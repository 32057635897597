import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  theBarTopBarContainer: {
    height: "65px",
    borderBottom: "1px solid #d0d0d0",
    backgroundColor: "#FFF",
  },
  goBackButtonDesktop: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    letterSpacing: "2px !important",
    backgroundColor: "transparent !important",
    color: "#2C2C2C !important",
    borderRadius: "30px !important",
    width: "142px !important",
    height: "44px !important",
    // border: "1px solid #2C2C2C !important",
    padding: "0!important",
  },
  startAgainButtonDesktop: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    letterSpacing: "2px !important",
    backgroundColor: "transparent !important",
    color: "#2C2C2C !important",
    borderRadius: "44px !important",
    width: "174px !important",
    gap: "8px",
    height: "44px !important",
    border: "1px solid #2C2C2C !important",
    padding: "8px 32px 8px 32px !important"
  },

  startAgainButtonDesktopES: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "10px !important",
    fontWeight: "800 !important",
    letterSpacing: "2px !important",
    backgroundColor: "transparent !important",
    color: "#2C2C2C !important",
    borderRadius: "44px !important",
    width: "194px !important",
    height: "44px !important",
    gap: "8px",
    border: "1px solid #2C2C2C !important",
    padding: "8px 32px 8px 32px !important"
  },
  goBackButtonMobile: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    letterSpacing: "2px !important",
    backgroundColor: "transparent !important",
    color: "#2C2C2C !important",
    padding: "0px !important",
    borderRadius: "30px !important",
    border: "0px solid #2C2C2C !important",
  },

  startAgainButtonMobile: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    letterSpacing: "2px !important",
    backgroundColor: "transparent !important",
    color: "#2C2C2C !important",
    borderRadius: "30px !important",
    width: "142px !important",
    height: "44px !important",
    border: "1px solid #2C2C2C !important",
    padding: "8px, 32px, 8px, 32px",
  },

  startAgainButtonMobileES: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "9px !important",
    fontWeight: "700 !important",
    letterSpacing: "2px !important",
    backgroundColor: "transparent !important",
    color: "#2C2C2C !important",
    borderRadius: "30px !important",
    width: "142px !important",
    height: "44px !important",
    border: "1px solid #2C2C2C !important",
    padding: "8px, 32px, 8px, 32px",
  },
  continueButtonDesktop: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    letterSpacing: "2px !important",
    backgroundColor: "#2C2C2C !important",
    color: "#FFF !important",
    borderRadius: "30px !important",
    width: "283px !important",
    height: "44px !important",
    "&:disabled": {
      backgroundColor: "#636363 !important",
    },
    marginLeft: "0px !important",
  },
  containerImage: {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectFit: "cover",
    width: "100% !important",
    height: "100vh !important",
  },
  coverImage: {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    minHeight: "100vh",
    objectFit: "cover",
    borderRadius: "100px 0 0 100px",
  },
  // first step
  firstStepRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // minHeight: "100vh",
    width: "100vw",
    backgroundColor: "#FAF1EA",
  },
  firstStepDescriptionContainer: {
    width: "528px",
    margin: "40px",
    marginBottom: "1",
  },
  WYCTitle: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "16px !important",
    fontWeight: "700 !important",
    letterSpacing: "2px !important",
    color: "#2C2C2C !important",
    textTransform: "uppercase",
  },
  WYCTitleMobile: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    letterSpacing: "2px !important",
    color: "#2C2C2C !important",
    textTransform: "uppercase",
  },
  firstStepTitle: {
    fontFamily: "Fnord !important",
    fontSize: "56px !important",
    fontWeight: "400 !important",
    color: "#000 !important",
    lineHeight: "61.6px !important",
  },
  firstStepTitleMobile: {
    fontFamily: "Fnord !important",
    fontSize: "32px !important",
    fontWeight: "400 !important",
    color: "#000 !important",
    lineHeight: "43.2px !important",
  },
  firstStepNormalText: {
    fontFamily: "Helvetica !important",
    fontSize: "18px !important",
    fontWeight: "400 !important",
    color: "#000 !important",
    lineHeight: "25.2px !important",
  },
  firstStepNormalTextMobile: {
    fontFamily: "Helvetica !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    color: "#000 !important",
    lineHeight: "25.2px !important",
  },
  firstStepButton: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    letterSpacing: "2px !important",
    backgroundColor: "#2C2C2C !important",
    color: "#F7F7F7 !important",
    borderRadius: "80px !important",
    "&:hover": {
      backgroundColor: "#2C2C2C !important",
      color: "#F7F7F7 !important",
    },
  },
  // second step
  secondStepTitle: {
    fontFamily: "Fnord !important",
    fontSize: "48px !important",
    fontWeight: "400 !important",
    color: "#000 !important",
    lineHeight: "61.6px !important",
  },
  secondStepTitleMobile: {
    fontFamily: "Fnord !important",
    fontSize: "36px !important",
    fontWeight: "400 !important",
    color: "#2C2C2C !important",
    lineHeight: "43.2px !important",
  },
  secondStepDescriptionContainer: {
    width: "816px",
    margin: "40px auto 0 auto",
    marginBottom: "0",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "left"
  },
  secondStepVibesButton: {
    fontFamily: "Helvetica !important",
    fontSize: "18px !important",
    fontWeight: "400 !important",
    textTransform: "none",
    color: "#2C2C2C !important",
    borderRadius: "30px !important",
    width: "343px !important",
    height: "80px !important",
  },
  secondStepVibesButtonMobile: {
    fontFamily: "Helvetica !important",
    fontSize: "18px !important",
    fontWeight: "400 !important",
    textTransform: "none",
    color: "#2C2C2C !important",
    borderRadius: "30px !important",
    width: "100% !important",
    height: "70px !important",
  },
  // third step
  thirdStepRoot: {
    backgroundColor: "#FAF1EA",
    overflowX: "hidden",
    // minHeight: "100vh",
  },
  thirdStepDescriptionContainer: {
    width: "100%",
    margin: "40px",
    marginBottom: "0",
  },
  dishesTextContainer: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "200px",
  },
  dishesText: {
    fontFamily: "HelveticaNowDisplay-Regular !important",
    fontSize: "20px !important",
    fontWeight: "400 !important",
    lineHeight: "26px !important",
    color: "#F7F7F7 !important",
    width: "100px",
  },
  // fourth step
  fourthStepDescriptionContainer: {
    width: "94%",
    margin: "20px",
    marginBottom: "0",
  },
  // fifth step
  recommendationCardContainer: {
    border: "none",
    margin: "0rem 0rem",
    borderRadius: "8px !important",
    width: "415px",
    maxWidth: "100%",
    height: "525px",
    boxShadow: "none",
  },
  iconText: {
    fontFamily: "HelveticaNowMicro-Regular !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    lineHeight: "1 !important",
    marginBottom: "1rem !important",
  },
  recommendationCocktailTitle: {
    fontFamily: "HelveticaNowMicro-Regular !important",
    fontWeight: "700 !important",
    fontSize: "18px !important",
    lineHeight: "25px !important",
    color: "#2C2C2C !important",
  },
  recommendationCocktailDescription: {
    fontFamily: "Helvetica !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "25px !important",
    color: "#2C2C2C !important",
  },
  viewRecipeLink: {
    fontFamily: "HelveticaNeue-Bold !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "1.2 !important",
    color: "#2C2C2C !important",
    textDecoration: "underline !important",
    position: "absolute",
    right: "2rem",
    cursor: "pointer",
  },
  footer: {
    position: "relative !important",
    width: "100% !important",
    height: "100px !important",
}
}));
