import TheBarRecommendationCard from "./TheBarRecommendationCard";
import { useStyles } from "../../styles";
import { useEffect, useState } from "react";
import { CarouselCardData } from "../../../../types/cocktail.types";
import { GetCocktailsByLanguage } from "../../../../graphql_queries";
import { getS3FileUrl } from "../../../../utils/S3Manager";
import NoImagePlaceholder from "../../../../assets/imagePlaceholders/NoImagePlaceholder.jpg";
import { Box } from "@mui/system";

interface TheBarCarouselProps {
  cocktails: string[];
  cocktailName: string;
  language: string;
  iframe: string;
}
export const TheBarCarousel = ({
  cocktails,
  cocktailName,
  language,
  iframe
}: TheBarCarouselProps) => {
  const classes = useStyles();
  const [cocktailsList, setCocktailsList] = useState<CarouselCardData[]>([]);

  const CLOUDFRONT_URL = process.env.REACT_APP_CDN;

  useEffect(() => {
    const getCocktailRecommendationCards = async () => {
      const items: CarouselCardData[] = [];

      const cocktails_names = cocktails.filter(item => item !== cocktailName)

      const graphqlData = await GetCocktailsByLanguage(cocktails_names, language);

      for (let i = 0; i < graphqlData.length; i++) {

          const lifeStyleImages = graphqlData[i].images
            ? graphqlData[i].images.filter((image: any) => image.imageType === "view_recipe_first_image")
            : [];
          let lifeStyleImageUrl = "";
          if (lifeStyleImages.length > 0) {

            const url  = `${CLOUDFRONT_URL}/public/${lifeStyleImages[0].imageUrl.split("/public/").slice(-1)[0]}`


            lifeStyleImageUrl = url;
          }
          var cocktailIdList = cocktails.join("_");
          console.log("cocktailIdList === ", cocktailIdList)
          
          const newRoute = `recipe/thebar/${cocktails_names[i]}/${language.toUpperCase()}/${iframe}?cocktailIds=${cocktailIdList}`
          const newItem = {
            cocktailName: graphqlData[i].cocktailTitle || "",
            difficulty: graphqlData[i].difficulty || "",
            alc: "",
            image: lifeStyleImageUrl,
            url: `${window.location.origin}/${newRoute}`,
            language: language,
          };
          if (graphqlData[i].spirit.length) {
            newItem.alc = `${graphqlData[i].spirit[0].name}`;
          }
          items.push(newItem);
        
      }
      setCocktailsList(items);
    };
    getCocktailRecommendationCards();
  }, [cocktailName, cocktails, language]);

  return (
    <>
      <div className={classes.carouselContainer + ' barcrowsel'}>
        <div className={`${classes.carouselBoxes} carousel-boxes`}>
          {cocktailsList.map((item, _index) => (
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => window.open(item.url, "_blank")}
              key={`recommendation-card-container-${_index}`}
            >
              <TheBarRecommendationCard
                key={`recommendation-card-${_index}`}
                cocktailName={item.cocktailName}
                difficulty={item.difficulty}
                alc={item.alc || "No Alcohol"}
                image={item.image || NoImagePlaceholder}
                lng={item.language || "EN_UK"}
              />
            </Box>
          ))}
        </div>
      </div>
    </>
  );
};
