import { useRouteError } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { DefaultThemeColors } from "../constants/colors";

const useStyles = makeStyles(() => ({
  mainLayoutContainer: {
    ...DefaultThemeColors,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

export default function ErrorPage() {
  const classes = useStyles();
  const error = useRouteError() as {
    statusText?: string;
    message?: string;
  };

  return (
    <Box className={classes.mainLayoutContainer}>
      <Typography variant="h1" gutterBottom>
        Oops!
      </Typography>
      <Typography>Sorry, an unexpected error has occurred.</Typography>
      <Typography fontWeight={600}>
        <i>{error.statusText || error.message}</i>
      </Typography>
    </Box>
  );
}
