export const DefaultThemeColors = {
  backgroundColor: "#FFF3DB",
  color: "#36454F",
};

export const BackgroundColors = {
  default: "#FFF2D7",
  important: "#FFDD99",
  dimmed: "#FFF3E8",
  primary: "#C80651",
  secondary: "#FEE5F2",
  disabled: "#C0C0C0",
  white: "#FFFFFF",
  black: "#000000",
  grey: "#333333",
};

export const TextColors = {
  primary: "#C80651",
  error: "#D32F2F",
  text: "#36454F",
  white: "#FFFFFF",
  black: "#000000",
};

export const OpacityValues = {
  o0: "00",
  o5: "0C",
  o10: "19",
  o15: "26",
  o20: "33",
  o25: "3F",
  o30: "4C",
  o35: "59",
  o40: "66",
  o45: "72",
  o50: "7F",
  o55: "8C",
  o60: "99",
  o65: "A5",
  o70: "B2",
  o75: "BF",
  o80: "CC",
  o85: "D8",
  o90: "E5",
  o95: "F2",
  o100: "FF",
};
