import React, { useState, useEffect, useCallback } from 'react';

interface InactiveScreenProps {
    setQuizStep: (val: number) => void;
}

const InactiveScreen: React.FC<InactiveScreenProps> = ({ setQuizStep }) => {
    const [isActive, setIsActive] = useState(true);
    //const timeoutDuration = 5000; // 5 seconds for demo
    const timeoutDuration = 10 * 60 * 1000; // 10 minutes in milliseconds

    const resetTimer = useCallback(() => {
        setIsActive(true); // Reset activity state
    }, []);

    useEffect(() => {
        const handleUserActivity = () => {
            setIsActive(true); // Reset activity on user action
        };

        const startTimer = () => {
            setIsActive(false); // Mark inactive after timeout
        };

        let timeoutId = setTimeout(startTimer, timeoutDuration); // Start inactivity timer

        const resetInactivityTimer = () => {
            clearTimeout(timeoutId); // Clear current timer
            setIsActive(true); // Mark user as active
            timeoutId = setTimeout(startTimer, timeoutDuration); // Restart the timer
        };

        // Reset the timer on any activity
        window.addEventListener('mousemove', resetInactivityTimer);
        window.addEventListener('mousedown', resetInactivityTimer); // Trigger on click or mouse down
        window.addEventListener('keydown', resetInactivityTimer); // Trigger on key down

        return () => {
            clearTimeout(timeoutId); // Clear timeout if component unmounts
            window.removeEventListener('mousemove', resetInactivityTimer);
            window.removeEventListener('mousedown', resetInactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
        };
    }, [resetTimer]);

    useEffect(() => {
        if (!isActive) {
            setQuizStep(1);
        }
    }, [isActive]);

    return (
        <></>
    );
};

export default InactiveScreen;
