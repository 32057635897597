import React, {useEffect} from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";
import { useStyles } from "../styles";
import { ArrowOutward } from "@mui/icons-material";

import {
  CreateConsumer,
  GetConsumer,
  UpdateConsumer,
} from "../../../graphql_queries";

export interface QuizRecommendationCardProps {
  cocktailId: string;
  site: string;
  brand_parameter: string;
  brand: string;
  cocktailName: string;
  description: string;
  compatibility: number;
  image: string;
  color: string;
  backgroundColor: string;
  url: string;
  mobile?: boolean;
  cocktailIds?: string[];
  titlediscover?: string;
  iframe?: string;
  cookie?: string | null;
  sessionId?: string | null;
}

const QuizRecommendationCard = ({
  cocktailId,
  site,
  brand_parameter,
  brand,
  cocktailName,
  description,
  compatibility,
  image,
  color,
  backgroundColor,
  url,
  mobile = false,
  cocktailIds,
  titlediscover,
  iframe,
  cookie,
  sessionId
}: QuizRecommendationCardProps) => {
  const classes = useStyles();


  if (cocktailIds) {
    var cocktailIdList = cocktailIds.join("_");
    console.log("cocktailIdList === ", cocktailIdList)
  }
  const api_url = process.env.REACT_APP_FLAVORPRINT_API_URL;
  const api_key = process.env.REACT_APP_FLAVORPRINT_API_KEY_QUIZ;

  const saveInteractionAPIFP = async (params) => {
    // Begin Saving Flavour Print --------------------------------
    const saveInteractionsFPRequests = [];
    for (const item of params) {
      const URLFP = new URL(api_url + "interaction");


      Object.keys(item).forEach((key) =>
        URLFP.searchParams.append(key, item[key])

      );

      let paramsObject = {};
      URLFP.searchParams.forEach((value, key) => {
        paramsObject[key] =
          value === "null" && key === "consumerid" ? null : value;
      });

      console.log("URLFP", URLFP)
      saveInteractionsFPRequests.push(
        new Promise(async (resolve) => {
          const fpResponse = await fetch(URLFP, {
            method: "POST",
            headers: {
              "x-api-key": String(api_key),
            },
          });
          resolve(fpResponse);
        })
      );
    }
    await Promise.all(saveInteractionsFPRequests);
    // End Saving Flavour Print ----------------------------------
  }
  const saveInteractionAPI = async (params) => {
    try {
      let consumer = await GetConsumer(String(params[0].consumerId));

      if (!consumer) {
        console.log("consumer id", params[0].consumerId);
        consumer = await CreateConsumer(String(params[0].consumerId));
      }

      if (consumer) {
        const { city, country, region, latitude, longitude } = locationData;
        const user_agent = userAgentData;
        let consumerInteractions = consumer.interactions || [];
        const input = {
          createdAt: consumer.createdAt,
          id: consumer.id,
          interactions: [
            ...consumerInteractions.map((i) => ({
              createdAt: i.createdAt,
              interactionType: i.interactionType,
              site: i.site,
              sourceId: i.sourceId,
              city: city,
              country: country,
              region: region,
              sessionId: sessionId,
              latitude: latitude,
              longitude: longitude,
              user_agent: user_agent
            })),
            ...params.map((interaction) => ({
              createdAt: dayjs().format("YYYY/MM/DD HH:mm"),
              interactionType: interaction.interactionType,
              site: interaction.site,
              sourceId: interaction.sourceId,
              sessionId: sessionId,
              city: city,
              country: country,
              region: region,
              latitude: latitude,
              longitude: longitude,
              user_agent: user_agent
            })),
          ],
        };

        await UpdateConsumer(input);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };

  async function performInteraction(params) {
    try {
      await saveInteractionAPIFP([params]);
      await saveInteractionAPI([params]);
      return;
    } catch (error) {
      console.error("Error making POST request:", error);
      throw error;
    }
  }




  const handleCardClick = () => {

    const paramsInteractionButton = {
      site: site,
      sourceId: cocktailId,
      consumerId: cookie,
      sessionToken: sessionId,
      interactionType: "Click",
    };

    performInteraction(paramsInteractionButton);

    const additionalData = {
      cocktailIds: cocktailIdList
    };

    // Serialize additional data into query parameters
    const queryParams = new URLSearchParams(additionalData).toString();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "custom_event",
      event_name: "recipe_tile",
      recipe_name: cocktailName,
      brand: brand,
      recipe_id: cocktailId,
      click_text: "Discover",
      destination_page_path: url,
    });

    // Concatenate query parameters to the URL

    const removeCookieFromUrl = (url) => {
      const urlParts = url.split('?');
      const filteredParts = urlParts.filter(part => !part.startsWith('cookie='));
      return filteredParts.join('?');
    };

    const url_updated = removeCookieFromUrl(url);

    let urlWithParams;

    if (brand_parameter !== 'thebardomain') {
      urlWithParams = `${url_updated}?${queryParams}`;
    }
    else {

      const utmSource = `${site}.com`;
      const utmMedium = "referral";
      const utmCampaign = "whatsyourcocktail";

      // Create an object with these values
      const utmData = {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign
      };

      // Serialize the UTM data into query parameters
      const queryParams = new URLSearchParams(utmData).toString();

      urlWithParams = `${url_updated}?${queryParams}`;
    }
    // change the parameter to _self in case of iframe

    if (iframe !== "worldclass") {
      if (iframe === 'stand') {
        window.open(urlWithParams, "_blank");
      }
      else {
        window.open(urlWithParams, "_blank");
      }
    }
  };


  return (
    <Box
      sx={{
        margin: 0,
      }}
    >
      <Card
        className={`${classes.recommendationCardContainer}`}
        sx={{
          width: {
            xs: "310px !important",
            sm: "335px !important",
            md: "415px !important",
            lg: "415px !important",
            xl: "415px !important",
          },
          height: {
            xs: "479px !important",
            sm: "479px !important",
            md: "525px !important",
            lg: "525px !important",
            xl: "525px !important",
          },
        }}
        onClick={handleCardClick}
        style={{ cursor: "pointer" }}
      >
        <CardMedia
          component="img"
          alt={cocktailName}
          height={mobile ? "233px" : "310px"}
          image={image}
        />
        <CardContent
          sx={{
            height: {
              xs: "246px !important",
              sm: "246px !important",
              md: "227px !important",
              lg: "227px !important",
              xl: "227px !important",
            },
            position: "relative !important",
          }}
        >
          <Chip
            label={`${Math.round(compatibility)}% Compatible`}
            sx={{
              marginBottom: "1.5rem",
              color,
              backgroundColor,
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Helvetica !important",
            }}
          />
          <Typography
            gutterBottom
            className={classes.recommendationCocktailTitle}
          >
            {cocktailName}
          </Typography>
          <Typography
            gutterBottom
            className={classes.recommendationCocktailDescription}
          >
            {description}
          </Typography>
          <Typography
            gutterBottom
            className={classes.viewRecipeLink}
            sx={{
              display: "flex",
              bottom: "1rem",
            }}
          >
            {(iframe !== "worldclass" &&
              <>{titlediscover} < ArrowOutward sx={{ paddingBottom: "4px" }} /></>
            )}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default QuizRecommendationCard;
