import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "../constants/store-actions/snackbar";
import { snackbarInitialState } from "../contexts/SnackbarContext";
import { SnackbarContextInitialState } from "../contexts/types/snackbar";

interface SnackbarReducerActionProps {
  type: string;
  payload?: SnackbarContextInitialState;
}

const snackbarReducer = (
  state = snackbarInitialState,
  action: SnackbarReducerActionProps
) => {
  const { payload, type } = action;

  switch (type) {
    case OPEN_SNACKBAR: {
      return {
        ...state,
        ...payload,
        open: true
      };
    }
    case CLOSE_SNACKBAR: {
      return {
        ...state,
        open: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default snackbarReducer;
