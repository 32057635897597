import { createContext, useReducer } from "react";
import {
  SnackbarContextInitialState,
  SnackbarContextType,
} from "./types/snackbar";
import snackbarReducer from "../reducers/snackbarReducer";
import {
  CLOSE_SNACKBAR,
  OPEN_SNACKBAR,
} from "../constants/store-actions/snackbar";

export const snackbarInitialState: SnackbarContextInitialState = {
  severity: "success",
  variant: "filled",
  title: "Alert Title",
  open: false,
  content: null,
};

const SnackbarContext = createContext<SnackbarContextType | null>(null);

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(snackbarReducer, snackbarInitialState);

  const handleOpen = async (alert: SnackbarContextInitialState) => {
    dispatch({
      type: OPEN_SNACKBAR,
      payload: {
        ...alert,
      },
    });
    return;
  };
  const handleClose = async () => {
    dispatch({
      type: CLOSE_SNACKBAR,
    });
    return;
  };

  return (
    <SnackbarContext.Provider
      value={{
        ...state,
        handleOpen,
        handleClose,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;
