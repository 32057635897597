/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { BeatLoader } from "react-spinners";
import Cookies from "js-cookie";
import {
  Box,
  Chip,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  Instagram,
  MailOutline,
  Square,
} from "@mui/icons-material";
import NoImagePlaceholder from "../../../assets/imagePlaceholders/NoImagePlaceholder.jpg";
import WhatsYourCocktailLogo from "../../../assets/whatsyourcocktaillogo.svg";
import { ReactComponent as GluttenSvg } from "../../../assets/svg/Glutten.svg";
import { ReactComponent as VitalSignsSvg } from "../../../assets/svg/VitalSigns.svg";
import { ReactComponent as FacebookSvg } from "../../../assets/svg/Facebook.svg";
import { ReactComponent as PinterestSvg } from "../../../assets/svg/Pinterest.svg";
import { ReactComponent as WineCupSvg } from "../../../assets/svg/WineCup.svg";
import { ReactComponent as BeginnerSvg } from "../../../assets/svg/Beginner.svg";
import { ReactComponent as IntermediateSvg } from "../../../assets/svg/Intermediate.svg";
import { ReactComponent as HardSvg } from "../../../assets/svg/Hard.svg";
import { ReactComponent as TimeSvg } from "../../../assets/svg/Time.svg";
import { useStyles } from "../styles";
import { RecipeViewerProps } from "../types";
import { UnbrandedCarousel } from "./components/UnbrandedCarousel";
import { GetCocktailsByLanguage } from "../../../graphql_queries";
import { CocktailDetailGraphQL } from "../../../types/cocktail.types";
import { downloadS3File, getS3FileUrl } from "../../../utils/S3Manager";
import { capitalize } from "../../../utils";
import UnbrandedECommerceCard from "./components/UnbrandedECommerceCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UnbrandedPdf from "./UnbrandedPdf";
import html2pdf from 'html2pdf.js';


const UnbrandedRecipeViewer = ({
  cocktailName,
  language,
}: RecipeViewerProps) => {
  const classes = useStyles();

  let difficultyIcons;

  const CLOUDFRONT_URL = process.env.REACT_APP_CDN;

  const difficultyIconsEN = {
    Beginner: BeginnerSvg,
    Intermediate: IntermediateSvg,
    Hard: HardSvg,
  };

  const difficultyIconsES = {
    Principiante: BeginnerSvg,
    Intermedio: IntermediateSvg,
    Intermedia: IntermediateSvg,
    Avanzado: HardSvg,
  };

  console.log('language', language)

    switch (language) {
  case 'ES':
    difficultyIcons = difficultyIconsES;
    break;
  case 'ES_ES':
    difficultyIcons = difficultyIconsES;
    break;
  case 'ES_MX':
    difficultyIcons = difficultyIconsES;
    break;
  case 'ES_US':
    difficultyIcons = difficultyIconsES;
    break;
  case 'ES_ROW':
    difficultyIcons = difficultyIconsES;
    break;
  case 'EN_UK':
  case 'EN_US':
  case 'EN_ROW':
    difficultyIcons = difficultyIconsEN;
    break;
  default:
    difficultyIcons = difficultyIconsEN;
    break;
}

  console.log('difficultyIcons', difficultyIcons)

  const [cocktail, setCocktail] = useState<CocktailDetailGraphQL | null>(null);
  const [spiritText, setSpiritText] = useState("");
  const [showAllEquipment, setShowAllEquipment] = useState(false);

  const [productInfo, setProductInfo] = useState<{
    name: string;
    description: string;
    image: string;
    url: string;
  }>({
    name: "",
    description: "",
    image: "",
    url: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [pdfImageUrls, setPdfImageUrls] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ingredients, setIngredients] = useState<
    { qty: number; unit: string; name: string }[]
  >([]);
  const [serves, setServes] = useState(1);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [recommendationIds, setRecommendationIds] = useState<string[]>([]);

  const essentialstitle = useMemo(
    () => ({
      EN_UK: "Essentials for Crafting the Magic",
      EN_US: "Essentials for Crafting the Magic",
      ES: "Elementos Essenciales para Crear la Magia",
    }),
    []
  );

  const ingredientstitle = useMemo(
    () => ({
      EN_UK: "Ingredients",
      EN_US: "Ingredients",
      EN_ROW: "Ingredients",
      ES: "Ingredientes",
      ES_ES: "Ingredientes",
      ES_US: "Ingredientes",
      ES_MX: "Ingredientes",
      ES_ROW: "Ingredientes",
    }),
    []
  );

  const persontitle = useMemo(
    () => ({
      EN_UK: "Person",
      EN_US: "Person",
      EN_ROW: "Person",
      ES: "Persona",
      ES_ES: "Persona",
      ES_US: "Persona",
      ES_MX: "Persona",
      ES_ROW: "Persona",
    }),
    []
  );

  const peopletitle = useMemo(
    () => ({
      EN_UK: "People",
      EN_US: "People",
      EN_ROW: "People",
      ES: "Personas",
      ES_ES: "Personas",
      ES_US: "Personas",
      ES_MX: "Personas",
      ES_ROW: "Personas",
    }),
    []
  );

  const crafttitle = useMemo(
    () => ({
      EN_UK: "How to Craft Your Cocktail",
      EN_US: "How to Craft Your Cocktail",
      EN_ROW: "How to Craft Your Cocktail",
      ES: "Pasos para elaborar tu Cóctel",
      ES_ES: "Pasos para elaborar tu Cóctel",
      ES_US: "Pasos para elaborar tu Cóctel",
      ES_MX: "Pasos para elaborar tu Cóctel",
      ES_ROW: "Pasos para elaborar tu Cóctel",

    }),
    []
  );

  const youmaytitle = useMemo(
    () => ({
      EN_UK: "Similar cocktails",
      EN_US: "Similar cocktails",
      EN_ROW: "Similar cocktails",
      ES: "Cocktails Similares",
      ES_ES: "Cocktails Similares",
      ES_US: "Cocktails Similares",
      ES_MX: "Cocktails Similares",
      ES_ROW: "Cocktails Similares",

    }),
    []
  );


  const selectservestitle = useMemo(
    () => ({
      EN_UK: "SELECT YOUR SERVES",
      EN_US: "SELECT YOUR SERVES",
      EN_ROW: "SELECT YOUR SERVES",
      ES: "SELECCIONA TUS COMENSALES",
      ES_ES: "SELECCIONA TUS COMENSALES",
      ES_US: "SELECCIONA TUS COMENSALES",
      ES_MX: "SELECCIONA TUS COMENSALES",
      ES_ROW: "SELECCIONA TUS COMENSALES",
    }),
    []
  );
  
  const notfoundtitle = useMemo(
    () => ({
      EN_UK: "Sorry... Cocktail Not Found",
      EN_US: "Sorry... Cocktail Not Found",
      EN_ROW: "Sorry... Cocktail Not Found",
      ES: "Lo sentimos... Cocktail no encontrado",
      ES_ES: "Lo sentimos... Cocktail no encontrado",
      ES_US:  "Lo sentimos... Cocktail no encontrado",
      ES_MX:  "Lo sentimos... Cocktail no encontrado",
      ES_ROW:  "Lo sentimos... Cocktail no encontrado",
    }),
    []
  );

  const equipmenttitle = useMemo(
    () => ({
      EN_UK: "Equipment",
      EN_US: "Equipment",
      ES: "Utensilios",
      EN_ROW: "Equipment",
      ES_ES: "Utensilios",
      ES_US:  "Utensilios",
      ES_MX:  "Utensilios",
      ES_ROW:  "Utensilios",

    }),
    []
  );

  const showmoretitle = useMemo(
    () => ({
      EN_UK: "Show more",
      EN_US: "Show more",
      EN_ROW: "Show more",
      ES: "Ver más",
      ES_ES: "Ver más",
      ES_US: "Ver más",
      ES_MX:  "Ver más",
      ES_ROW:  "Ver más",
    }),
    []
  );

  const showlesstitle = useMemo(
    () => ({
      EN_UK: "Show less",
      EN_US: "Show less",
      EN_ROW: "Show less",
      ES: "Ver menos",
      ES_ES: "Ver menos",
      ES_US: "Ver menos",
      ES_MX:  "Ver menos",
      ES_ROW:  "Ver menos",
    }),
    []
  );


  const downloadrecipetitle = useMemo(
    () => ({
      EN_UK: "DOWNLOAD RECIPE",
      EN_US: "DOWNLOAD RECIPE",
      EN_ROW: "DOWNLOAD RECIPE",
      ES: "DESCARGAR RECETA",
      ES_ES: "DESCARGAR RECETA",
      ES_US: "DESCARGAR RECETA",
      ES_MX:  "DESCARGAR RECETA",
      ES_ROW:  "DESCARGAR RECETA",
    }),
    []
  );
  
  

  const title_essentials = essentialstitle[language] || "Essentials for Crafting the Magic";
  const title_ingredients = ingredientstitle[language] || "Ingredients";

  const title_person = persontitle[language] || "Person";
  const title_people = peopletitle[language] || "People";

  const title_craft = crafttitle[language] || "How to Craft Your Cocktail";

  const title_youmay = youmaytitle[language] || "You may also like";

  const title_serves = selectservestitle[language] || "SELECT YOUR SERVES";
  
  const title_notfound = notfoundtitle[language] || "You may also like";

  const title_equipment = equipmenttitle[language] || "Equipment";

  const title_showmore = showmoretitle[language] || "Show more";

  const title_showless = showlesstitle[language] || "Show less";

  const title_downloadrecipe = downloadrecipetitle[language] || "DOWNLOAD RECIPE";

  

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let cocktailIds: string | null = params.get('cocktailIds');
    if (cocktailIds) {
      let cocktailIdsArray = cocktailIds.split('_');
      setRecommendationIds(cocktailIdsArray);
    }
  }, [])

  const ingredientsUnitMapper = useMemo(
    () => ({
      ES: {
        qtyLabel: "quantity_ml",
        unit: "ml",
      },
      EN_US: {
        qtyLabel: "quantity_oz",
        unit: "oz",
      },
      EN_UK: {
        qtyLabel: "quantity_ml",
        unit: "ml",
      },
      EN_ROW: {
        qtyLabel: "quantity_ml",
        unit: "ml",
      },
      ES_US: {
        qtyLabel: "quantity_ml",
        unit: "ml",
      },
      ES_MX: {
        qtyLabel: "quantity_ml",
        unit: "ml",
      },
      ES_ROW: {
        qtyLabel: "quantity_ml",
        unit: "ml",
      },
      ES_ES: {
        qtyLabel: "quantity_ml",
        unit: "ml",
      },
    }),
    []
  );

  useEffect(() => {
    const CLOUDFRONT_URL = process.env.REACT_APP_CDN;
    const GetCocktailData = async () => {
      let data = await GetCocktailsByLanguage([cocktailName], language);

      data = data[0]

      if (data) {
        setCocktail(data);
        let preIng = data.ingredients.map((ing: any) => {
  const qtyLabel = ingredientsUnitMapper[language as keyof typeof ingredientsUnitMapper].qtyLabel;
  const isQtyLabelPresent = ing[qtyLabel] != null;

  return {
    qty: isQtyLabelPresent ? +ing[qtyLabel] : ing.quantity,
    unit: isQtyLabelPresent ? ingredientsUnitMapper[language as keyof typeof ingredientsUnitMapper].unit : '',
    name: ing.ingredient,
  };
});

        setIngredients(preIng);

        const lifeStyleImages = data.images
          ? data.images
            .filter(
              (image: any) =>
                image.imageType === "view_recipe_first_image" ||
                image.imageType === "view_recipe_second_image"
            )
            .sort((a: any, b: any) => {
              const nameA = a.imageType.toUpperCase();
              const nameB = b.imageType.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
          : [];
        let lifeStyleImageUrl = [];
        let lifeStyleImageUrlPdf = [];
        for await (const img of lifeStyleImages) {
          if (img) {

            const url  = `${CLOUDFRONT_URL}/public/${img.imageUrl.split("public/").slice(-1)[0]}`

            const { data: pdfUrl } = await downloadS3File(
              `${img.imageUrl.split("public/").slice(-1)[0]}`.replace(/%20/g, ' ')
            );
            lifeStyleImageUrlPdf.push(pdfUrl);
            lifeStyleImageUrl.push(url);
          }
        }
        setImageUrls(lifeStyleImageUrl);
        setPdfImageUrls(lifeStyleImageUrlPdf);
        if (data.spirit.length) {
          const brandNames = data.spirit.map(s => 
            `${s.brand[0].brandName.charAt(0).toUpperCase()}${s.brand[0].brandName.slice(1).toLowerCase()}`
          ).join(', ');
          setSpiritText(brandNames);
        }

        if (data.ecommerce && data.ecommerce.length) {

          const productImage  = `${CLOUDFRONT_URL}/public/${data.ecommerce[0].product_imageUrl}`

          setProductInfo({
            name: data.ecommerce[0].product_title,
            description: data.ecommerce[0].product_description,
            image: productImage,
            url: data.ecommerce[0].product_Url,
          });
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "custom_event",
          event_name: "view_recipe",
          recipe_id: cocktailName,
          recipe_name: data.cocktailTitle,
          brand: data.spirit[0].brand[0].brandName,
        });

      } else {
        setNotFound(true);
      }
      setIsLoading(false);
    };
    GetCocktailData();
  }, [cocktailName, ingredientsUnitMapper, language]);
  const downloadAsPDF = () => {

    const input = document.getElementById('pdf-content');
    const options = {
      // margin: 1,
      filename: 'Recipe.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 5 ,useCors:true},
      jsPDF: {
      unit: 'pt',  // Use points as the unit
      format: [595.28, 841.89],  // A4 size in points (1 pt = 1/72 inch)
      orientation: 'portrait',
    },
    };

    // Generate PDF
    html2pdf().from(input).set(options).save();
  };
  

  return (
    <>
    <Box
        sx={{
          opacity: 0,
          position: "absolute",
          top: "-200vh",
          minWidth: "1300px",
        }}
      >
       <div id="pdf-content"> 
      <UnbrandedPdf
        cocktail={cocktail}
        spiritText={spiritText}
        ingredients={ingredients}
        imageUrl={pdfImageUrls}
        serves={serves}
        title_essentials={title_essentials}
        title_craft={title_craft}
        title_ingredients={title_ingredients}
        language={language}
        difficultyIcons={difficultyIcons}
      />
      </div>
      
      </Box>
     
    <Box
      className={classes.WYCRootContainer}
      sx={{ "& *": { color: "#2c2c2c" } }}
    >
      <Grid container className={classes.topBarContainer}>
        <Grid
          component={Box}
          item
          xs={2}
          display={{ xs: "none", md: "block" }}
          sx={{ position: "relative", top: "14px", left: "20px" }}
        >
          <img src={WhatsYourCocktailLogo} alt="wyc-logo" />
        </Grid>
        <Grid
          component={Box}
          item
          xs={12}
          display={{ xs: "flex", md: "none" }}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={WhatsYourCocktailLogo} alt="wyc-logo" />
        </Grid>
      </Grid>
      {isLoading && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "400px",
          }}
        >
          <BeatLoader />
        </Box>
      )}
      {!isLoading && !notFound && cocktail && (
        <>
          <Box
            className={classes.recipeViewerContainer}
            sx={() => ({
              padding: {
                xs: "0rem 0rem 0",
                sm: "0rem 0rem 0",
                md: "3rem 3rem 0",
                lg: "3rem 3rem 0",
                xl: "3rem 3rem 0",
              },
              paddingBottom: "0 !important",
            })}
          >
            <Box
              className={classes.recipeViewerContainer2}
              sx={() => ({
                padding: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "0rem",
                  lg: "0rem",
                  xl: "0rem",
                },
                paddingBottom: "0 !important",
              })}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={4}
                sx={{
                  marginBottom: {
                    xs: "4rem",
                    sm: "4rem",
                    md: "6rem",
                    lg: "6rem",
                    xl: "6rem",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    padding: {
                      xs: "3rem !important",
                      sm: "3rem !important",
                      md: "0rem !important",
                      lg: "0rem !important",
                      xl: "0rem !important",
                    },
                    paddingRight: {
                      xs: "1rem !important",
                      sm: "1rem !important",
                      md: "0rem !important",
                      lg: "0rem !important",
                      xl: "0rem !important",
                    },
                    paddingLeft: {
                      xs: "3rem !important",
                      sm: "3rem !important",
                      md: "32px !important",
                      lg: "32px !important",
                      xl: "32px !important",
                    },
                    paddingBottom: {
                      xs: "0 !important",
                      sm: "0 !important",
                      md: "3rem !important",
                      lg: "3rem !important",
                      xl: "3rem !important",
                    },
                  }}
                >
                  <Box
                    id="desktop-description"
                    display={{ xs: "none", md: "flex" }}
                    sx={{
                      height: "100%",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      padding: "1rem 0rem !important",
                    }}
                  >
                    <Box>
                      <Typography className={classes.topTitle}>
                        {spiritText}
                      </Typography>
                      <Typography
                        className={classes.mainTitle}
                        sx={{
                          fontSize: {
                            xs: "30px !important",
                            sm: "80px !important",
                            md: "80px",
                            lg: "80px",
                          },
                          lineHeight: {
                            xs: "48px !important",
                            sm: "80px !important",
                            md: "80px",
                            lg: "80px",
                          },
                        }}
                      >
                        {cocktail.cocktailTitle}
                      </Typography>
                    </Box>
                    <Box sx={{ maxWidth: "527px !important" }}>
                      <Typography
                        className={classes.description}
                        sx={{
                          fontSize: {
                            xs: "18px !important",
                            sm: "20px !important",
                            md: "20px !important",
                            lg: "20px !important",
                          },
                          lineHeight: {
                            xs: "25.2px !important",
                            sm: "26px !important",
                            md: "26px !important",
                            lg: "26px !important",
                          },
                        }}
                      >
                        {cocktail.cocktailDescriptionbig}
                      </Typography>
                      
                     
                      
                    </Box>
                  </Box>
                  <Box
                    sx={{ marginTop: { xs: "2rem", md: "20rem" } }}
                    display={{ xs: "block", md: "none" }}
                  >
                    <Typography
                      className={classes.topTitle}
                      sx={{
                        fontFamily: "HelveticaNowMicro-Bold !important",
                        fontSize: "16px !important",
                      }}
                    >
                      {spiritText}
                    </Typography>
                    <Typography
                      className={classes.mainTitle}
                      sx={{
                        fontSize: "56px !important",
                        lineHeight: {
                          xs: "60px !important",
                          sm: "60px !important",
                          md: "24px !important",
                        },
                      }}
                    >
                      {cocktail.cocktailTitle}
                    </Typography>
                    <Typography
                      className={classes.description}
                      sx={{
                        fontSize: "18px !important",
                        lineHeight: "25.2px !important",
                        marginTop: "3rem",
                        marginBottom: "1rem",
                      }}
                    >
                      {cocktail.cocktailDescriptionbig}
                    </Typography>
        
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "0 !important",
                    paddingLeft: {
                      xs: "32px !important",
                      sm: "32px !important",
                      md: "0px !important",
                      lg: "0px !important",
                    },
                  }}
                >
                  <Box
                    className={classes.containerImage}
                    sx={{
                      width: {
                        xs: "343px !important",
                        sm: "343px !important",
                        md: "640px !important",
                        lg: "640px !important",
                      },
                      height: {
                        xs: "343px !important",
                        sm: "343px !important",
                        md: "640px !important",
                        lg: "640px !important",
                      },
                    }}
                  >
                    <img
                      src={imageUrls[0] ? imageUrls[0] : NoImagePlaceholder}
                      alt="main"
                      className={classes.coverImage}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                rowSpacing={2}
                columnSpacing={3}
                sx={{
                  marginBottom: {
                    xs: "2rem !important",
                    sm: "2rem !important",
                    md: "4rem !important",
                    lg: "4rem !important",
                    xl: "4rem !important",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  sx={{
                    paddingLeft: {
                      xs: "3rem !important",
                      sm: "3rem !important",
                      md: "32px !important",
                      lg: "32px !important",
                      xl: "32px !important",
                    },
                  }}
                >
                  <Typography
                    className={classes.mainTitle}
                    sx={{
                      fontSize: {
                        xs: "48px !important",
                        sm: "48px !important",
                        md: "72px",
                        lg: "72px",
                      },
                      lineHeight: {
                        xs: "48px !important",
                        sm: "48px !important",
                        md: "72px",
                        lg: "72px",
                      },
                    }}
                  >
                    {title_essentials}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={3}
                sx={{
                  marginBottom: {
                    xs: "3rem",
                    sm: "3rem",
                    md: "10rem",
                    lg: "10rem",
                    xl: "10rem",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  sx={{
                    paddingLeft: {
                      xs: "3rem !important",
                      sm: "3rem !important",
                      md: "32px !important",
                      lg: "32px !important",
                      xl: "32px !important",
                    },
                  }}
                >
                  <Typography className={classes.sectionTitle}>
                    {title_serves}
                  </Typography>
                  <FormControl sx={{ m: 0, mb: 4, width: "192px" }}>
                    <Select
                      fullWidth
                      notched={false}
                      id="serves-select"
                      value={serves}
                      label="Age"
                      sx={{
                        border: "2px solid #000",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onChange={(e) => setServes(+e.target.value)}
                      inputProps={{
                        sx: {
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                    >
                      <MenuItem
                        value={1}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          className={classes.sectionTitle}
                          sx={{ marginBottom: "0px !important" }}
                        >
                          1 {title_person}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        value={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          className={classes.sectionTitle}
                          sx={{ marginBottom: "0px !important" }}
                        >
                          2 {title_people}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        value={3}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          className={classes.sectionTitle}
                          sx={{ marginBottom: "0px !important" }}
                        >
                          3 {title_people}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        value={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          className={classes.sectionTitle}
                          sx={{ marginBottom: "0px !important" }}
                        >
                          4 {title_people}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        value={5}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          className={classes.sectionTitle}
                          sx={{ marginBottom: "0px !important" }}
                        >
                          5 {title_people}
                        </Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {cocktail.calories && (
                    <Box className={classes.iconText}>
                      <SvgIcon
                        component={VitalSignsSvg}
                        sx={{ marginRight: 1 }}
                      />
                      {cocktail.calories} kcal
                    </Box>
                  )}
                  {cocktail.alcoholVol && (
                    <Box className={classes.iconText}>
                      <SvgIcon component={WineCupSvg} sx={{ marginRight: 1 }} />
                      {(() => {
                        switch (language) {
                          case 'EN_UK':
                          case 'EN_ROW':
                            return `${cocktail.alcoholVol} units of alcohol per serve`;
                          case 'EN_US':
                            return (
                                    <>
                                      Approximate serving size is {cocktail.alcoholVol}.
                                      <span className={classes.indented}> Please enjoy responsibly.</span>
                                    </>
                                  );
                          case 'ES':
                          case 'ES_ES':
                          case 'ES_US':
                          case 'ES_MX':
                          case 'ES_ROW':
                            return `${cocktail.alcoholVol} unidades de alcohol`;
                          default:
                            return `${cocktail.alcoholVol} units of alcohol`; 
                        }
                      })()}
                    </Box>
                  )}
                  {cocktail.allergens &&
                    cocktail.allergens.map((allergen) => (
                      <Box className={classes.iconText} key={`key-${allergen}`}>
                        <SvgIcon
                          component={GluttenSvg}
                          sx={{ marginRight: 1 }}
                        />
                        {allergen.name}
                      </Box>
                    ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  sx={{
                    paddingLeft: {
                      xs: "3rem !important",
                      sm: "3rem !important",
                      md: "32px !important",
                      lg: "32px !important",
                      xl: "32px !important",
                    },
                  }}
                >
                  <Typography className={classes.sectionTitle}>
                    {title_ingredients}
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      {ingredients.map((ingredient, _index) => (
                        <Typography
                          key={`${ingredient.name}`}
                          className={classes.iconText}
                        >
                          
                          <Box
                            component="span"
                            sx={{
                              marginRight: "0.5rem",
                              display: "inline",
                            }}
                          >
                            <Square
                              sx={{
                                width: "8px",
                                height: "8px",
                              }}
                            />
                          </Box>
                          {ingredient.qty > 0 && (
                            <Box
                              component="span"
                              sx={{
                                fontWeight: "bolder",
                                marginRight: "0.5rem",
                              }}
                            >
                              {ingredient.qty > 0
                                ? `${Math.round(ingredient.qty * serves*100)/100} ${ingredient.unit
                                }`
                                : ""}
                            </Box>
                          )}
                          {/* Ingredient name in regular weight */}
                          <Box component="span" sx={{ fontWeight: "normal" }}>
                            
                            {Math.round(ingredient.qty * serves) !== 1 ? 
                                    ingredient.name.replace(/\b(unit|dash|pinch|wedge|Unit|Dash|Pinch|Wedge|Unidad|Rodaja|unidad|rodaja|toque|Toque|aceituna|Aceituna|pizca|Pizca|trozo|Trozo)\b/gi, match => {
                                      const plurals = {
                                        unit: 'units',
                                        dash: 'dashes',
                                        pinch: 'pinches',
                                        wedge: 'wedges',
                                        Unit: 'Units',
                                        Dash: 'Dashes',
                                        Pinch: 'Pinches',
                                        Wedge: 'Wedges',
                                        Unidad: 'Unidades',
                                        Rodaja: 'Rodajas',
                                        Toque: 'Toques',
                                        aceituna: 'Aceitunas',
                                        pizca: "Pizcas",
                                        trozo: "Trozos"
                                      };
                                      return plurals[match];
                                    }) 
                                    : ingredient.name.replace(/\b(units|dashes|pinches|wedges|Units|Dashes|Pinches|Wedges|Unidades|Rodajas|unidades|rodajas|toques|Toques|aceitunas|Aceitunas|pizcas|Pizcas|trozo|Trozo)\b/gi, match => {
                                      const singulars = {
                                        units: 'unit',
                                        dashes: 'dash',
                                        pinches: 'pinch',
                                        wedges: 'wedge',
                                        Units: 'Unit',
                                        Dashes: 'Dash',
                                        Pinches: 'Pinch',
                                        Wedges: 'Wedge',
                                        Unidades: 'Unidad',
                                        Rodajas: 'Rodaja',
                                        Toques: 'Toque',
                                        aceitunas: 'Aceituna',
                                        pizcas: "Pizca",
                                        trozo: "Trozos"
                                      };
                                      return singulars[match];
                                    })
                                  }

                          </Box>
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                
                       
                        <Grid container marginTop={"1rem"}>
                          <Grid item xs={6} sm={6}>
                          <Typography  className={classes.sectionTitle}
                          sx={{
                            fontFamily: "HelveticaNeue-Bold !important",
                          }}>
                            {title_equipment}
                          </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} marginBottom={"1rem"} marginTop={"-0.3rem"}  >
                          <Typography
                              component="span"
                              variant="body1"
                              className={classes.showMoreText}
                              onClick={() => setShowAllEquipment(!showAllEquipment)}
                            >
                              {showAllEquipment ? title_showless : title_showmore}
                              {/* Show expand/collapse icon */}
                              {showAllEquipment ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Typography>
                            </Grid>
                          <Grid item xs={12} sm={12} md={12} >
                            { showAllEquipment && cocktail.equipment &&  cocktail.equipment.map((equipment, index) => (
                              <Typography
                                key={equipment.equipmentName}
                                className={classes.iconText}
                                
                                
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    marginRight: "0.5rem",
                                    display: "inline",
                                  }}
                                >
                                  <Square
                                    sx={{
                                      width: "8px",
                                      height: "8px",
                                    }}
                                  />
                                </Box>
                                {equipment.equipmentName}
                              </Typography>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                {productInfo.name && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <UnbrandedECommerceCard
                      name={productInfo.name}
                      description={productInfo.description}
                      image={`${productInfo.image.replace(/ /g, '%20')}`}
                      productUrl={productInfo.url}
                      lng={language}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={3}
                sx={{
                  marginBottom: {
                    xs: "3rem",
                    sm: "3rem",
                    md: "5rem",
                    lg: "5rem",
                    xl: "5rem",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  sx={{
                    paddingLeft: {
                      xs: "3rem !important",
                      sm: "3rem !important",
                      md: "32px !important",
                      lg: "32px !important",
                      xl: "32px !important",
                    },
                  }}
                >
                  <Typography
                    className={classes.mainTitle}
                    sx={{
                      fontSize: {
                        xs: "48px !important",
                        sm: "48px !important",
                        md: "72px",
                        lg: "72px",
                      },
                      lineHeight: {
                        xs: "48px !important",
                        sm: "48px !important",
                        md: "72px",
                        lg: "72px",
                      },
                    }}
                  >
                    {title_craft}
                  </Typography>
                </Grid>
                
              </Grid>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={8}
                sx={{
                  marginBottom: {
                    xs: "3rem",
                    sm: "3rem",
                    md: "10rem",
                    lg: "10rem",
                    xl: "10rem",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  sx={{ paddingLeft: "90px !important" }}
                >
                  {cocktail.preparationTime && (
                  <Box className={classes.iconText}>
                    <SvgIcon component={TimeSvg} sx={{ marginRight: 1 }} />{" "}
                    {cocktail.preparationTime || "-"}
                  </Box>)}
                  {cocktail.difficulty && (
                    <Box className={classes.iconText}>
                      <SvgIcon
                        component={difficultyIcons[cocktail.difficulty as keyof typeof difficultyIcons]}
                        sx={{ marginRight: 1 }}
                      />
                      {cocktail.difficulty}
                    </Box>
                  )}
                </Grid>
                {cocktail.method && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    sx={{
                      paddingLeft: "90px !important",
                      paddingRight: "3rem !important",
                    }}
                  >
                    {cocktail.method.map((step, _index) => (
                      <Box
                        key={`index-step-${_index}`}
                        className={classes.stepContainer}
                      >
                        <Box className={classes.stepNumber}>
                          <Typography className={classes.stepNumber}>
                            {_index + 1}.
                          </Typography>
                        </Box>
                        <Box className={classes.stepText}>
                          <Typography className={classes.iconText}>
                            {step.step}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                    <Button  
                           style={{borderColor:'#2C2C2C',
                           color: '#2C2C2C',
                           paddingLeft:'32px',
                           paddingRight:'32px'
                           }}variant="contained" variant="outlined" color="primary" onClick={downloadAsPDF}>
                              {title_downloadrecipe}
                            </Button>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    className={classes.containerImage}
                    sx={{
                      width: {
                        xs: "343px !important",
                        sm: "343px !important",
                        md: "526px !important",
                        lg: "526px !important",
                      },
                      height: {
                        xs: "343px !important",
                        sm: "343px !important",
                        md: "526px !important",
                        lg: "526px !important",
                      },
                      paddingLeft: "0 !important",
                    }}
                  >
                    <img
                      src={
                        imageUrls[1]
                          ? imageUrls[1]
                          : imageUrls[0]
                            ? imageUrls[0]
                            : NoImagePlaceholder
                      }
                      alt="main"
                      className={classes.coverImage}
                    />
                  </Box>
                </Grid>
              </Grid>
              {recommendationIds.length > 0 && (
                <>
                  <Box>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={3}
                      sx={{
                        marginBottom: {
                          xs: "3rem",
                          sm: "3rem",
                          md: "5rem",
                          lg: "5rem",
                          xl: "5rem",
                        },
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        sx={{
                          paddingLeft: {
                            xs: "3rem !important",
                            sm: "3rem !important",
                            md: "32px !important",
                            lg: "32px !important",
                            xl: "32px !important",
                          },
                        }}
                      >
                        <Typography
                          className={classes.mainTitle}
                          sx={{
                            fontSize: {
                              xs: "48px !important",
                              sm: "48px !important",
                              md: "72px",
                              lg: "72px",
                            },
                            lineHeight: {
                              xs: "48px !important",
                              sm: "48px !important",
                              md: "72px",
                              lg: "72px",
                            },
                          }}
                        >
                          {title_youmay}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={8}
                      sx={{
                        paddingBottom: "2rem",
                        marginBottom: 0,
                        width: {
                          xs: "415px",
                          sm: "415px",
                          md: "calc(100% + 120px)",
                          lg: "calc(100% + 120px)",
                          xl: "calc(100% + 120px)",
                        },
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        sx={{
                          paddingLeft: {
                            xs: "90px !important",
                            sm: "90px !important",
                            md: "70px !important",
                            lg: "70px !important",
                            xl: "70px !important",
                          },
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item>
                            <IconButton
                              className={`${classes.analogButton} carousel-trigger-left-btn`}
                            >
                              <ArrowBack />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton
                              className={`${classes.analogButton} carousel-trigger-right-btn`}
                            >
                              <ArrowForward />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        sx={{
                          paddingLeft: {
                            xs: "90px !important",
                            sm: "90px !important",
                            md: "32px !important",
                            lg: "32px !important",
                            xl: "32px !important",
                          },
                        }}
                      >
                        <UnbrandedCarousel
                          cocktails={recommendationIds}
                          cocktailName={cocktailName}
                          language={language}
                        />
                      </Grid>
                    </Grid>
                    <Helmet>
                      <title>{`View Recipe: ${cocktail && cocktail.cocktailTitle
                        }`}</title>
                      <script type="text/javascript" src="/carousel.js" defer />
                      {!window.location.hostname.includes("localhost") && (
                        <script
                          type="text/javascript"
                          src="https://footer.diageohorizon.com/dfs/master.js"
                        ></script>
                      )}
                    </Helmet>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
      {!isLoading && notFound && (
        <>
          <Box
            className={classes.recipeViewerContainer}
            sx={() => ({
              padding: {
                xs: "1rem",
                sm: "1rem",
                md: "4rem",
                lg: "4rem",
                xl: "4rem",
              },
            })}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={3}
              sx={{ marginBottom: "6rem" }}
            >
              <Grid item xs={12} sm={12} md={5}>
                <Typography
                  className={classes.mainTitle}
                  sx={{
                    fontSize: {
                      xs: "48px !important",
                      sm: "48px !important",
                      md: "72px",
                      lg: "72px",
                    },
                    lineHeight: {
                      xs: "48px !important",
                      sm: "48px !important",
                      md: "72px",
                      lg: "72px",
                    },
                  }}
                >
                  {title_notfound}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
    </>
  );
};

export default UnbrandedRecipeViewer;
