/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConsumer = /* GraphQL */ `
  query GetConsumer($id: ID!) {
    getConsumer(id: $id) {
      id
      createdAt
      interactions {
        site
        sourceId
        interactionType
        createdAt
        country
        region
        city
        latitude
        longitude
        __typename
      }
      fallback
      updatedAt
      __typename
    }
  }
`;
export const listConsumers = /* GraphQL */ `
  query ListConsumers(
    $id: ID
    $filter: ModelConsumerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listConsumers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;


export const getCocktails = /* GraphQL */ `
  query GetCocktails($cocktailNames: [String!]!) {
    getCocktails(cocktailNames: $cocktailNames) {
      cocktailName
      EN_UK {
        cocktailTitle
        cocktailDescription
        cocktail_url
        cocktailDescriptionbig
        images {
          imageType
          imageUrl
        }
        __typename
      }
      EN_US {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        images {
          imageType
          imageUrl
        }
        
        __typename
      }
      ES {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        images {
          imageType
          imageUrl
        }
        
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getCocktail = /* GraphQL */ `
  query GetCocktail($cocktailName: String!) {
    getCocktail(cocktailName: $cocktailName) {
      cocktailName
      EN_UK {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        alcoholVol
        alc
        foodPairing
        metaData
        shakerMethod
        difficulty
        preparationTime
        created_date
        updated_date
        user
        ingredients {
          ingredient
          quantity_oz
          quantity_ml
          quantity
          substitute
        }
        spirit {
          name
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        equipment {
          equipmentName
          quantity
          description
        }
        cuisineType {
          type
          description
        }
        seasonTags {
          name
          description
        }
        occasion {
          name
          description
        }
        method {
          step
          description
        }
        images {
          imageUrl
          imageType
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
      EN_ROW {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        alcoholVol
        alc
        foodPairing
        metaData
        shakerMethod
        difficulty
        preparationTime
        created_date
        updated_date
        user
        ingredients {
          ingredient
          quantity_oz
          quantity_ml
          quantity
          substitute
        }
        spirit {
          name
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        equipment {
          equipmentName
          quantity
          description
        }
        cuisineType {
          type
          description
        }
        seasonTags {
          name
          description
        }
        occasion {
          name
          description
        }
        method {
          step
          description
        }
        images {
          imageUrl
          imageType
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
      EN_US {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        alcoholVol
        alc
        foodPairing
        metaData
        shakerMethod
        difficulty
        preparationTime
        created_date
        updated_date
        user
        ingredients {
          ingredient
          quantity_oz
          quantity_ml
          quantity
          substitute
        }
        spirit {
          name
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        equipment {
          equipmentName
          quantity
          description
        }
        cuisineType {
          type
          description
        }
        seasonTags {
          name
          description
        }
        occasion {
          name
          description
        }
        method {
          step
          description
        }
        images {
          imageUrl
          imageType
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
      ES {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        alcoholVol
        alc
        foodPairing
        metaData
        shakerMethod
        difficulty
        preparationTime
        created_date
        updated_date
        user
        ingredients {
          ingredient
          quantity_oz
          quantity_ml
          quantity
          substitute
        }
        spirit {
          name
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        equipment {
          equipmentName
          quantity
          description
        }
        cuisineType {
          type
          description
        }
        seasonTags {
          name
          description
        }
        occasion {
          name
          description
        }
        method {
          step
          description
        }
        images {
          imageUrl
          imageType
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
    ES_ES {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        alcoholVol
        alc
        foodPairing
        metaData
        shakerMethod
        difficulty
        preparationTime
        created_date
        updated_date
        user
        ingredients {
          ingredient
          quantity_oz
          quantity_ml
          quantity
          substitute
        }
        spirit {
          name
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        equipment {
          equipmentName
          quantity
          description
        }
        cuisineType {
          type
          description
        }
        seasonTags {
          name
          description
        }
        occasion {
          name
          description
        }
        method {
          step
          description
        }
        images {
          imageUrl
          imageType
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
  ES_MX {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        alcoholVol
        alc
        foodPairing
        metaData
        shakerMethod
        difficulty
        preparationTime
        created_date
        updated_date
        user
        ingredients {
          ingredient
          quantity_oz
          quantity_ml
          quantity
          substitute
        }
        spirit {
          name
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        equipment {
          equipmentName
          quantity
          description
        }
        cuisineType {
          type
          description
        }
        seasonTags {
          name
          description
        }
        occasion {
          name
          description
        }
        method {
          step
          description
        }
        images {
          imageUrl
          imageType
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
    ES_US {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        alcoholVol
        alc
        foodPairing
        metaData
        shakerMethod
        difficulty
        preparationTime
        created_date
        updated_date
        user
        ingredients {
          ingredient
          quantity_oz
          quantity_ml
          quantity
          substitute
        }
        spirit {
          name
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        equipment {
          equipmentName
          quantity
          description
        }
        cuisineType {
          type
          description
        }
        seasonTags {
          name
          description
        }
        occasion {
          name
          description
        }
        method {
          step
          description
        }
        images {
          imageUrl
          imageType
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
    ES_ROW {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        alcoholVol
        alc
        foodPairing
        metaData
        shakerMethod
        difficulty
        preparationTime
        created_date
        updated_date
        user
        ingredients {
          ingredient
          quantity_oz
          quantity_ml
          quantity
          substitute
        }
        spirit {
          name
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        equipment {
          equipmentName
          quantity
          description
        }
        cuisineType {
          type
          description
        }
        seasonTags {
          name
          description
        }
        occasion {
          name
          description
        }
        method {
          step
          description
        }
        images {
          imageUrl
          imageType
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCocktails = /* GraphQL */ `
  query ListCocktails(
    $cocktailName: String
    $filter: ModelCocktailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCocktails(
      cocktailName: $cocktailName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cocktailName
        EN_UK {          
          user          
        }
        EN_US {          
          user          
        }
        ES {          
          user          
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getCocktailByLanguage = /* GraphQL */ `
  query GetCocktailByLanguage($cocktailNames: [String!]!, $language: String!) {
    getCocktailByLanguage(cocktailNames: $cocktailNames, language: $language) {
      cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        alcoholVol
        alc
        foodPairing
        metaData
        shakerMethod
        difficulty
        preparationTime
        created_date
        updated_date
        user
        ingredients {
          ingredient
          quantity_oz
          quantity_ml
          quantity
          substitute
        }
        spirit {
          name
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        equipment {
          equipmentName
          quantity
          description
        }
        cuisineType {
          type
          description
        }
        seasonTags {
          name
          description
        }
        occasion {
          name
          description
        }
        method {
          step
          description
        }
        images {
          imageUrl
          imageType
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
      __typename
    }
  }
`;