import React from "react";
import { Amplify } from "aws-amplify";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/Widget.css";
import amplifyconfig from "../../amplifyconfiguration.json";
import PocDiageo from "./PocDiageo";


Amplify.configure(amplifyconfig);

function Widget() {
  const template = getQueryParam("template") || "";
  let primaryColor = "#DB236D";
  let secondaryColor = "#000000";
  let bgColor = "#FBF4F7";
  if (template !== "") {
    primaryColor = "red";
    secondaryColor = "yellow";
    bgColor = "black";
  }

  window.parent.postMessage({ href: window.location.href }, "*")



  return (
    <div className="">
      <div className="WidgetCocktail">
        <PocDiageo
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          bgColor={bgColor}
        />
      </div>
    </div>
  );
}

function getQueryParam(name) {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
}

export default Widget;
