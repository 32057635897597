import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  theBarRootContainer: {
    backgroundColor: "#FAF1EA",
  },
  WYCRootContainer: {
    backgroundColor: "#F7F7F7",
    overflowX: "hidden",
  },
  recipeViewerContainer: {
    padding: "2rem 2rem",
    overflowWrap: "break-word",
  },
  recipeViewerContainer2: {
    padding: "12px 4rem",
  },
  topBarContainer: {
    height: "65px",
    borderBottom: "1px solid #d0d0d0",
  },
  theBarTopBarContainer: {
    height: "65px",
    borderBottom: "1px solid #d0d0d0",
    backgroundColor: "#FFF",
  },
  topTitle: {
    fontFamily: "HelveticaNowMicro-Bold !important",
    fontSize: "16px !important",
    fontWeight: "700 !important",
    lineHeight: "21.2px !important",
    marginLeft: "5px !important",
    padding: "6px !important",
    marginBottom: "1rem !important",
    marginRight: "14px !important",
    textTransform: "uppercase",
  
  },
  mainTitle: {
    fontFamily: "HelveticaNeue-Bold !important",
    fontSize: "80px !important",
    fontWeight: "700 !important",
    marginRight: "10px !important",
    padding: "6px !important",
    lineHeight: "80px !important"
    

  },
  theBarMainTitle: {
    fontFamily: "Fnordbold !important",
    fontSize: "64px !important",
    fontWeight: "400 !important",
    lineHeight: "64px !important",
    width: "90%",
  },

  theBarMainTitlePdf:{
    fontFamily: "Fnordbold !important",
    fontSize: "32px !important",
    fontWeight: "400 !important",
    lineHeight: "32px !important",
    width: "90%", 
  },

    TheBarSecondSectionMainTitle: {
    fontSize: "20pt !important",
    fontFamily: "Fnordbold !important",
    fontWeight: "400 !important",
  },

  description: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "20px !important",
    marginRight: "10px !important",
    padding: "6px !important",
    fontWeight: "400 !important",
    lineHeight: "1 !important",
  },
  theBarDescription: {
    fontFamily: "Fnord !important",
    fontSize: "24px !important",
    fontWeight: "400 !important",
    lineHeight: "31.2px !important",
    width: "80%",
  },
  theBarDescriptionPdf: {
    fontFamily: "Fnord !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    lineHeight: "18.2px !important",
    width: "80%",
  },
  containerImage: {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectFit: "cover",
  },
  containerText: {
    width: "640px",
    maxWidth: "100%",
    height: "853px",
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectFit: "cover",
  },
  coverImage: {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "640px",
    height: "640px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  theBarCoverImage: {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "640px",
    height: "640px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  tags: {
    display: 'flex',
    flexDirection: 'column', // Align children vertically
    alignItems: 'left', // Center children horizontally
    marginBottom: '0.5px', // Reduce bottom margin to pull rows closer
  },
  tagRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    gap: '0.5px', // Space between chips in the same row
  },
  cocktailChip: {
    marginRight: "0.5rem",
    fontFamily: "HelveticaNeue-Bold !important",
    fontSize: "14px !important",
    color: "#FFFFFF !important",
    border: "1.5px solid #2c2c2c !important",
    height: "2px !important",
    marginTop: "19px !important",
    marginBottom: "0.1rem !important",
    padding: "0.7rem !important"
  },
  theBarCocktailChip: {
    backgroundColor: "#FCD7A4 !important",
    marginRight: "0.5rem",
    fontFamily: "Helvetica !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    color: "#2c2c2c !important",
    border: "none !important",
    height: "21px !important",
    marginTop: "1.5rem !important",
    marginBottom: "1.5rem !important",
    padding: "1rem 0 !important",
  },
  sectionTitle: {
    fontFamily: "HelveticaNeue-Bold !important",
    fontSize: "16px !important",
    fontWeight: "700 !important",
    lineHeight: "1 !important",
    marginBottom: "1rem !important",
  },
  sectionTitlePdf: {
    fontFamily: "HelveticaNeue-Bold !important",
    fontSize:"10px !important",
    lineHeight:"10.6px !important",
    fontWeight: "700 !important",
    marginBottom: "0.5rem !important",
  },
  sectionContent: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "1 !important",
    marginBottom: "1rem !important",
  },
  socialButton: {
    border: "1px solid #2c2c2c !important",
    width: "44px !important",
    color: "#2c2c2c !important",
  },
  iconText: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "20px !important",
    fontWeight: "400 !important",
    lineHeight: "1 !important",
    marginBottom: "1rem !important",
  },
  theBarIconText: {
    fontFamily: "HelveticaNowMicro-Regular !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    lineHeight: "1 !important",
    marginBottom: "1rem !important",
    
  },
  theBarIconTextPdf: {
    fontFamily: "HelveticaNowMicro-Regular !important",
    fontSize: "11px !important",
    fontWeight: "400 !important",
    lineHeight: "13.2px !important",
    
    
  },
  indented: {
    paddingLeft: '2.4em',
    display: 'inline-block',
  },
  theBarStepText: {
    fontFamily: "Fnord !important",
    fontSize: "20px !important",
    fontWeight: "400 !important",
    lineHeight: "1 !important",
    marginBottom: "1rem !important",
  },
  theBarStepTextPdf: {
    fontFamily: "Fnord !important",
    fontSize: "8px !important",
    fontWeight: "400 !important",
    lineHeight: "8.48px !important",
    
  },
  stepContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: "415px",
  },
  stepContainerPdf: {
    display: "flex",
    alignItems: "center",
    maxWidth:"415px",
    marginBottom:"0.5rem",
  },
  stepNumber: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "64px !important",
    fontWeight: "700 !important",
    lineHeight: "1 !important",
    marginBottom: "1rem !important",
    marginRight: "1rem !important",
  },
  stepNumberPdf: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "11px !important",
    fontWeight: "700 !important",
    lineHeight: "13.2px !important",
    marginRight: "0.5rem !important",
  },
  stepText: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "20px !important",
    fontWeight: "400 !important",
    lineHeight: "1 !important",
    marginBottom: "1rem !important",
    display: "flex",
    alignItems: "center",
  },
  stepTextPdf: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "11px !important",
    fontWeight: "400 !important",
    lineHeight: "13.2px !important",
   
    display: "flex",
    alignItems: "center",
  },
  analogButton: {
    border: "1px solid #2c2c2c !important",
    color: "#2c2c2c !important",
    boxShadow: "1px 1px 0 #2c2c2c, 2px 2px 0 #2c2c2c",
    "&:hover": {
      boxShadow: "1px 1px 0 #2c2c2c",
      marginTop: "2px",
      marginLeft: "2px",
    },
  },
  theBarSliderButton: {
    color: "#1C1B1F",
  },
  cocktailTitle: {
    fontFamily: "HelveticaNeue-Bold !important",
    fontWeight: "700 !important",
    fontSize: "32px !important",
    lineHeight: "1.2 !important",
    color: "#2C2C2C !important",
    marginBottom: "1rem !important",
  },
  theBarCocktailTitle: {
    fontFamily: "Fnord !important",
    fontWeight: "700 !important",
    fontSize: "32px !important",
    lineHeight: "1.2 !important",
    color: "#2C2C2C !important",
    marginBottom: "1rem !important",
  },
  theBarViewRecipeLink: {
    fontFamily: "HelveticaNeue-Bold !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "1.2 !important",
    color: "#2C2C2C !important",
    marginBottom: "1rem !important",
    textDecoration: "underline !important",
    position: "absolute",
    bottom: 45,
    right: "2rem",
  },
  cardContainer: {
    border: "3px solid #000",
    margin: "0rem 0rem",
    borderRadius: "8px !important",
    width: "415px",
    height: "565px",
    boxShadow: "none",
  },
  ecommerceCardContainer: {
    margin: "0rem 0rem",
    borderRadius: "20px !important",
    boxShadow: "none !important",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ecommerceSpiritTitle: {
    fontFamily: "Helvetica !important",
    fontWeight: "700 !important",
    fontSize: "24px !important",
    lineHeight: "31.2px !important",
    color: "#2C2C2C !important",
  },
  ecommerceSpiritDescription: {
    fontFamily: "Helvetica !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: "#2C2C2C !important",
  },
  addToBagButton: {
    width: "351px",
    height: "44px",
    padding: "8px 92px 8px 92px",
    borderRadius: "44px !important",
    backgroundColor: "#2C2C2C !important",
    color: "#F7F7F7 !important",
  },
  theBarCardContainer: {
    border: "none",
    margin: "0rem 0rem",
    borderRadius: "8px !important",
    width: "415px",
    height: "640px",
    boxShadow: "none",
  },
  SimilarContainer: {
    width: "calc(90vw - 150px)",
    height: "709px",
  },
  theBarSimilarContainer: {
    width: "100%",
    height: "736px",
  },
  carouselContainer: {
    border: "none",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  carouselBoxes: {
    display: "flex",
    overflowX: "auto",
    gap: "10px",
    alignItems: "center",
    scrollBehavior: "smooth",
    "&&::-webkit-scrollbar": {
      display: "none",
    },
  },
  showMoreText: {
    fontSize: "15px !important",
    fontFamily: "FNord !important",
    fontWeight: "light !important",
    marginRight: "0.5rem",
    cursor: "pointer", // Add cursor pointer
    textDecoration: "underline", // Add underline
  },
}));
