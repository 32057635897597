import React, { useRef, useState, useMemo } from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import Carousel from "react-elastic-carousel";
import Orange from "../../../assets/quiz/quizLoaderImages/quiz4/Gimlet_Hero.jpg";
import Blue from "../../../assets/quiz/quizLoaderImages/quiz4/Manhattan_Hero.jpg";
import Yellow from "../../../assets/quiz/quizLoaderImages/quiz4/Spicy_Margarita_Hero.jpg";
import Green from "../../../assets/quiz/quizLoaderImages/quiz4/Tom_Collins_Additional_2.jpg";

import Loader1 from "../../../assets/quiz/quizLoaderImages/quiz4/upside_down/Whiskey_Sour_Hero.jpg";
import Loader2 from "../../../assets/quiz/quizLoaderImages/quiz4/upside_down/Paloma_Additional_2.jpg";
import Loader3 from "../../../assets/quiz/quizLoaderImages/quiz4/upside_down/Vesper_Hero.jpg";
import Loader4 from "../../../assets/quiz/quizLoaderImages/quiz4/upside_down/Margarita_Hero.jpg";
import Loader5 from "../../../assets/quiz/quizLoaderImages/quiz4/upside_down/Tequila_Sunrise_Hero.jpg";
import Loader6 from "../../../assets/quiz/quizLoaderImages/quiz4/upside_down/Whiskey_Sour_Hero.jpg";

import LoaderHorizontal1 from "../../../assets/quiz/quizLoaderImages/quiz4/normal/Whiskey_Sour_Hero.jpg";
import LoaderHorizontal2 from "../../../assets/quiz/quizLoaderImages/quiz4/normal/Paloma_Additional_2.jpg";
import LoaderHorizontal3 from "../../../assets/quiz/quizLoaderImages/quiz4/normal/Vesper_Hero.jpg";
import LoaderHorizontal4 from "../../../assets/quiz/quizLoaderImages/quiz4/normal/Margarita_Hero.jpg";
import LoaderHorizontal5 from "../../../assets/quiz/quizLoaderImages/quiz4/normal/Tequila_Sunrise_Hero.jpg";
import { useStyles } from "../styles";
import { QuizStepProps } from "..";

const QuizStep4 = ({ errorMsg, lang }: QuizStepProps) => {
  const classes = useStyles();
  const verticalCarouselFirstRef = useRef<any>(null);
  const verticalCarouselSecondRef = useRef<any>(null);
  const horizontalCarouselFirstRef = useRef<any>(null);
  const horizontalCarouselSecondRef = useRef<any>(null);
  const [autoPlay, setAutoPlay] = useState(true);

  const handleCarouselMouseEvent = () => {
    setAutoPlay(!autoPlay);
  };

  const whatsyourcocktailtitle = useMemo(
    () => ({
      EN_UK: "What's your cocktail?",
      EN_US: "What's your cocktail?",
      ES: "¿CUÁL ES TU CÓCTEL?",
    }),
    []
  );
  
  const titlefail = useMemo(
    () => ({
      EN_UK: "Our AI failed in tailoring a flavour adventure for you. due to",
      EN_US: "Our AI failed in tailoring a flavour adventure for you. due to",
      ES: "Nuestra IA no ha conseguido crear una experiencia de sabores para ti debido a",
    }),
    []
  );

  const title = whatsyourcocktailtitle[lang] || "What's your cocktail?";

  const failtitle = titlefail[lang] || "What's your cocktail?";

  return (
    <Box
      className={classes.firstStepRoot}
      sx={{
        flexDirection: {
          xs: "column !important",
          sm: "column !important",
          md: "row !important",
          lg: "row !important",
          xl: "row !important",
        },
        justifyContent: {
          xs: "unset !important",
          sm: "unset !important",
          md: "center !important",
          lg: "center !important",
          xl: "center !important",
        },
        paddingTop: {
          xs: "2rem !important",
          sm: "2rem !important",
          md: "0rem !important",
          lg: "0rem !important",
          xl: "0rem !important",
        },
        height: "100vh !important",
      }}
    >
      {/* Desktop */}
      <Grid container component={Box} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={6}
          sx={{
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className={classes.firstStepDescriptionContainer}>
            <Box>
              <Typography gutterBottom className={classes.WYCTitle}>
                {title}
              </Typography>
            </Box>

            <Box>
              <Typography gutterBottom className={classes.firstStepTitle}>
                {failtitle} {`${errorMsg}`}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& .rec-slider-container": {
              margin: "0 !important",
            },
          }}
          onMouseEnter={handleCarouselMouseEvent}
          onMouseLeave={handleCarouselMouseEvent}
        >
          <Carousel
            ref={verticalCarouselFirstRef}
            verticalMode
            itemsToShow={4}
            enableAutoPlay={autoPlay}
            autoPlaySpeed={1000}
            renderArrow={() => <></>}
            renderPagination={() => <></>}
            itemPadding={[10, 0]}
          >
            <img
              src={Yellow}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
            />
            <img
              src={Orange}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Orange Cocktail"
            />
            <img
              src={Blue}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Blue Cocktail"
            />
            <img
              src={Green}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Green Cocktail"
            />
            <img
              src={Yellow}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
            />
            <img
              src={Orange}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Orange Cocktail"
            />
          </Carousel>
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "240px",
            "& .rec-carousel-wrapper": {
              transform: "rotate(180deg)",
            },
            "& .rec-slider-container": {
              margin: "0 !important",
            },
          }}
          onMouseEnter={handleCarouselMouseEvent}
          onMouseLeave={handleCarouselMouseEvent}
        >
          <Carousel
            ref={verticalCarouselSecondRef}
            verticalMode
            enableAutoPlay={autoPlay}
            itemsToShow={4}
            autoPlaySpeed={1000}
            renderArrow={() => <></>}
            renderPagination={() => <></>}
            itemPadding={[10, 0]}
          >
            <img
              src={Loader1}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
            />
            <img
              src={Loader2}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Orange Cocktail"
            />
            <img
              src={Loader3}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
            />
            <img
              src={Loader4}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Orange Cocktail"
            />
            <img
              src={Loader5}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
            />
            <img
              src={Loader6}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
            />
          </Carousel>
        </Grid>
      </Grid>
      {/* Mobile */}
      <Grid
        container
        component={Box}
        display={{ xs: "block", md: "none" }}
        sx={{ paddingRight: "1rem" }}
      >
        <Grid item xs={12}>
          <Box
            className={classes.fourthStepDescriptionContainer}
            sx={{ padding: "0rem !important", paddingTop: "0 !important" }}
          >
            <Box>
              <Typography className={classes.WYCTitleMobile}>
                {title}
              </Typography>
            </Box>

            <Box>
              <Typography gutterBottom className={classes.firstStepTitleMobile}>
                {failtitle} {`${errorMsg}`}
              </Typography>
            </Box>
          </Box>
          <Grid container component={Box} display={{ xs: "block", md: "none" }}>
            <Grid
              item
              xs={12}
              sx={{
                height: "231px",
                overflow: "hidden",
                "& .rec-slider-container": {
                  marginRight: "0 !important",
                },
              }}
              onMouseEnter={handleCarouselMouseEvent}
              onMouseLeave={handleCarouselMouseEvent}
            >
              <Carousel
                ref={horizontalCarouselFirstRef}
                itemsToShow={1.5}
                enableAutoPlay={autoPlay}
                autoPlaySpeed={1500}
                renderArrow={() => <></>}
                renderPagination={() => <></>}
                itemPadding={[0, 0, 0, 10]}
              >
                <img
                  src={Orange}
                  style={{
                    width: "276px",
                    height: "207px",
                    borderRadius: "46.16px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Orange Cocktail"
                />
                <img
                  src={Blue}
                  style={{
                    width: "276px",
                    height: "207px",
                    borderRadius: "46.16px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Blue Cocktail"
                />
                <img
                  src={Green}
                  style={{
                    width: "276px",
                    height: "207px",
                    borderRadius: "46.16px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Green Cocktail"
                />
                <img
                  src={Yellow}
                  style={{
                    width: "276px",
                    height: "207px",
                    borderRadius: "46.16px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Yellow Cocktail"
                />
                <img
                  src={Orange}
                  style={{
                    width: "276px",
                    height: "207px",
                    borderRadius: "46.16px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Orange Cocktail"
                />
              </Carousel>
            </Grid>
          </Grid>
          <Grid container component={Box} display={{ xs: "block", md: "none" }}>
            <Grid
              item
              xs={12}
              sx={{
                height: "231px",
                overflow: "hidden",
                "& .rec-slider-container": {
                  marginRight: "0 !important",
                },
              }}
              onMouseEnter={handleCarouselMouseEvent}
              onMouseLeave={handleCarouselMouseEvent}
            >
              <Carousel
                ref={horizontalCarouselSecondRef}
                itemsToShow={1.5}
                enableAutoPlay={autoPlay}
                autoPlaySpeed={1500}
                isRTL
                renderArrow={() => <></>}
                renderPagination={() => <></>}
                itemPadding={[0, 0, 0, 10]}
              >
                <img
                  src={LoaderHorizontal1}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Orange Cocktail"
                />
                <img
                  src={LoaderHorizontal2}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Blue Cocktail"
                />
                <img
                  src={LoaderHorizontal3}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Green Cocktail"
                />
                <img
                  src={LoaderHorizontal4}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Yellow Cocktail"
                />
                <img
                  src={LoaderHorizontal5}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Orange Cocktail"
                />
              </Carousel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuizStep4;
