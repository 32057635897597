/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Grid,
  SvgIcon,
  Typography,
} from "@mui/material";
import {

  Square,
} from "@mui/icons-material";
import NoImagePlaceholder from "../../../assets/imagePlaceholders/NoImagePlaceholder.jpg";

import { ReactComponent as BeginnerSvg } from "../../../assets/svg/Beginner.svg";
import { ReactComponent as IntermediateSvg } from "../../../assets/svg/Intermediate.svg";
import { ReactComponent as HardSvg } from "../../../assets/svg/Hard.svg";
import { ReactComponent as TimeSvg } from "../../../assets/svg/Time.svg";
import { useStyles } from "../pdf_styles";
import { RecipeDownloaderProps, RecipeViewerProps } from "../types";
import { useMemo } from "react";


const UnbrandedPdf = ({
  cocktail,
  spiritText,
  ingredients,
  imageUrl,
  serves,
  title_essentials,
  title_craft,
  title_ingredients,
  language,
  difficultyIcons
}: RecipeDownloaderProps) => {
  const classes = useStyles();
  
  const persontitle = useMemo(
    () => ({
      EN_UK: "Person",
      EN_US: "Person",
      EN_ROW: "Person",
      ES: "Persona",
      ES_ES: "Persona",
      ES_US: "Persona",
      ES_MX: "Persona",
      ES_ROW: "Persona",
    }),
    []
  );

  const servestitle = useMemo(
    () => ({
      EN_UK: "Serves",
      EN_US: "Serves",
      EN_ROW: "Serves",
      ES: "Comensales",
      ES_ES: "Comensales",
      ES_US:  "Comensales",
      ES_MX:  "Comensales",
      ES_ROW:  "Comensales",
    }),
    []
  );
    const equipmenttitle = useMemo(
    () => ({
      EN_UK: "Equipment",
      EN_US: "Equipment",
      ES: "Utensilios",
      EN_ROW: "Equipment",
      ES_ES: "Utensilios",
      ES_US:  "Utensilios",
      ES_MX:  "Utensilios",
      ES_ROW:  "Utensilios",

    }),
    []
  );

    const title_people = persontitle[language] || "Person";
    const title_equipment = equipmenttitle[language] || "Equipment";
    const title_serves = servestitle[language] || "SERVES";

  return (
    <Box
      className={classes.WYCRootContainer}
      sx={{ "& *": {  color: "#2c2c2c"  } }}
      style={{ minHeight: "840.89pt" }}
    >
      <div style={{ padding: "3rem" }}>
        <div>
          <div style={{ display: "flex", alignItems: "center",gap:"0pt 23pt" }}>
            {cocktail && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  },
                  width: "50%",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "343px !important",
                      sm: "343px !important",
                      md: "1000px !important",
                      lg: "1100px !important",
                    },
                  }}
                >
                  <Typography
                    className={classes.topTitlepdf}
                    sx={{
                      fontFamily: "HelveticaNeue-Bold !important",
                      marginBottom: "0px !important",
                    }}
                  >
                    {spiritText}
                  </Typography>
                  <Typography
                    className={classes.theBarMainTitlePdf}
                    sx={{
                      fontSize: {
                        xs: "24px !important",
                        sm: "24px !important",
                        md: "32px !important",
                        lg: "32px !important",
                        xl: "32px !important",
                      },
                      fontFamily: "HelveticaNeue-Bold !important",
                    }}
                  >
                    {cocktail.cocktailTitle}
                  </Typography>
                  <Typography
                    className={classes.theBarDescriptionPdf}
                    sx={{
                      fontFamily: "HelveticaNeue !important",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {cocktail.cocktailDescriptionbig}
                  </Typography>
                </Box>
              </Grid>
            )}
           {/*  <Grid
              item
              xs={12}
              sm={12}
              md={12}
              display="flex"
              sx={{
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "end",
                  lg: "end",
                  xl: "end",
                },
                alignItems: "center",
              }}
            >
              <Box
                className={classes.containerImage}
                sx={{
                  width: {
                    xs: "229px !important",
                    sm: "229px !important",
                    md: "308px !important",
                    lg: "308px !important",
                  },
                  height: {
                    xs: "229px !important",
                    sm: "229px !important",
                    md: "308px !important",
                    lg: "308px !important",
                  },
                }}
              >
                <img
                  src={imageUrl[0] ? imageUrl[0] : NoImagePlaceholder}
                  alt="main"
                  className={classes.theBarCoverImage}
                />
              </Box>
            </Grid> */}
          </div>
          <>
            <Box
              id="whitecontainer"
              sx={{
                backgroundColor: "#FFF",
                padding: "0pt !important",
                margin: {
                  xs: "0px",
                  sm: "0px",
                  md: "0 10pt",
                  lg: "0 10pt",
                },
                paddingRight: {
                  xs: "20pt",
                  sm: "20pt",
                  md: "0px",
                  lg: "0px",
                },
                marginBottom: {
                  xs: "6pt",
                  sm: "6pt",
                  md: "6pt",
                  lg: "6pt",
                  xl: "6pt",
                },
                marginTop: {
                  xs: "3rem",
                  sm: "3rem",
                  md: "3rem",
                  lg: "3rem",
                  xl: "3rem",
                },
                marginLeft:"0pt !important"
              }}
            >
              <Typography
                className={classes.TheUnbrandedSecondSectionMainTitle}
                style={{
                  lineHeight: "20pt",
                  marginTop: "1rem",
                }}
              >
                {title_essentials}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "self-start",
                  gap: "0px 30px",
                  marginTop: "40px",
                }}
              >
                <div>
                  <p
                    className={classes.sectionTitlePdf}
                    style={{
                      fontFamily: "HelveticaNeue-Bold !important",
                    }}
                  >
                    {title_serves}
                  </p>
                  <div>
                    <Typography className={classes.theBarStepTextPdf}>
                      {serves} {title_people}
                    </Typography>
                  </div>
                </div>
                <div>
                  <p
                    className={classes.sectionTitlePdf}
                    style={{
                      fontFamily: "HelveticaNeue-Bold !important",
                    }}
                  >
                    {title_ingredients}
                  </p>
                  <div>
                    {ingredients.map((ingredient, _index) => (
                      <Typography
                        key={`${ingredient.name}`}
                        className={classes.theBarStepTextPdf}
                      >
                        <Box
                          component="span"
                          sx={{
                            marginRight: "0.5rem",
                            display: "inline",
                          }}
                        >
                          <Square
                            sx={{
                              width: "2px",
                              height: "2px",
                            }}
                          />
                        </Box>
                        {ingredient.qty > 0 && (
                          <Box
                            component="span"
                            sx={{
                              fontWeight: "bolder",
                              marginRight: "0.5rem",
                            }}
                          >
                            {ingredient.qty > 0
                              ? `${Math.round(ingredient.qty * serves*100)/100} ${ingredient.unit
                              }`
                              : ""}
                          </Box>
                        )}
                        {/* Ingredient name displayed normally */}
                        <Typography
                          component="span"
                          variant="body1"
                          className={classes.theBarStepTextPdf}
                        >
                          {ingredient.name}
                        </Typography>
                      </Typography>
                    ))}
                  </div>
                </div>
                <div>
                  <Grid container>
                    <Grid item xs={6} sm={6}>
                      <Typography
                        className={classes.sectionTitlePdf}
                      >
                        {title_equipment}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      {cocktail &&
                        cocktail.equipment.map((equipment) => (
                          <Typography
                            key={equipment.equipmentName}
                            className={classes.theBarStepTextPdf}
                          >
                            <Box
                              component="span"
                              sx={{
                                marginRight: "0.5rem",
                                display: "inline",
                              }}
                            >
                              <Square
                                sx={{
                                  width: "2px",
                                  height: "2px",
                                }}
                              />
                            </Box>
                            {equipment.equipmentName}
                          </Typography>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </div>
            
        
          <Typography
            className={classes.TheUnbrandedSecondSectionMainTitle}
            style={{
              lineHeight: "20pt",
              marginTop: "3rem",
              

            }}
          >
            {title_craft}
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              marginTop: "3rem",
              gap: "0px 30px",
            }}
          >
            {cocktail && (
              <>
                <div style={{ display: "flex" }}>
                  {cocktail.difficulty && (
                    <Box className={classes.theBarIconTextPdf}>
                      <SvgIcon
                       component={difficultyIcons[cocktail.difficulty as keyof typeof difficultyIcons]}
                        sx={{ marginRight: 1 }}
                      />
                      {cocktail.difficulty}
                    </Box>
                  )}
                </div>
                {cocktail.method && (
                  <Grid item xs={12} sm={12} md={5}>
                    {cocktail.method.map((step, _index) => (
                      <Box
                        key={`index-step-${_index}`}
                        className={classes.stepContainerPdf}
                      >
                        <Box className={classes.stepNumberPdf}>
                          <Typography
                            className={classes.stepNumberPdf}
                            sx={{ fontFamily: "HelveticaNeue !important" }}
                          >
                            {_index + 1}.
                          </Typography>
                        </Box>
                        <Box className={classes.stepTextPdf}>
                          <Typography className={classes.theBarStepTextPdf}>
                            {step.step}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                )}
              </>
            )}
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={4}
              display="flex"
              sx={{
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "end",
                  lg: "end",
                  xl: "end",
                },
                alignItems: "center",
              }}
            >
              <Box
                className={classes.containerImage}
                sx={{
                  width: {
                    xs: "300px !important",
                    sm: "300px !important",
                    md: "300px !important",
                    lg: "300px !important",
                  },
                  height: {
                    xs: "300px !important",
                    sm: "300px !important",
                    md: "300px !important",
                    lg: "300px !important",
                  }
                }}
              >
                <img
                  src={imageUrl[1] ? imageUrl[1] : NoImagePlaceholder}
                  alt="main"
                  className={classes.theBarCoverImage}
                />
              </Box>
            </Grid> */}
          </div>
          </Box>
          </>
        </div>
      </div>
    </Box>
  );
};

export default UnbrandedPdf;

