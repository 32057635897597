import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  signIn,
  signOut,
  fetchAuthSession,
} from "aws-amplify/auth";
import { LOGIN, LOGOUT, STORAGE_LOGIN } from "../constants/store-actions/auth";
import authReducer from "../reducers/authReducer";
import {
  AuthContextInitialState,
  AuthContextType,
  LoginResult,
} from "./types/auth";

export const authInitialState: AuthContextInitialState = {
  isLoggedIn: false,
  userEmail: null,
};

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loggedUserAuthData, setLoggedUserAuthData] = useState({
    email: "",
    password: "",
  } as { email: string; password: string });
  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const isBackendLogged = useMemo(() => {
    const email = localStorage.getItem("userEmail");
    const storageIsLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    return email && storageIsLoggedIn;
  }, []);

  const clearLocalStorage = () => {
    localStorage.removeItem("diageoAuthToken");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userShowName");
    localStorage.removeItem("isLoggedIn");
  };

  const setSession = (diageoAuthToken?: string | null) => {
    if (diageoAuthToken)
      localStorage.setItem("diageoAuthToken", diageoAuthToken);
    else localStorage.removeItem("diageoAuthToken");
  };

  const amplifyLogin = async (
    username: string,
    password: string
  ): Promise<LoginResult> => {
    try {
      await signIn({
        username,
        password,
      });
      return { success: true, message: "Login successful" };
    } catch (error: any) {
      return { success: false, message: error.message };
    }
  };

  const handleLogin = async (email: string, password: string) => {
    return new Promise<LoginResult>(async (res) => {
      const { success, message } = await amplifyLogin(email, password);
      if (success) {
        const tokens = (await fetchAuthSession()).tokens || null;
        if (tokens) {
          setSession(tokens.accessToken.toString());
          setLoggedUserAuthData({ email, password });
          localStorage.setItem("userEmail", email);
          localStorage.setItem("userShowName", email);
          localStorage.setItem("isLoggedIn", true.toString());
          res({ success: true, message: "Login successful" });
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              userEmail: email,
            },
          });
          navigate(`/upload_cocktail/cocktails`);
        }
      }
      res({
        success: false,
        message: message || "Login failed, unexpected error ocurred",
      });
    });
  };

  const handleStorageLogin = useCallback(async () => {
    const email = localStorage.getItem("userEmail");
    const storageIsLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    if (email && storageIsLoggedIn) {
      dispatch({
        type: STORAGE_LOGIN,
        payload: {
          isLoggedIn: true,
          userEmail: email,
        },
      });
      const tokens = (await fetchAuthSession()).tokens || null;
      if (tokens) {
        setSession(tokens.accessToken.toString());
      }
      if (!window.location.pathname.includes("auth")) {
        navigate(window.location.pathname);
      } else {
        navigate("/upload_cocktail/cocktails");
      }
    }
  }, [navigate]);

  const handleLogout = async () => {
    dispatch({
      type: LOGOUT,
      payload: {
        isLoggedIn: true,
        userEmail: null,
      },
    });
    await signOut();
    clearLocalStorage();
    setSession(null);
    setLoggedUserAuthData({ email: "", password: "" });
    navigate(`/upload_cocktail/auth/login`);
  };

  useEffect(() => {
    if (isBackendLogged && window.location.pathname !== "/")
      handleStorageLogin();
  }, [handleStorageLogin, isBackendLogged]);
  return (
    <AuthContext.Provider
      value={{
        ...state,
        isBackendLogged,
        handleLogin,
        handleStorageLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
