import React from "react";
import { Box } from "@mui/system";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";
import UnbrandedRecipeViewer from "./unbranded/UnbrandedRecipeViewer";
import TheBarRecipeViewer from "./thebar/TheBarRecipeViewer";

const RecipeViewer = () => {
  const { cocktailName, language, brand, iframe } = useParams();

  return (
    <>
      {brand !== "thebar" && (
        <UnbrandedRecipeViewer
          cocktailName={String(cocktailName)}
          language={String(language).toUpperCase()}
          iframe={iframe}
        />
      )}
      {brand === "thebar" && (
        <TheBarRecipeViewer
          cocktailName={String(cocktailName)}
          language={String(language).toUpperCase()}
          iframe={iframe}
        />
      )}

      {iframe === 'stand' && (
      <>
      <Box
        sx={{ minHeight: "121px", backgroundColor: "#111" }}
        display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex", xl: "flex" }}
      >
        <Grid container>
          <Grid item xs={8}>
            <Box id="footer" sx={{padding: "4rem 0rem"}}/>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "4rem",
            }}
          >
            <img
              src="/drinkiq_slim.png"
              alt="DrinkIQ"
              width={"150px"}
              height={"46px"}
            />
          </Grid>
        </Grid>
      </Box>
      <Helmet>

        <title>What's Your Cocktail?</title>
        {!window.location.hostname.includes("localhost") && (
          <script
            type="text/javascript"
            src="https://footer.diageohorizon.com/dfs/master.js"
          ></script>
        )}
        

      </Helmet>
      </>
      )}
    </>
    
  );
};

export default RecipeViewer;
