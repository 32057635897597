import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useStyles } from "../../styles";
import { ReactComponent as BeginnerSvg } from "../../../../assets/svg/Beginner.svg";
import { ReactComponent as IntermediateSvg } from "../../../../assets/svg/Intermediate.svg";
import { ReactComponent as HardSvg } from "../../../../assets/svg/Hard.svg";
import { ReactComponent as WineCupSvg } from "../../../../assets/svg/WineCup.svg";

interface UnbrandedRecommendationCardProps {
  cocktailName: string;
  difficulty: string;
  alc: string;
  image: string;
}

const UnbrandedRecommendationCard = ({
  cocktailName,
  difficulty,
  alc,
  image,
}: UnbrandedRecommendationCardProps) => {
  const classes = useStyles();
  const difficultyIcons = {
    Beginner: BeginnerSvg,
    Intermediate: IntermediateSvg,
    Hard: HardSvg,
  };
  return (
    <Box
      sx={{
        margin: {
          xs: "0 0",
          sm: "0 0",
          md: "0 1rem",
          lg: "0 1rem",
          xl: "0 1rem",
        },
      }}
    >
      <Card
        className={`${classes.cardContainer}`}
        sx={{
          width: {
            xs: "543px !important",
            sm: "543px !important",
            md: "515px !important",
            lg: "515px !important",
            xl: "515px !important",
          },
        }}
      >
        <CardMedia
          component="img"
          alt={cocktailName}
          height="415"
          image={image}
        />
        <CardContent>
          <Box sx={{ display: "flex", marginBottom: 0 }}>
            <Box className={classes.iconText} sx={{ marginRight: 2 }}>
              <SvgIcon component={WineCupSvg} sx={{ marginRight: 1 }} />
              {alc}
            </Box>
            <Box className={classes.iconText}>
              <SvgIcon
                component={
                  difficultyIcons[difficulty as keyof typeof difficultyIcons]
                }
                sx={{ marginRight: 1 }}
              />
              {difficulty}
            </Box>
          </Box>
          <Typography gutterBottom className={classes.cocktailTitle}>
            {cocktailName}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UnbrandedRecommendationCard;
