import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import './css/App.css';
import { AuthProvider } from "./contexts/AuthContext";
import AppRoutes from "./routes";
import SnackbarAlert from "./shared-components/SnackbarAlert";
import { SnackbarProvider } from "./contexts/SnackbarContext";
import { IframeParamProvider, useIframeParam } from "./contexts/IframeParamContext";  // Import your context provider and hook

const AppContent = () => {

  const { iframeParam } = useIframeParam();

  useEffect(() => {
    if (iframeParam === 'stand') {
    if (window.location.pathname !== '/') {
      const otAutoBlock = document.createElement('script');
      otAutoBlock.type = 'text/javascript';
      otAutoBlock.src = 'https://cdn-ukwest.onetrust.com/consent/018f0c93-2f46-71cd-a870-7326440b58b2/OtAutoBlock.js';
      document.head.appendChild(otAutoBlock);

      // Create and append otSDKStub
      const otSDKStub = document.createElement('script');
      otSDKStub.type = 'text/javascript';
      otSDKStub.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';
      otSDKStub.setAttribute('data-domain-script', '018f0c93-2f46-71cd-a870-7326440b58b2');
      document.head.appendChild(otSDKStub);

        // Create and append a script for OptanonWrapper
        const optanonWrapper = document.createElement('script');
        optanonWrapper.type = 'text/javascript';
        optanonWrapper.textContent = 'function OptanonWrapper() { }';
        document.head.appendChild(optanonWrapper);

        // Clean up function to remove the appended scripts when component unmounts
        return () => {
          document.head.removeChild(otAutoBlock);
          document.head.removeChild(otSDKStub);
          document.head.removeChild(optanonWrapper);
        };
      }
    }
  }, [iframeParam])



  return (

    <div style={{ width: '100vw', height: '100vh', backgroundColor: (window.location.pathname !== '/') && (iframeParam === 'stand') ? "#111111" : '' }}>
      <AppRoutes />
      <SnackbarAlert />
    </div>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <SnackbarProvider>
        <AuthProvider>
          <IframeParamProvider>  {/* Wrap App with context provider */}
            <AppContent />
          </IframeParamProvider>
        </AuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
};

export default App;
