import { GraphQLClient } from "graphql-request";

import * as mutations from "./graphql_personalized/mutations";
import * as queries from "./graphql_personalized/queries";
import dayjs from "dayjs";
import { Consumer } from "./types/consumer.types";
import { CocktailGraphQL, CocktailSimplified } from "./types/cocktail.types";

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL || "";
const GRAPHQL_API_KEY = process.env.REACT_APP_GRAPHQL_API_KEY || "";


export const graphQLHeaders = {
  "X-Api-Key": GRAPHQL_API_KEY,
};

export const clientGraphQL = new GraphQLClient(GRAPHQL_URL, {
  headers: graphQLHeaders,
});

export async function CreateConsumer(consumer_id: string, fallback: string) {
  try {
    const response = (await clientGraphQL.request(mutations.createConsumer, {
      input: {
        id: consumer_id,
        createdAt: dayjs().format("YYYY/MM/DD HH:mm:ss"),
        fallback: fallback
      },
    })) as { createConsumer: Consumer };

    return response.createConsumer;
  } catch (error) {
    console.error("Error creating consumer:", error);
  }
}

export async function GetConsumer(consumer_id: string) {
  try {
    const response = (await clientGraphQL.request(queries.getConsumer, {
      id: consumer_id,
    })) as { getConsumer: Consumer };

    return response.getConsumer;
  } catch (error) {
    console.error("Error parsing consumer:", error);
  }
}

export async function UpdateConsumer(input: Consumer) {
  try {
    const response = (await clientGraphQL.request(mutations.updateConsumer, {
      input,
    })) as { updateConsumer: Consumer };

    //console.log("Save interaction", response.updateConsumer)

    return response.updateConsumer;
  } catch (error) {
    console.error("Error updating consumer:", error);
  }
}

export async function ListCocktails(nextToken?: string) {
  try {
    const response = (await clientGraphQL.request(queries.listCocktails)) as {
      listCocktails: { items: CocktailSimplified[] };
    };

    return response.listCocktails.items;
  } catch (error) {
    console.error("Error parsing consumer:", error);
  }
}

export async function GetCocktail(cocktailName: string) {
  try {
    const response = (await clientGraphQL.request(queries.getCocktail, {
      cocktailName,
    })) as {
      getCocktail: any;
    };

    return response.getCocktail;
  } catch (error) {
    console.error("Error parsing consumer:", error);
  }
}

export async function GetCocktails(cocktailNames: string[]) {
  try {
    const response = (await clientGraphQL.request(queries.getCocktails, {
      cocktailNames,
    })) as {
      getCocktails: any;
    };

    return response.getCocktails;
  } catch (error) {
    console.error("Error parsing consumer:", error);
  }
}

export async function GetCocktailsByLanguage(cocktailNames: string[], language: string) {
  try {
    const response = (await clientGraphQL.request(queries.getCocktailByLanguage, {
      "cocktailNames": cocktailNames, "language": language
    })) as {
      getCocktailByLanguage: any;
    };

    return response.getCocktailByLanguage;
  } catch (error) {
    console.error("Error parsing consumer:", error);
  }
}

export async function CreateCocktail(input: CocktailGraphQL) {
  try {
    const response = (await clientGraphQL.request(mutations.createCocktail, {
      input,
    })) as { createCocktail: CocktailGraphQL };

    return response.createCocktail;
  } catch (error) {
    throw new Error("Server Error Creating Cocktail");
  }
}

export async function UpdateCocktail(input: CocktailGraphQL) {
  try {
    const response = (await clientGraphQL.request(mutations.updateCocktail, {
      input,
    })) as { updateCocktail: CocktailGraphQL };

    return response.updateCocktail;
  } catch (error) {
    throw new Error("Server Error Updating Cocktail");
  }
}
