import UnbrandedRecommendationCard from "./UnbrandedRecommendationCard";
import { useStyles } from "../../styles";
import { useEffect, useState } from "react";
import { CarouselCardData } from "../../../../types/cocktail.types";
import { GetCocktail } from "../../../../graphql_queries";
import { getS3FileUrl } from "../../../../utils/S3Manager";
import NoImagePlaceholder from "../../../../assets/imagePlaceholders/NoImagePlaceholder.jpg";
import { Box } from "@mui/system";

interface UnbrandedCarouselProps {
  cocktails: string[];
  cocktailName: string;
  language: string;
  iframe: string;
}
export const UnbrandedCarousel = ({
  cocktails,
  cocktailName,
  language,
  iframe
}: UnbrandedCarouselProps) => {
  const classes = useStyles();
  const [cocktailsList, setCocktailsList] = useState<CarouselCardData[]>([]);

  useEffect(() => {
    const getCocktailRecommendationCards = async () => {
      const items: CarouselCardData[] = [];
      for await (const recommendation of cocktails) {
        if (recommendation !== cocktailName) {
          const graphqlData = await GetCocktail(recommendation);
          const EN = graphqlData[language.toUpperCase()];
          const lifeStyleImages = EN.images
            ? EN.images.filter(
                (image: any) => image.imageType === "view_recipe_first_image"
              )
            : [];
          let lifeStyleImageUrl = "";
          if (lifeStyleImages.length > 0) {

            const url  = `https://d27hayjchchphb.cloudfront.net/public/${lifeStyleImages[0].imageUrl.split("/public/").slice(-1)[0]}`

            lifeStyleImageUrl = url;
          }
          var cocktailIdList = cocktails.join("_");
          console.log("cocktailIdList === ", cocktailIdList)
          

          const newRoute = `recipe/unbranded/${recommendation}/${language.toUpperCase()}/${iframe}?cocktailIds=${cocktailIdList}`
          const newItem = {
            cocktailName: EN.cocktailTitle || "",
            difficulty: EN.difficulty || "",
            alc: "",
            image: lifeStyleImageUrl,
            url: `${window.location.origin}/${newRoute}`,
          };
          if (EN.spirit.length) {
            newItem.alc = `${EN.spirit[0].name}`;
          }
          items.push(newItem);
        }
      }
      setCocktailsList(items);
    };
    getCocktailRecommendationCards();
  }, [cocktailName, cocktails, language]);

  return (
    <>
      <Box
        className={classes.carouselContainer}
        sx={{ width: {
          xs: "358px !important",
          sm: "358px !important",
          md: "100% !important",
          lg: "100% !important",
          xl: "100% !important",
        } }}
      >
        <div className={`${classes.carouselBoxes} carousel-boxes`}>
          {cocktailsList.map((item, _index) => (
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => window.open(item.url, "_blank")}
              key={`recommendation-card-container-${_index}`}
            >
              <UnbrandedRecommendationCard
                key={`recommendation-card-${_index}`}
                cocktailName={item.cocktailName}
                difficulty={item.difficulty}
                alc={item.alc || "No Alcohol"}
                image={item.image || NoImagePlaceholder}
              />
            </Box>
          ))}
        </div>
      </Box>
    </>
  );
};
