import Orange from "./quiz/quizLoaderImages/quiz1/Margarita_Hero.jpg";
import Blue from "./quiz/quizLoaderImages/quiz1/Manhattan_Hero.jpg";
import Yellow from "./quiz/quizLoaderImages/quiz1/Paloma_Additional_2.jpg";
import Green from "./quiz/quizLoaderImages/quiz1/Tequila_Sunrise_Hero.jpg";

import Orange_quiz4 from "./quiz/quizLoaderImages/quiz4/Gimlet_Hero.jpg";
import Blue_quiz4 from "./quiz/quizLoaderImages/quiz4/Manhattan_Hero.jpg";
import Yellow_quiz4 from "./quiz/quizLoaderImages/quiz4/Spicy_Margarita_Hero.jpg";
import Green_quiz4 from "./quiz/quizLoaderImages/quiz4/Tom_Collins_Additional_2.jpg";

import Orange_webp from "./quiz/quizLoaderImages/quiz1/Margarita Hero.webp";
import Blue_webp from "./quiz/quizLoaderImages/quiz1/Manhattan Hero.webp";
import Yellow_webp from "./quiz/quizLoaderImages/quiz1/Paloma Additional 2.webp";
import Green_webp from "./quiz/quizLoaderImages/quiz1/Tequila Sunrise Hero.webp";

import Orange_quiz4_webp from "./quiz/quizLoaderImages/quiz4/Gimlet Hero.webp";
import Blue_quiz4_webp from "./quiz/quizLoaderImages/quiz4/Manhattan Hero.webp";
import Yellow_quiz4_webp from "./quiz/quizLoaderImages/quiz4/Spicy Margarita Hero.webp";
import Green_quiz4_webp from "./quiz/quizLoaderImages/quiz4/Tom Collins Additional 2.webp";

// Party category
import HoneyGingerChicken from "./quiz/quizLoaderImages/quiz3/dishes/party/Honey Ginger Chicken Wings.png";
import PineappleSalsa from "./quiz/quizLoaderImages/quiz3/dishes/party/Fresh Pineapple Salsa.png";
import JalapenoPoppers from "./quiz/quizLoaderImages/quiz3/dishes/party/Jalapeno Poppers.png";
import LemonCheesecakeBars from "./quiz/quizLoaderImages/quiz3/dishes/party/Lemon Cheesecake Bars.png";
import RosemaryGarlicBread from "./quiz/quizLoaderImages/quiz3/dishes/party/Rosemary Garlic Beer Bread Recipe.png";
import SpinachArtichokeDip from "./quiz/quizLoaderImages/quiz3/dishes/party/Spinach Artichoke Dip.png";
import ChocolateStrawberries from "./quiz/quizLoaderImages/quiz3/dishes/party/Chocolate Covered Strawberries.png";
import RedVelvetCupcakes from "./quiz/quizLoaderImages/quiz3/dishes/party/Red Velvet Cupcakes with Cream Cheese Frosting.png";
import ChurroBites from "./quiz/quizLoaderImages/quiz3/dishes/party/Churro Bites.png";
import TruffleFries from "./quiz/quizLoaderImages/quiz3/dishes/party/Truffle Fries with parsley and garlic.png";
import CoconutMacroons from "./quiz/quizLoaderImages/quiz3/dishes/party/Coconut Macroons.png";
import FishTacosWithSlaw from "./quiz/quizLoaderImages/quiz3/dishes/party/Fish tacos with red cabbage slaw.png";

// Party category
import HoneyGingerChicken_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Honey Ginger Chicken Wings.webp";
import PineappleSalsa_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Fresh Pineapple Salsa.webp";
import JalapenoPoppers_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Jalapeno Poppers.webp";
import LemonCheesecakeBars_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Lemon Cheesecake Bars.webp";
import RosemaryGarlicBread_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Rosemary Garlic Beer Bread Recipe.webp";
import SpinachArtichokeDip_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Spinach Artichoke Dip.webp";
import ChocolateStrawberries_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Chocolate Covered Strawberries.webp";
import RedVelvetCupcakes_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Red Velvet Cupcakes with Cream Cheese Frosting.webp";
import ChurroBites_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Churro Bites.webp";
import TruffleFries_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Truffle Fries with parsley and garlic.webp";
import CoconutMacroons_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Coconut Macroons.webp";
import FishTacosWithSlaw_webp from "./quiz/quizLoaderImages/quiz3/dishes/party/Fish tacos with red cabbage slaw.webp";


// Sophisticated category
import Bruschetta from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/bruschetta with tomato and basil.png";
import Paella from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Paella.png";
import RosemaryPotatoes from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Rosemary Potatoes.png";
import FennelSalad from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Fennel and Citrus Salad with Pomegranate.png";
import GrapefruitMintSorbet from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Grapefruit Mint Sorbet.png";
import SpicyPasta from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Spicy Rigatoni.png";
import Tiramisu from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/tiramisu.png";
import CaramelIceCream from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Salted Caramel Ice Cream.png";
import CranberryBakedBrie from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Cranberry Walnut Baked Brie.png";
import LemonArtichokeDip from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Lemon Artichoke Dip.png";
import MangoChutney from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Mango Chutney.png";
import CoconutCurry from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Coconut shrimp curry.png";

// Sophisticated category
import Bruschetta_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/bruschetta with tomato and basil.webp";
import Paella_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Paella.webp";
import RosemaryPotatoes_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Rosemary Potatoes.webp";
import FennelSalad_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Fennel and Citrus Salad with Pomegranate.webp";
import GrapefruitMintSorbet_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Grapefruit Mint Sorbet.webp";
import SpicyPasta_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Spicy Rigatoni.webp";
import Tiramisu_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/tiramisu.webp";
import CaramelIceCream_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Salted Caramel Ice Cream.webp";
import CranberryBakedBrie_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Cranberry Walnut Baked Brie.webp";
import LemonArtichokeDip_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Lemon Artichoke Dip.webp";
import MangoChutney_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Mango Chutney.webp";
import CoconutCurry_webp from "./quiz/quizLoaderImages/quiz3/dishes/sophisticated/Coconut shrimp curry.webp";


// Casual category
import CreamyZucchiniPasta from "./quiz/quizLoaderImages/quiz3/dishes/casual/Creamy Zucchini Garlic Pasta.png";
import JerkChickenSkewers from "./quiz/quizLoaderImages/quiz3/dishes/casual/Jerk Chicken Skewers.png";
import PestoRice from "./quiz/quizLoaderImages/quiz3/dishes/casual/Pesto rice.png";
import VegetablePizza from "./quiz/quizLoaderImages/quiz3/dishes/casual/Grilled Vegetable Pizza.png";
import HarissaHummus from "./quiz/quizLoaderImages/quiz3/dishes/casual/Harissa Hummus.png";
import ChocolateChipCookies from "./quiz/quizLoaderImages/quiz3/dishes/casual/Chocolate Chip Cookies.png";
import MapleIceCream from "./quiz/quizLoaderImages/quiz3/dishes/casual/Maple Ice Cream.png";
import GrapefruitAvocadoSalad from "./quiz/quizLoaderImages/quiz3/dishes/casual/Grapefruit Avocado Salad.png";
import StrawberryShortcake from "./quiz/quizLoaderImages/quiz3/dishes/casual/Strawberry Shortcake.png";
import CoconutCupcakes from "./quiz/quizLoaderImages/quiz3/dishes/casual/Coconut Cupcakes.png";
import PineappleUpsideDownCake from "./quiz/quizLoaderImages/quiz3/dishes/casual/Pineapple Upside Down Cake.png";
import MediterraneanPasta from "./quiz/quizLoaderImages/quiz3/dishes/casual/Mediterranean pasta salad.png";

// Casual category
import CreamyZucchiniPasta_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Creamy Zucchini Garlic Pasta.webp";
import JerkChickenSkewers_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Jerk Chicken Skewers.webp";
import PestoRice_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Pesto rice.webp";
import VegetablePizza_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Grilled Vegetable Pizza.webp";
import HarissaHummus_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Harissa Hummus.webp";
import ChocolateChipCookies_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Chocolate Chip Cookies.webp";
import MapleIceCream_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Maple Ice Cream.webp";
import GrapefruitAvocadoSalad_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Grapefruit Avocado Salad.webp";
import StrawberryShortcake_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Strawberry Shortcake.webp";
import CoconutCupcakes_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Coconut Cupcakes.webp";
import PineappleUpsideDownCake_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Pineapple Upside Down Cake.webp";
import MediterraneanPasta_webp from "./quiz/quizLoaderImages/quiz3/dishes/casual/Mediterranean pasta salad.webp";


// Familiar category
import LemonPestoPasta from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Pasta with lemon pesto.png";
import ChiliConCarne from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Chili con carne.png";
import CinnamonCoffeeCake from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Cinnamon Coffee Cake.png";
import GingerbreadWaffles from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Gingerbread Waffles.png";
import KaleCaesarSalad from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Kale caesar salad.png";
import SaltedCaramelCookies from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Salted Caramel Cookies.png";
import ChipsAndSalsa from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Chips and Salsa.png";
import CaramelizedOnionFrittata from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Caramelized Onion Frittata.png";
import KeyLimeCheesecake from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Key Lime Cheesecake Bites.png";
import BlueberryScones from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Lemon Blueberry Scones.png";
import BananaBread from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Banana bread.png";
import CoconutBlondies from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Dark Chocolate Coconut Blondies.png";

// Familiar category
import LemonPestoPasta_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Pasta with lemon pesto.webp";
import ChiliConCarne_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Chili con carne.webp";
import CinnamonCoffeeCake_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Cinnamon Coffee Cake.webp";
import GingerbreadWaffles_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Gingerbread Waffles.webp";
import KaleCaesarSalad_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Kale caesar salad.webp";
import SaltedCaramelCookies_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Salted Caramel Cookies.webp";
import ChipsAndSalsa_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Chips and Salsa.webp";
import CaramelizedOnionFrittata_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Caramelized Onion Frittata.webp";
import KeyLimeCheesecake_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Key Lime Cheesecake Bites.webp";
import BlueberryScones_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Lemon Blueberry Scones.webp";
import BananaBread_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Banana bread.webp";
import CoconutBlondies_webp from "./quiz/quizLoaderImages/quiz3/dishes/familiar/Dark Chocolate Coconut Blondies.webp";


import Loader1 from "./quiz/quizLoaderImages/quiz4/upside_down/Whiskey_Sour_Hero.jpg"
import Loader2 from "./quiz/quizLoaderImages/quiz4/upside_down/Paloma_Additional_2.jpg"
import Loader3 from "./quiz/quizLoaderImages/quiz4/upside_down/Vesper_Hero.jpg"
import Loader4 from "./quiz/quizLoaderImages/quiz4/upside_down/Margarita_Hero.jpg"
import Loader5 from "./quiz/quizLoaderImages/quiz4/upside_down/Tequila_Sunrise_Hero.jpg"
import Loader6 from "./quiz/quizLoaderImages/quiz4/upside_down/Whiskey_Sour_Hero.jpg"

import LoaderHorizontal1 from "./quiz/quizLoaderImages/quiz4/normal/Whiskey_Sour_Hero.jpg"
import LoaderHorizontal2 from "./quiz/quizLoaderImages/quiz4/normal/Paloma_Additional_2.jpg"
import LoaderHorizontal3 from "./quiz/quizLoaderImages/quiz4/normal/Vesper_Hero.jpg"
import LoaderHorizontal4 from "./quiz/quizLoaderImages/quiz4/normal/Margarita_Hero.jpg"
import LoaderHorizontal5 from "./quiz/quizLoaderImages/quiz4/normal/Tequila_Sunrise_Hero.jpg"
import LoaderHorizontal6 from "./quiz/quizLoaderImages/quiz4/normal/Whiskey_Sour_Hero.jpg"

import Loader_webp_1 from "./quiz/quizLoaderImages/quiz4/upside_down/Whiskey Sour Hero.webp"
import Loader_webp_2 from "./quiz/quizLoaderImages/quiz4/upside_down/Paloma Additional 2.webp"
import Loader_webp_3 from "./quiz/quizLoaderImages/quiz4/upside_down/Vesper Hero.webp"
import Loader_webp_4 from "./quiz/quizLoaderImages/quiz4/upside_down/Margarita Hero.webp"
import Loader_webp_5 from "./quiz/quizLoaderImages/quiz4/upside_down/Tequila Sunrise Hero.webp"
import Loader_webp_6 from "./quiz/quizLoaderImages/quiz4/upside_down/Whiskey Sour Hero.webp"

import Loader_webp_Horizontal1 from "./quiz/quizLoaderImages/quiz4/normal/Whiskey Sour Hero.webp"
import Loader_webp_Horizontal2 from "./quiz/quizLoaderImages/quiz4/normal/Paloma Additional 2.webp"
import Loader_webp_Horizontal3 from "./quiz/quizLoaderImages/quiz4/normal/Vesper Hero.webp"
import Loader_webp_Horizontal4 from "./quiz/quizLoaderImages/quiz4/normal/Margarita Hero.webp"
import Loader_webp_Horizontal5 from "./quiz/quizLoaderImages/quiz4/normal/Tequila Sunrise Hero.webp"
import Loader_webp_Horizontal6 from "./quiz/quizLoaderImages/quiz4/normal/Whiskey Sour Hero.webp"


const assets = {
  quiz1: {
    jpg:{
        orange: Orange,
        blue: Blue,
        yellow: Yellow,
        green: Green
    },
    webp:{
        orange: Orange_webp,
        blue: Blue_webp,
        yellow: Yellow_webp,
        green: Green_webp
    }
  },
  quiz4: {
    jpg:{
        orange: Orange_quiz4,
        blue: Blue_quiz4,
        yellow: Yellow_quiz4,
        green: Green_quiz4,
        loader1: Loader1,
        loader2: Loader2,
        loader3: Loader3,
        loader4: Loader4,
        loader5: Loader5,
        loader6: Loader6,
        loaderhorizontal1: LoaderHorizontal1,
        loaderhorizontal2: LoaderHorizontal2,
        loaderhorizontal3: LoaderHorizontal3,
        loaderhorizontal4: LoaderHorizontal4,
        loaderhorizontal5: LoaderHorizontal5,
        loaderhorizontal6: LoaderHorizontal6,
    },
    webp:{
        orange: Orange_quiz4_webp,
        blue: Blue_quiz4_webp,
        yellow: Yellow_quiz4_webp,
        green: Green_quiz4_webp,
        loader1: Loader_webp_1,
        loader2: Loader_webp_2,
        loader3: Loader_webp_3,
        loader4: Loader_webp_4,
        loader5: Loader_webp_5,
        loader6: Loader_webp_6,
        loaderhorizontal1: Loader_webp_Horizontal1,
        loaderhorizontal2: Loader_webp_Horizontal2,
        loaderhorizontal3: Loader_webp_Horizontal3,
        loaderhorizontal4: Loader_webp_Horizontal4,
        loaderhorizontal5: Loader_webp_Horizontal5,
        loaderhorizontal6: Loader_webp_Horizontal6,
    },

  },

  dishes: {
    party: [
      {
        name: "Honey Ginger Chicken",
        name_es: "Pollo con Miel y Jengibre",
        imageUrl: HoneyGingerChicken,
        imageUrl_webp: HoneyGingerChicken_webp,
      },
      {
        name: "Pineapple Salsa",
        name_es: "Salsa de Piña",
        imageUrl: PineappleSalsa,
        imageUrl_webp: PineappleSalsa_webp,
      },
      {
        name: "Jalapeño Poppers",
        name_es: "Poppers de Jalapeño",
        imageUrl: JalapenoPoppers,
        imageUrl_webp: JalapenoPoppers_webp,
      },
      {
        name: "Lemon Cheesecake Bars",
        name_es: "Tarta de Queso con Limón",
        imageUrl: LemonCheesecakeBars,
        imageUrl_webp: LemonCheesecakeBars_webp,
      },
      {
        name: "Rosemary Garlic Bread",
        name_es: "Pan de Ajo y Romero",
        imageUrl: RosemaryGarlicBread,
        imageUrl_webp: RosemaryGarlicBread_webp,
      },
      {
        name: "Spinach Artichoke Dip",
        name_es: "Dip de alcachofa y espinaca",
        imageUrl: SpinachArtichokeDip,
        imageUrl_webp: SpinachArtichokeDip_webp,
      },
      {
        name: "Chocolate Strawberries",
        name_es: "Fresas con Chocolate",
        imageUrl: ChocolateStrawberries,
        imageUrl_webp: ChocolateStrawberries_webp,
      },
      {
        name: "Red Velvet Cupcakes",
        name_es: "Cupcakes de Red Velvet",
        imageUrl: RedVelvetCupcakes,
        imageUrl_webp: RedVelvetCupcakes_webp,
      },
      {
        name: "Churro Bites",
        name_es: "Churros",
        imageUrl: ChurroBites,
        imageUrl_webp: ChurroBites_webp,
      },
      {
        name: "Truffle Fries",
        name_es: "Patatas Fritas con Trufa",
        imageUrl: TruffleFries,
        imageUrl_webp: TruffleFries_webp,
      },
      {
        name: "Coconut Macroons",
        name_es: "Macaroons de Coco",
        imageUrl: CoconutMacroons,
        imageUrl_webp: CoconutMacroons_webp,
      },
      {
        name: "Fish Tacos with Slaw",
        name_es: "Tacos de Pescado con Ensalada de Repollo",
        imageUrl: FishTacosWithSlaw,
        imageUrl_webp: FishTacosWithSlaw_webp,
      },
    ],
    sophisticated: [
      {
        name: "Bruschetta",
        name_es: "Bruschetta",
        imageUrl: Bruschetta,
        imageUrl_webp: Bruschetta_webp,
      },
      {
        name: "Paella",
        name_es: "Paella",
        imageUrl: Paella,
        imageUrl_webp: Paella_webp,
      },
      {
        name: "Rosemary Potatoes",
        name_es: "Patatas al Romero",
        imageUrl: RosemaryPotatoes,
        imageUrl_webp: RosemaryPotatoes_webp,
      },
      {
        name: "Fennel Salad",
        name_es: "Ensalada de Hinojo",
        imageUrl: FennelSalad,
        imageUrl_webp: FennelSalad_webp,
      },
      {
        name: "Grapefruit Mint Sorbet",
        name_es: "Sorbete de Pomelo y Menta",
        imageUrl: GrapefruitMintSorbet,
        imageUrl_webp: GrapefruitMintSorbet_webp,
      },
      {
        name: "Spicy Pasta",
        name_es: "Pasta Picante",
        imageUrl: SpicyPasta,
        imageUrl_webp: SpicyPasta_webp,
      },
      {
        name: "Tiramisu",
        name_es: "Tiramisu",
        imageUrl: Tiramisu,
        imageUrl_webp: Tiramisu_webp,
      },
      {
        name: "Caramel Ice Cream",
        name_es: "Helado de Caramelo",
        imageUrl: CaramelIceCream,
        imageUrl_webp: CaramelIceCream_webp,
      },
      {
        name: "Cranberry Baked Brie",
        name_es: "Brie al horno con Arándanos rojos",
        imageUrl: CranberryBakedBrie,
        imageUrl_webp: CranberryBakedBrie_webp,
      },
      {
        name: "Lemon Artichoke Dip",
        name_es: "Dip de alcachofas y limón",
        imageUrl: LemonArtichokeDip,
        imageUrl_webp: LemonArtichokeDip_webp,
      },
      {
        name: "Mango Chutney",
        name_es: "Chutney de Mango",
        imageUrl: MangoChutney,
        imageUrl_webp: MangoChutney_webp,
      },
      {
        name: "Coconut Curry",
        name_es: "Curry de Coco",
        imageUrl: CoconutCurry,
        imageUrl_webp: CoconutCurry_webp,
      },
    ],
    casual: [
      {
        name: "Creamy Zucchini Pasta",
        name_es: "Pasta Cremosa de Calabacín",
        imageUrl: CreamyZucchiniPasta,
        imageUrl_webp: CreamyZucchiniPasta_webp,
      },
      {
        name: "Jerk Chicken Skewers",
        name_es: "Brochetas de Pollo",
        imageUrl: JerkChickenSkewers,
        imageUrl_webp: JerkChickenSkewers_webp,
      },
      {
        name: "Pesto Rice",
        name_es: "Arroz con Pesto",
        imageUrl: PestoRice,
        imageUrl_webp: PestoRice_webp,
      },
      {
        name: "Vegetable Pizza",
        name_es: "Pizza Vegetariana",
        imageUrl: VegetablePizza,
        imageUrl_webp: VegetablePizza_webp,
      },
      {
        name: "Harissa Hummus",
        name_es: "Hummus Harissa",
        imageUrl: HarissaHummus,
        imageUrl_webp: HarissaHummus_webp,
      },
      {
        name: "Chocolate Chip Cookies",
        name_es: "Galletas de Chocolate",
        imageUrl: ChocolateChipCookies,
        imageUrl_webp: ChocolateChipCookies_webp,
      },
      {
        name: "Maple Ice Cream",
        name_es: "Helado de Arce",
        imageUrl: MapleIceCream,
        imageUrl_webp: MapleIceCream_webp,
      },
      {
        name: "Grapefruit Avocado Salad",
        name_es: "Ensalada de Aguacate y Pomelo",
        imageUrl: GrapefruitAvocadoSalad,
        imageUrl_webp: GrapefruitAvocadoSalad_webp,
      },
      {
        name: "Strawberry Shortcake",
        name_es: "Pastel de Fresas",
        imageUrl: StrawberryShortcake,
        imageUrl_webp: StrawberryShortcake_webp,
      },
      {
        name: "Coconut Cupcakes",
        name_es: "Cupcakes de Coco",
        imageUrl: CoconutCupcakes,
        imageUrl_webp: CoconutCupcakes_webp,
      },
      {
        name: "Pineapple Upside Down Cake",
        name_es: "Tarta de Piña al revés",
        imageUrl: PineappleUpsideDownCake,
        imageUrl_webp: PineappleUpsideDownCake_webp,
      },
      {
        name: "Mediterranean Pasta",
        name_es: "Pasta Mediterránea",
        imageUrl: MediterraneanPasta,
        imageUrl_webp: MediterraneanPasta_webp,
      },
    ],
    familiar: [
      {
        name: "Lemon pesto pasta",
        name_es: "Pasta al Pesto de Limón",
        imageUrl: LemonPestoPasta,
        imageUrl_webp: LemonPestoPasta_webp,
      },
      {
        name: "Chili con carne",
        name_es: "Chili con carne",
        imageUrl: ChiliConCarne,
        imageUrl_webp: ChiliConCarne_webp,
      },
      {
        name: "Cinnamon Coffee Cake",
        name_es: "Bizcocho de Café con Canela",
        imageUrl: CinnamonCoffeeCake,
        imageUrl_webp: CinnamonCoffeeCake_webp,
      },
      {
        name: "Gingerbread Waffles",
        name_es: "Gofres de Galletas de Jengibre",
        imageUrl: GingerbreadWaffles,
        imageUrl_webp: GingerbreadWaffles_webp,
      },
      {
        name: "Kale caesar salad",
        name_es: "Ensalada César de Kale",
        imageUrl: KaleCaesarSalad,
        imageUrl_webp: KaleCaesarSalad_webp,
      },
      {
        name: "Salted Caramel Cookies",
        name_es: "Galletas de Caramelo Salado",
        imageUrl: SaltedCaramelCookies,
        imageUrl_webp: SaltedCaramelCookies_webp,
      },
      {
        name: "Chips and Salsa",
        name_es: "Nachos con Salsa",
        imageUrl: ChipsAndSalsa,
        imageUrl_webp: ChipsAndSalsa_webp,
      },
      {
        name: "Caramelized Onion Frittata",
        name_es: "Frittata con Cebolla Caramelizada",
        imageUrl: CaramelizedOnionFrittata,
        imageUrl_webp: CaramelizedOnionFrittata_webp,
      },
      {
        name: "Key Lime Cheesecake",
        name_es: "Cheesecake de lima",
        imageUrl: KeyLimeCheesecake,
        imageUrl_webp: KeyLimeCheesecake_webp,
      },
      {
        name: "Blueberry Scones",
        name_es: "Bollos de Arándano",
        imageUrl: BlueberryScones,
        imageUrl_webp: BlueberryScones_webp,
      },
      {
        name: "Banana bread",
        name_es: "Bizcocho de Plátano",
        imageUrl: BananaBread,
        imageUrl_webp: BananaBread_webp,
      },
      {
        name: "Coconut Blondies",
        name_es: "Blondies de Coco",
        imageUrl: CoconutBlondies,
        imageUrl_webp: CoconutBlondies_webp,
      },
    ]
  }

};

export default assets;