import React, { useEffect, useState, useMemo } from "react";
import { Box } from "@mui/system";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import PartyFullSvg from "../../../assets/quiz/quizGlasses/party-full.svg";
import CasualFullSvg from "../../../assets/quiz/quizGlasses/casual-full.svg";
import FamiliarFullSvg from "../../../assets/quiz/quizGlasses/familiar-full.svg";
import SophisticatedFullSvg from "../../../assets/quiz/quizGlasses/sophisticated-full.svg";
import PartyEmptySvg from "../../../assets/quiz/quizGlasses/party-empty.svg";
import CasualEmptySvg from "../../../assets/quiz/quizGlasses/casual-empty.svg";
import FamiliarEmptySvg from "../../../assets/quiz/quizGlasses/familiar-empty.svg";
import SophisticatedEmptySvg from "../../../assets/quiz/quizGlasses/sophisticated-empty.svg";

import { useStyles } from "../styles";
import { QuizStepProps, atmosphereColors } from "..";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const QuizStep2 = ({
  setQuizStep,
  atmosphere = "",
  lang = "EN_UK",
  iframe = 'stand',
  setAtmosphere = () => { },
  setDishesSelected = () => { },
}: QuizStepProps) => {
  const classes = useStyles();
  const [sophisticatedHovered, setSophisticatedHovered] = useState(false);

  const CLOUDFRONT_URL = process.env.REACT_APP_CDN;

  const Cover = `${CLOUDFRONT_URL}/public/FE/stepTwoCover.jpg`

  const whatsyourcocktailtitle = useMemo(
    () => ({
      EN_UK: "What's your cocktail?",
      EN_US: "What's your cocktail?",
      EN_ROW: "What's your cocktail?",
      ES: "¿CUÁL ES TU CÓCTEL?",
      ES_ROW: "¿CUÁL ES TU CÓCTEL?",
      ES_US: "¿CUÁL ES TU CÓCTEL?",
      ES_ES: "¿CUÁL ES TU CÓCTEL?",
      ES_MX: "¿CUÁL ES TU CÓCTEL?",
    }),
    []
  );

  const whatsatmospheretitle = useMemo(
    () => ({
      EN_UK: "What atmosphere do you want to create?",
      EN_US: "What atmosphere do you want to create?",
      EN_ROW: "What atmosphere do you want to create?",
      ES: "¿Qué ambiente quieres crear?",
      ES_ROW: "¿Qué ambiente quieres crear?",
      ES_US: "¿Qué ambiente quieres crear?",
      ES_ES: "¿Qué ambiente quieres crear?",
      ES_MX: "¿Qué ambiente quieres crear?",
    }),
    []
  );

  const sophisticatedtitle = useMemo(
    () => ({
      EN_UK: "Sophisticated Vibes",
      EN_US: "Sophisticated Vibes",
      EN_ROW: "Sophisticated Vibes",
      ES: "Sofisticada",
      ES_ROW: "Sofisticada",
      ES_US: "Sofisticada",
      ES_ES: "Sofisticada",
      ES_MX: "Sofisticada",
    }),
    []
  );

  const partytitle = useMemo(
    () => ({
      EN_UK: "Party Vibes",
      EN_US: "Party Vibes",
      EN_ROW: "Party Vibes",
      ES: "Fiesta",
      ES_ROW: "Fiesta",
      ES_US: "Fiesta",
      ES_ES: "Fiesta",
      ES_MX: "Fiesta",
    }),
    []
  );

  const unwindtitle = useMemo(
    () => ({
      EN_UK: "Unwind and Relax",
      EN_US: "Unwind and Relax",
      EN_ROW: "Unwind and Relax",
      ES: "Relax y Desconexión",
      ES_ROW: "Relax y Desconexión",
      ES_US: "Relax y Desconexión",
      ES_ES: "Relax y Desconexión",
      ES_MX: "Relax y Desconexión",
    }),
    []
  );

  const casualtitle = useMemo(
    () => ({
      EN_UK: "Casual Get Together",
      EN_US: "Casual Get Together",
      EN_ROW: "Casual Get Together",
      ES: "Reunión informal",
      ES_ROW: "Reunión informal",
      ES_US: "Reunión informal",
      ES_ES: "Reunión informal",
      ES_MX: "Reunión informal",
    }),
    []
  );

  const gobacktitle = useMemo(
    () => ({
      EN_UK: "BACK",
      EN_US: "BACK",
      EN_ROW: "BACK",
      ES: "ATRÁS",
      ES_ROW: "ATRÁS",
      ES_US: "ATRÁS",
      ES_ES: "ATRÁS",
      ES_MX: "ATRÁS",
    }),
    []
  );

  const continuetitle = useMemo(
    () => ({
      EN_UK: "CONTINUE",
      EN_US: "CONTINUE",
      EN_ROW: "CONTINUE",
      ES: "CONTINUAR",
      ES_ROW: "CONTINUAR",
      ES_US: "CONTINUAR",
      ES_ES: "CONTINUAR",
      ES_MX: "CONTINUAR",
    }),
    []
  );


  const title = whatsyourcocktailtitle[lang] || "What's your cocktail?";
  const titlewhatatmosphere = whatsatmospheretitle[lang] || "What atmosphere do you want to create?";
  const titlesophisticated = sophisticatedtitle[lang] || "Sophisticated Vibes";
  const title_party = partytitle[lang] || "Party Vibes";
  const title_unwind = unwindtitle[lang] || "Unwind and Relax";
  const titlescasual = casualtitle[lang] || "Casual Get Together";
  const titlesgoback = gobacktitle[lang] || "BACK";
  const titlescontinue = continuetitle[lang] || "Continue";

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "custom_event",
      event_name: "quiz_step",
      step: "question 1",
    });
    setAtmosphere("");
    setDishesSelected([]);
  }, [setAtmosphere, setDishesSelected]);



  return (
    <Box
      className={classes.firstStepRoot}
      sx={{
        flexDirection: {
          xs: "column !important",
          sm: "column !important",
          md: "row !important",
          lg: "row !important",
          xl: "row !important",
        },
        justifyContent: {
          xs: "unset !important",
          sm: "unset !important",
          md: "center !important",
          lg: "center !important",
          xl: "center !important",
        },
        paddingTop: {
          xs: "2rem !important",
          sm: "2rem !important",
          md: "0rem !important",
          lg: "0rem !important",
          xl: "0rem !important",
        },
        height: {
          // xs: "unset !important",
          // sm: "unset !important",
          // md: "100vh !important",
          // lg: "100vh !important",
          // xl: "100vh !important",
        },
      }}
    >
      {/* Desktop */}
      <Grid container className="main_wrapper" component={Box} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={8}
          sx={{
            // height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            paddingTop: iframe === 'stand' ? "6rem !important" : undefined,
            // alignItems: "center",
          }}
        >
          <Box className={classes.secondStepDescriptionContainer + ' coctail-bx ' + (iframe === "worldclass" ? 'world_class_second_step secondsteps' : '')}>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "68px",
              }}
            >
              <Button
                fullWidth
                className={classes.goBackButtonDesktop}
                onClick={() => setQuizStep(1)}
                sx={{ marginBottom: "1rem" }}
              >
                <Grid container sx={{ justifyContent: "space-between" }}
                  className={(iframe === "worldclass" ? 'world_class_back' : '')}
                >
                  <Grid item xs={2}>
                    <ArrowBack />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box>{titlesgoback}</Box>
                  </Grid>
                </Grid>
              </Button>
            </Box>

            <Box>
              <Typography gutterBottom className={classes.WYCTitle}>
                {title}
              </Typography>
            </Box>




            <Box sx={{ width: "515px" }}>
              <Typography gutterBottom className={classes.secondStepTitle}>
                {titlewhatatmosphere}
              </Typography>
            </Box>


            <Box className={(iframe === "worldclass" ? 'world_class_continue' : '')} sx={{ display: "flex", justifyContent: 'flex-end', paddingRight: "68px", }}>
              <Button
                fullWidth
                className={classes.continueButtonDesktop}
                onClick={() => setQuizStep(3)}
                sx={{ marginBottom: "1rem" }}
                disabled={atmosphere === ""}
              >
                <Grid container sx={{ justifyContent: "space-around" }}>
                  <Grid
                    item
                    xs={10}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "32px",
                    }}
                  >
                    <Box width={"100%"}>{titlescontinue}</Box>
                  </Grid>
                  <Grid item xs={2}>
                    <ArrowForward />
                  </Grid>
                </Grid>
              </Button>
            </Box>




            <Box
              sx={{
                width: "100%",
                marginBottom: "2rem",
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    id={
                      atmosphere === "Party"
                        ? "atmospherePartyButtonActive"
                        : "atmospherePartyButton"
                    }
                    fullWidth
                    className={classes.secondStepVibesButton}
                    onClick={() => setAtmosphere("Party")}
                    sx={{
                      textTransform: "none",
                      marginBottom: "1rem",
                      border:
                        atmosphere === "Party"
                          ? `none`
                          : "1px solid #2C2C2C !important",
                      backgroundColor:
                        atmosphere === "Party"
                          ? `${atmosphereColors.party} !important`
                          : "transparent !important",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <img
                          src={
                            atmosphere === "Party"
                              ? PartyEmptySvg
                              : PartyFullSvg
                          }
                          alt="Party"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box sx={{ marginLeft: "1rem" }}>{title_party}</Box>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={6}>

                  <Button
                    fullWidth
                    className={classes.secondStepVibesButton}
                    onClick={() => setAtmosphere("Sophisticated")}
                    id={
                      atmosphere === "Sophisticated"
                        ? "atmosphereSophisticatedButtonActive"
                        : "atmosphereSophisticatedButton"
                    }
                    sx={{
                      textTransform: "none",
                      marginBottom: "1rem",
                      border:
                        atmosphere === "Sophisticated"
                          ? `none`
                          : "1px solid #2C2C2C !important",
                      backgroundColor:
                        atmosphere === "Sophisticated"
                          ? `${atmosphereColors.sophisticated} !important`
                          : "transparent !important",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <img
                          src={
                            atmosphere === "Sophisticated" ||
                              sophisticatedHovered
                              ? SophisticatedEmptySvg
                              : SophisticatedFullSvg
                          }
                          alt="Sophisticated"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box
                          sx={{
                            marginLeft: "1rem",
                            color:
                              atmosphere === "Sophisticated"
                                ? `#F7F7F7 !important`
                                : "",
                          }}
                        >
                          {titlesophisticated}
                        </Box>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    className={classes.secondStepVibesButton}
                    onClick={() => setAtmosphere("Casual")}
                    id={
                      atmosphere === "Casual"
                        ? "atmosphereCasualButtonActive"
                        : "atmosphereCasualButton"
                    }
                    sx={{
                      textTransform: "none",
                      marginBottom: "1rem",
                      border:
                        atmosphere === "Casual"
                          ? `none`
                          : "1px solid #2C2C2C !important",
                      backgroundColor:
                        atmosphere === "Casual"
                          ? `${atmosphereColors.casual} !important`
                          : "transparent !important",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <img
                          src={
                            atmosphere === "Casual"
                              ? CasualEmptySvg
                              : CasualFullSvg
                          }
                          alt="Casual"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box sx={{ marginLeft: "1rem" }}>
                          {titlescasual}
                        </Box>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    id={
                      atmosphere === "Familiar"
                        ? "atmosphereFamiliarButtonActive"
                        : "atmosphereFamiliarButton"
                    }
                    fullWidth
                    className={classes.secondStepVibesButton}
                    onClick={() => setAtmosphere("Familiar")}
                    sx={{
                      textTransform: "none",
                      marginBottom: "1rem",
                      border:
                        atmosphere === "Familiar"
                          ? `none`
                          : "1px solid #2C2C2C !important",
                      backgroundColor:
                        atmosphere === "Familiar"
                          ? `${atmosphereColors.familiar} !important`
                          : "transparent !important",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <img
                          src={
                            atmosphere === "Familiar"
                              ? FamiliarEmptySvg
                              : FamiliarFullSvg
                          }
                          alt="Familiar"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box sx={{ marginLeft: "1rem" }}>{title_unwind}</Box>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className={classes.containerImage + ' step2ImageContainer'}>
            <img src={Cover} alt="main" className={classes.coverImage + ' step2Image'} />
          </Box>
        </Grid>
      </Grid>
      {/* Mobile */}
      <Grid container component={Box} display={{ xs: "block", md: "none" }}>
        <Grid
          item
          xs={12}
          sx={{
            // height: "100vh",
            overflow: "hidden",
          }}
        >
          <Box
            className={classes.secondStepDescriptionContainer}
            sx={{
              width: "100% !important",
              margin: "0 !important",
              padding: "25px !important",
              marginBottom: "0",
              textAlign: "left !important",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >

            <Box
              sx={{
                width: "100%",
                display: "flex",
              }}
            >
              <IconButton
                className={classes.goBackButtonMobile}
                onClick={() => setQuizStep(1)}
                sx={{ marginBottom: "1rem" }}
              >
                <ArrowBack /> {titlesgoback}
              </IconButton>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography className={classes.WYCTitle}>
                {title}
              </Typography>
            </Box>

            <Box sx={{ marginBottom: "1rem", width: "100%" }}>
              <Typography
                gutterBottom
                className={classes.secondStepTitleMobile}
              >
                {titlewhatatmosphere}
              </Typography>
            </Box>
            {iframe !== "stand" && (

              <Button
                fullWidth
                className={classes.continueButtonDesktop}
                onClick={() => setQuizStep(3)}
                sx={{ marginBottom: "2rem" }}
                disabled={atmosphere === ""}
              >
                <Grid container sx={{ justifyContent: "space-around" }}>
                  <Grid
                    item
                    xs={10}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "32px",
                    }}
                  >
                    <Box width={"100%"}>{titlescontinue}</Box>
                  </Grid>
                  <Grid item xs={2}>
                    <ArrowForward />
                  </Grid>
                </Grid>
              </Button>)}
            <Box
              sx={{
                width: "90%",
                marginBottom: "0rem",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    className={classes.secondStepVibesButtonMobile}
                    onClick={() => setAtmosphere("Party")}
                    sx={{
                      textTransform: "none",
                      marginBottom: "1rem",
                      border:
                        atmosphere === "Party"
                          ? `none`
                          : "1px solid #2C2C2C !important",
                      backgroundColor:
                        atmosphere === "Party"
                          ? `${atmosphereColors.party} !important`
                          : "transparent !important",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <img
                          src={
                            atmosphere === "Party"
                              ? PartyEmptySvg
                              : PartyFullSvg
                          }
                          alt="Party"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box sx={{ marginLeft: "1rem" }}>{title_party}</Box>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    className={classes.secondStepVibesButtonMobile}
                    onClick={() => setAtmosphere("Sophisticated")}
                    sx={{
                      textTransform: "none",
                      marginBottom: "1rem",
                      border:
                        atmosphere === "Sophisticated"
                          ? `none`
                          : "1px solid #2C2C2C !important",
                      backgroundColor:
                        atmosphere === "Sophisticated"
                          ? `${atmosphereColors.sophisticated} !important`
                          : "transparent !important",
                      color:
                        atmosphere === "Sophisticated"
                          ? `#F7F7F7 !important`
                          : "#2C2C2C !important",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <img
                          src={
                            atmosphere === "Sophisticated"
                              ? SophisticatedEmptySvg
                              : SophisticatedFullSvg
                          }
                          alt="Sophisticated"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box
                          sx={{
                            marginLeft: "1rem",
                            color:
                              atmosphere === "Sophisticated"
                                ? `#F7F7F7 !important`
                                : "#2C2C2C !important",
                          }}
                        >
                          {titlesophisticated}
                        </Box>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    className={classes.secondStepVibesButtonMobile}
                    onClick={() => setAtmosphere("Casual")}
                    sx={{
                      textTransform: "none",
                      marginBottom: "1rem",
                      border:
                        atmosphere === "Casual"
                          ? `none`
                          : "1px solid #2C2C2C !important",
                      backgroundColor:
                        atmosphere === "Casual"
                          ? `${atmosphereColors.casual} !important`
                          : "transparent !important",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <img
                          src={
                            atmosphere === "Casual"
                              ? CasualEmptySvg
                              : CasualFullSvg
                          }
                          alt="Casual"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box sx={{ marginLeft: "1rem" }}>
                          {titlescasual}
                        </Box>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    className={classes.secondStepVibesButtonMobile}
                    onClick={() => setAtmosphere("Familiar")}
                    sx={{
                      textTransform: "none",
                      width: "100% !important",
                      marginBottom: "1rem",
                      border:
                        atmosphere === "Familiar"
                          ? `none`
                          : "1px solid #2C2C2C !important",
                      backgroundColor:
                        atmosphere === "Familiar"
                          ? `${atmosphereColors.familiar} !important`
                          : "transparent !important",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <img
                          src={
                            atmosphere === "Familiar"
                              ? FamiliarEmptySvg
                              : FamiliarFullSvg
                          }
                          alt="Familiar"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box sx={{ marginLeft: "1rem" }}>{title_unwind}</Box>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {iframe !== "iframe" && (
                <Button
                  fullWidth
                  className={classes.continueButtonDesktop}
                  onClick={() => setQuizStep(3)}
                  sx={{ marginBottom: "1rem" }}
                  disabled={atmosphere === ""}
                >
                  <Grid container sx={{ justifyContent: "space-around" }}>
                    <Grid
                      item
                      xs={10}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "32px",
                      }}
                    >
                      <Box width={"100%"}>{titlescontinue}</Box>
                    </Grid>
                    <Grid item xs={2}>
                      <ArrowForward />
                    </Grid>
                  </Grid>
                </Button>)}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuizStep2;
