import React from "react";
import { styled } from "@mui/material/styles";
import Tab, { tabClasses } from "@mui/material/Tab";
import Tabs, { tabsClasses, TabsProps } from "@mui/material/Tabs";
import { Box } from "@mui/material";

const TabItem = styled(Tab)(({ theme }) => ({
  opacity: 1,
  overflow: "initial",
  padding: "2px 2px !important",
  lineHeight: "2px !important",  // Explicitly set the height
  borderTopLeftRadius: "8px",
  minWidth: "80px !important",
  borderTopRightRadius: "8px",
  fontFamily: "HelveticaNowDisplay-Regular !important",
  fontSize: "14px",
  fontWeight: "500",
  color: "#2C2C2C",
  backgroundColor: "#FCA43E",
  transition: "0.2s",
  zIndex: 2,
  marginTop: theme.spacing(0.1),
  textTransform: "initial",
  minHeight: "31px !important",
  marginRight: "10px !important",
  "&:before": {
    transition: "0.2s",
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: "#FCD7A4",
    color: "#2C2C2C",
  },
  [`&.${tabClasses.selected} + .${tabClasses.root}`]: {
    zIndex: 1,
  },
  [`&.${tabClasses.selected} + .${tabClasses.root}::before`]: {
    opacity: 0,
  },
}));

export interface CustomTabsDefinition {
  tabs: { label: string; content: string }[];
  mobile?: boolean;
}

export function TasteProfileTabs({
  sx,
  tabs,
  mobile = false,
}: TabsProps & CustomTabsDefinition) {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <>
      <Tabs
        className="aaa"
        value={tabIndex}
        onChange={(e, index) => setTabIndex(index)}
        sx={{
          [`& .${tabsClasses.indicator}`]: {
            display: "none",
          },
          marginBottom: "0px !important",
          minHeight: "31px !important"
        }}
      >
        {tabs.map((tab, index) => (
          <TabItem
            key={`result-tab-${index}`}
            label={tab.label}
            sx={{
              ...sx,
              marginRight: mobile ? "5px" : "10px",
              fontFamily: "HelveticaNowDisplay-Regular !important",


            }}
          />
        ))}
      </Tabs>
      <Box
        sx={{
          padding: "14px 12px",
          backgroundColor: "#FCD7A4",
          color: "#2C2C2C",
          borderRadius: "8px",
          borderTopLeftRadius: "0px",
          width: mobile ? "334px" : "560px",
          fontFamily: "HelveticaNowDisplay-Regular !important",
          marginTop: "0px !important"

        }}
      >
        {tabs[tabIndex].content}
      </Box>
    </>
  );
}
