import React, { useEffect, useState, useMemo } from "react";
import { Box, useMediaQuery } from "@mui/system";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import ChooseMinimum3 from "../../../assets/quiz/choose3.svg";
import ChooseMinimum3ES from "../../../assets/quiz/choose3_ES.svg";
import dishesJSON_EN from "../../../assets/quiz/dishes.json";
import dishesJSON_ES from "../../../assets/quiz/dishes_ES_ES.json";
import { useStyles } from "../styles";
import { QuizStepProps } from "..";
import { getS3FileUrl } from "../../../utils/S3Manager";
import { BeatLoader } from "react-spinners";
import assets from "../../../assets";

const atmosphereColors = {
  casual: "#695a94",
  familiar: "#FCA43E",
  sophisticated: "#943434",
  party: "#94AB6E",
};

const QuizStep3 = ({
  setQuizStep,
  lang = "EN_UK",
  dishesSelected = [],
  setDishesSelected = () => { },
  atmosphere = "",
  iframe = "stand"
}: QuizStepProps) => {
  const classes = useStyles();
  const [dishes, setDishes] = useState<{ name: string; imageUrl: string }[]>(
    []
  );
  const [dishesMobileContainerId, setDishesMobileContainerId] = useState(
    "dishes-container-mobile"
  );
  const isLargeScreen = useMediaQuery('(min-width:1200px)');
  const is768px = useMediaQuery('(max-width:768px)');
  const between768pxto1050px = useMediaQuery('(min-width:768px) and (max-width:1050px)');
  const isAbove1050px = useMediaQuery('(min-width:1050px)');


  const [smallBoxWidth, setSmallBoxWidth] = useState('clamp(136px, 36.2666666667vw, 204px)');
  const [smallBoxHeight, setSmallBoxHeight] = useState('clamp(136px, 36.2666666667vw, 204px)');
  const [largeBoxWidth, setLargeBoxWidth] = useState('clamp(174px, 46.4vw, 261px)');
  const [largeBoxHeight, setLargeBoxHeight] = useState('clamp(174px, 46.4vw, 261px)');
  const [imageHeight, setImageHeight] = useState("85%");



  useEffect(() => {
    if (isLargeScreen) {
      setSmallBoxWidth('10.5555555556vw');
      setSmallBoxHeight('10.5555555556vw');
      setLargeBoxWidth("13.6111111111vw");
      setLargeBoxHeight("13.6111111111vw");
    } else {
      setSmallBoxWidth('clamp(136px, 36.2666666667vw, 204px)');
      setSmallBoxHeight('clamp(136px, 36.2666666667vw, 204px)');
      setLargeBoxWidth('clamp(174px, 46.4vw, 261px)');
      setLargeBoxHeight('clamp(174px, 46.4vw, 261px)');
    }
  }, [isLargeScreen])

  useEffect(() => {
    if (is768px) {
      setImageHeight("85%")
    } else if (between768pxto1050px) {
      setImageHeight("50%")

    } else if (isAbove1050px) {
      setImageHeight("30%")

    } else {
      setImageHeight("30%")
    }

  }, [is768px, isAbove1050px, between768pxto1050px])


  const dishes_mapping = useMemo(
    () => ({
      EN_UK: dishesJSON_EN,
      EN_US: dishesJSON_EN,
      EN_ROW: dishesJSON_EN,
      ES: dishesJSON_ES,
      ES_ROW: dishesJSON_ES,
      ES_US: dishesJSON_ES,
      ES_ES: dishesJSON_ES,
      ES_MX: dishesJSON_ES,
    }),
    []
  );

  const dishesJSON = dishes_mapping[lang] || dishesJSON_EN;

  const whatsyourcocktailtitle = useMemo(
    () => ({
      EN_UK: "What's your cocktail?",
      EN_US: "What's your cocktail?",
      EN_ROW: "What's your cocktail?",
      ES: "¿CUÁL ES TU CÓCTEL?",
      ES_ROW: "¿CUÁL ES TU CÓCTEL?",
      ES_US: "¿CUÁL ES TU CÓCTEL?",
      ES_ES: "¿CUÁL ES TU CÓCTEL?",
      ES_MX: "¿CUÁL ES TU CÓCTEL?",
    }),
    []
  );

  const dishquestiontitle = useMemo(
    () => ({
      EN_UK: "Which of these foods match your favourite flavours?",
      EN_US: "Which of these foods match your favorite flavors?",
      EN_ROW: "Which of these foods match your favorite flavors?",
      ES: "¿Cuál de estos platos tienen los sabores que más te gustan?",
      ES_ROW: "¿Cuál de estos platos tienen los sabores que más te gustan?",
      ES_US: "¿Cuál de estos platos tienen los sabores que más te gustan?",
      ES_ES: "¿Cuál de estos platos tienen los sabores que más te gustan?",
      ES_MX: "¿Cuál de estos platos tienen los sabores que más te gustan?",
    }),
    []
  );

  const gobacktitle = useMemo(
    () => ({
      EN_UK: "BACK",
      EN_US: "BACK",
      EN_ROW: "BACK",
      ES: "ATRÁS",
      ES_ROW: "ATRÁS",
      ES_US: "ATRÁS",
      ES_ES: "ATRÁS",
      ES_MX: "ATRÁS",
    }),
    []
  );

  const continuetitle = useMemo(
    () => ({
      EN_UK: "CONTINUE",
      EN_US: "CONTINUE",
      EN_ROW: "CONTINUE",
      ES: "CONTINUAR",
      ES_ROW: "CONTINUAR",
      ES_US: "CONTINUAR",
      ES_ES: "CONTINUAR",
      ES_MX: "CONTINUAR",
    }),
    []
  );

  const choose3title = useMemo(
    () => ({
      EN_UK: ChooseMinimum3,
      EN_US: ChooseMinimum3,
      EN_ROW: ChooseMinimum3,
      ES: ChooseMinimum3ES,
      ES_ROW: ChooseMinimum3ES,
      ES_US: ChooseMinimum3ES,
      ES_ES: ChooseMinimum3ES,
      ES_MX: ChooseMinimum3ES,
    }),
    []
  );



  const title = whatsyourcocktailtitle[lang] || "What's your cocktail?";
  const title_dish_question = dishquestiontitle[lang] || "Which of these foods match your favourite flavours?";
  const titlesgoback = gobacktitle[lang] || "GO BACK";
  const titlescontinue = continuetitle[lang] || "Continue";
  const titlechoose = choose3title[lang] || ChooseMinimum3;

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "custom_event",
      event_name: "quiz_step",
      step: "question 2",
    });
  }, []);

  useEffect(() => {
    const getDishesWithImages = () => {
      const selectedDishes = assets.dishes[atmosphere.toLowerCase() as keyof typeof assets.dishes];

      const dishesArray = selectedDishes.map((dish) => {
        if (['ES', 'ES_ES', 'ES_MX', 'ES_US', 'ES_ROW'].includes(lang)) {
          return {
            name: dish.name_es,
            imageUrl: dish.imageUrl
          };
        } else {
          return {
            name: dish.name,
            imageUrl: dish.imageUrl
          };
        }
      });

      // Now you can use dishesArray as needed
      console.log(dishesArray);
      setDishes(dishesArray);
    };

    getDishesWithImages();
  }, [atmosphere, lang]);

  useEffect(() => {
    setDishesSelected([]);
  }, [setDishesSelected]);

  const toggleDish = (dish: string) => {
    if (dishesSelected.includes(dish)) {
      setDishesSelected(dishesSelected.filter((d) => d !== dish));
    } else {
      setDishesSelected([...dishesSelected, dish]);
    }
  };

  const getDishBackground = (dish: string, imageUrl: string) =>
    dishesSelected.includes(dish)
      ? `linear-gradient(0deg, ${atmosphereColors[
      atmosphere.toLowerCase() as keyof typeof atmosphereColors
      ]
      }, ${atmosphereColors[
      atmosphere.toLowerCase() as keyof typeof atmosphereColors
      ]
      }), ${imageUrl}`
      : `${imageUrl} rgba(0,0,0,0.5)`;

  const getDishBackgroundMobile = (dish: string) =>
    dishesSelected.includes(dish)
      ? `${atmosphereColors[
      atmosphere.toLowerCase() as keyof typeof atmosphereColors
      ]
      }BF`
      : "rgba(0,0,0,0.5)";




  return (
    <Box className={`${classes.thirdStepRoot} main_quize_step_wrp`}
   
      sx={{
        padding: {
          xs: "0rem !important",
          sm: "0rem !important",
          md: "0rem 2rem 0rem 4rem !important",
          lg: "0rem 2rem 0rem 4rem !important",
          xl: "0rem 2rem 0rem 4rem !important",
        },
        flexDirection: {
          xs: "column !important",
          sm: "column !important",
          md: "row !important",
          lg: "row !important",
          xl: "row !important",
        },
        justifyContent: {
          xs: "unset !important",
          sm: "unset !important",
          md: "center !important",
          lg: "center !important",
          xl: "center !important",
        },
      }}
    >
      <Grid container component={Box} display={{ xs: "block", md: "flex" }}>
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Box
            className={classes.secondStepDescriptionContainer}
            sx={{
              width: "100% !important",
              margin: "0 !important",
              padding: "25px !important",
            }}
          >

            {(iframe !== "worldclass" &&
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  className={classes.goBackButtonMobile}
                  onClick={() => setQuizStep(2)}
                  sx={{ marginBottom: "1rem" }}
                >
                  <ArrowBack />{titlesgoback}
                </IconButton>
              </Box>
            )}

            <Box>
              <Typography className={classes.WYCTitle}>
                {title}
              </Typography>
            </Box>

            <Box>
              <Typography className={classes.secondStepTitleMobile}>
                {title_dish_question}
              </Typography>
            </Box>

            {iframe !== "worldclass" && (
              <Button
                fullWidth
                className={classes.continueButtonDesktop}
                onClick={() => setQuizStep(4)}
                sx={{ marginBottom: "2.5rem", marginTop: "2.5rem" }}
                disabled={!dishesSelected || dishesSelected.length < 3}
              >
                <Grid container sx={{ justifyContent: "space-around" }}>
                  <Grid
                    item
                    xs={10}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "32px",
                    }}
                  >
                    <Box width={"100%"}>{titlescontinue}</Box>
                  </Grid>
                  <Grid item xs={2}>
                    <ArrowForward />
                  </Grid>
                </Grid>
              </Button>)}

            <Box>
              {lang === 'ES' || lang === 'ES_ES' || lang === 'ES_MX' || lang === 'ES_US'
                || lang === 'ES_ROW' ? (
                <img
                  src={titlechoose}
                  alt="choose minimum 3!"
                  style={{ width: imageHeight, marginLeft: "0" }}
                />
              ) : (
                <img
                  src={titlechoose}
                  alt="choose minimum 3"
                  style={{ width: imageHeight, marginLeft: "0" }}
                />
              )}
            </Box>
            {dishes.length === 0 && (
              <Box
                sx={{
                  height: "50vh",
                  marginBottom: "4rem",
                  padding: "2rem",
                  paddingBottom: "0",
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BeatLoader color="black" />
              </Box>
            )}
            {dishes.length > 0 && (
              <Box
                id={dishesMobileContainerId}
                onTouchStart={() =>
                  setDishesMobileContainerId("dishes-container-mobile-touched")
                }
                onTouchEnd={() =>
                  setDishesMobileContainerId("dishes-container-mobile")
                }
                sx={{
                  marginBottom: "4rem",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                    xl: "100%",
                  },
                  overflowX: "scroll",
                  padding: "2rem 0",
                  paddingRight: !isLargeScreen && "100px",
                  paddingBottom: "0",
                  display: "flex",
                  gap: isLargeScreen ? "2rem" : "0.5rem",
                  justifyContent: isLargeScreen && "center"
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[0].name)}
                  >
                    <img
                      src={`${dishes[0].imageUrl}`}
                      alt={dishes[0].name}
                      loading="lazy"
                      style={{
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                        borderRadius: "200px",
                        marginTop: "-1.5rem",
                        marginLeft: "0rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[0].name
                        ),
                        top: "-1.5rem",
                        left: 0,
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[0].name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[1].name)}
                  >
                    <img
                      src={`${dishes[1].imageUrl}`}
                      alt={dishes[1].name}
                      loading="lazy"
                      style={{
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                        borderRadius: "200px",
                        marginTop: "3rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[1].name
                        ),
                        top: "calc(3rem)",
                        left: 0,
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[1].name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[2].name)}
                  >
                    <img
                      src={`${dishes[2].imageUrl}`}
                      alt={dishes[2].name}
                      loading="lazy"
                      style={{
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                        borderRadius: "200px",
                        marginTop: "-2rem",
                        marginLeft: "0rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[2].name
                        ),
                        top: "-2rem",
                        left: "0rem",
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[2].name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[3].name)}
                  >
                    <img
                      src={`${dishes[3].imageUrl}`}
                      alt={dishes[3].name}
                      loading="lazy"
                      style={{
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                        borderRadius: "200px",
                        marginTop: "0.5rem",
                        marginLeft: "-3rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[3].name
                        ),
                        top: "0.5rem",
                        left: "-3rem",
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[3].name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[4].name)}
                  >
                    <img
                      src={`${dishes[4].imageUrl}`}
                      alt={dishes[4].name}
                      loading="lazy"
                      style={{
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                        borderRadius: "200px",
                        marginTop: "2rem",
                        marginLeft: "-1rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[4].name
                        ),
                        top: "2rem",
                        left: "-1rem",
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[4].name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[5].name)}
                  >
                    <img
                      src={`${dishes[5].imageUrl}`}
                      alt={dishes[5].name}
                      loading="lazy"
                      style={{
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                        borderRadius: "200px",
                        marginTop: "1rem",
                        marginLeft: "0",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[5].name
                        ),
                        top: "1rem",
                        left: "0",
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[5].name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[6].name)}
                  >
                    <img
                      src={`${dishes[6].imageUrl}`}
                      alt={dishes[6].name}
                      loading="lazy"
                      style={{
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                        borderRadius: "200px",
                        marginTop: "-1rem",
                        marginLeft: "-1rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[6].name
                        ),
                        top: "-1rem",
                        left: "-1rem",
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[6].name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[7].name)}
                  >
                    <img
                      src={`${dishes[7].imageUrl}`}
                      alt={dishes[7].name}
                      loading="lazy"
                      style={{
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                        borderRadius: "200px",
                        marginTop: "2rem",
                        marginLeft: "-2rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[7].name
                        ),
                        top: "2rem",
                        left: "-2rem",
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[7].name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[8].name)}
                  >
                    <img
                      src={`${dishes[8].imageUrl}`}
                      alt={dishes[8].name}
                      loading="lazy"
                      style={{
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                        borderRadius: "200px",
                        marginTop: "2rem",
                        marginLeft: "-2rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[8].name
                        ),
                        top: "2rem",
                        left: "-2rem",
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[8].name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[9].name)}
                  >
                    <img
                      src={`${dishes[9].imageUrl}`}
                      alt={dishes[9].name}
                      loading="lazy"
                      style={{
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                        borderRadius: "200px",
                        marginTop: "1rem",
                        marginLeft: "-0.5rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[9].name
                        ),
                        top: "1rem",
                        left: "-0.5rem",
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[9].name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[10].name)}
                  >
                    <img
                      src={`${dishes[10].imageUrl}`}
                      alt={dishes[10].name}
                      loading="lazy"
                      style={{
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                        borderRadius: "200px",
                        marginTop: "-1.5rem",
                        marginLeft: "-1rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[10].name
                        ),
                        top: "-1.5rem",
                        left: "-1rem",
                        width: smallBoxWidth,
                        height: smallBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[10].name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    position="relative"
                    onClick={() => toggleDish(dishes[11].name)}
                  >
                    <img
                      src={`${dishes[11].imageUrl}`}
                      alt={dishes[11].name}
                      loading="lazy"
                      style={{
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                        borderRadius: "200px",
                        marginTop: "1.5rem",
                        marginLeft: "-1.5rem",
                      }}
                    />
                    <Box
                      className={classes.dishesTextContainer}
                      position="absolute"
                      sx={{
                        backgroundColor: getDishBackgroundMobile(
                          dishes[11].name
                        ),
                        top: "1.5rem",
                        left: "-1.5rem",
                        width: largeBoxWidth,
                        height: largeBoxHeight,
                      }}
                    >
                      <Typography className={classes.dishesText}>
                        {dishes[11].name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}


            {(iframe === "worldclass" &&
              <div className="stepthreewrapper">
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton
                    className={classes.goBackButtonMobile}
                    onClick={() => setQuizStep(2)}
                    sx={{ marginBottom: "1rem" }}
                  >
                    <ArrowBack />{titlesgoback}
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    fullWidth
                    className={classes.continueButtonDesktop}
                    onClick={() => setQuizStep(4)}
                    sx={{ marginBottom: "1rem" }}
                    disabled={!dishesSelected || dishesSelected.length < 3}
                  >
                    <Grid container sx={{ justifyContent: "space-around" }}>
                      <Grid
                        item
                        xs={10}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "32px",
                        }}
                      >
                        <Box width={"100%"}>{titlescontinue}</Box>
                      </Grid>
                      <Grid item xs={2}>
                        <ArrowForward />
                      </Grid>
                    </Grid>
                  </Button>
                </Box>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuizStep3;
