import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useStyles } from "../../styles";
import { ReactComponent as ShoopingBagIcon } from "../../../../assets/svg/shopping_bag.svg";

interface UnbrandedECommerceCardProps {
  name: string;
  description: string;
  image: string;
  productUrl: string;
  lng: string;
}

const UnbrandedECommerceCard = ({
  name,
  description,
  image,
  productUrl,
  lng
}: UnbrandedECommerceCardProps) => {
  const classes = useStyles();

  const checkpricetitle = useMemo(
    () => ({
      EN_UK: "Check price",
      EN_US: "Check price",
      ES: "Comprar",
    }),
    []
  );


  const title_checkprice = checkpricetitle[lng] || "Check price";

  return (
    <Box
      sx={{
        margin: {
          xs: "0 0",
          sm: "0 0",
          md: "0 1rem",
          lg: "0 1rem",
          xl: "0 1rem",
        },
      }}
    >
      <Card
        className={`${classes.ecommerceCardContainer}`}
        sx={{
          width: {
            xs: "343px !important",
            sm: "343px !important",
            md: "315px !important",
            lg: "315px !important",
            xl: "315px !important",
          },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "223px",
              height: "171px",
              background: `url(${image})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}
          />
          <Box
            sx={{
              width: "100%",
              paddingTop: "1rem",
              paddingLeft: {
                xs: "2rem",
                sm: "2rem",
                md: "0rem",
                lg: "0rem",
                xl: "0rem",
              },
            }}
          >
            <Typography gutterBottom className={classes.ecommerceSpiritTitle}>
              {name}
            </Typography>
            <Typography
              gutterBottom
              className={classes.ecommerceSpiritDescription}
            >
              {description}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              marginTop: "1rem",
              paddingLeft: {
                xs: "2rem",
                sm: "2rem",
                md: "0rem",
                lg: "0rem",
                xl: "0rem",
              },
            }}
          >
            <Button
              sx={{
                width: {
                  xs: "calc(100% - 2rem)",
                  sm: "calc(100% - 2rem)",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
              }}
              className={classes.addToBagButton}
              onClick={() => window.open(productUrl, "_blank")}
            >
              <Box sx={{ marginRight: "0.3rem" }}>
                <Typography
                  sx={{
                    fontFamily: "Helvetica !important",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#F7F7F7",
                    textTransform: "none",
                  }}
                >
                  {title_checkprice}
                </Typography>
              </Box>
              <Box height="28px">
                <SvgIcon component={ShoopingBagIcon} />
              </Box>
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UnbrandedECommerceCard;
