import { LOGIN, LOGOUT, STORAGE_LOGIN } from "../constants/store-actions/auth";
import { authInitialState } from "../contexts/AuthContext";
import { AuthContextInitialState } from "../contexts/types/auth";

interface AccountReducerActionProps {
  type: string;
  payload?: AuthContextInitialState;
}

const accountReducer = (
  state = authInitialState,
  action: AccountReducerActionProps
) => {
  const { payload, type } = action;

  switch (type) {
    case LOGIN: {
      const { userEmail } = payload!;
      return {
        ...state,
        isLoggedIn: true,
        userEmail
      };
    }
    case STORAGE_LOGIN: {
      const { userEmail } = payload!;
      return {
        ...state,
        isLoggedIn: true,
        userEmail
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
