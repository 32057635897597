import React, { useRef, useState, useMemo } from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import Carousel from "react-elastic-carousel";

import { useStyles } from "../styles";
import { QuizStepProps } from "..";
import assets from "../../../assets";

const QuizStep4 = ({ setQuizStep, lang, iframe }: QuizStepProps) => {
  const classes = useStyles();
  const verticalCarouselFirstRef = useRef<any>(null);
  const verticalCarouselSecondRef = useRef<any>(null);
  const horizontalCarouselFirstRef = useRef<any>(null);
  const horizontalCarouselSecondRef = useRef<any>(null);
  const [autoPlay, setAutoPlay] = useState(true);

  const handleCarouselMouseEvent = () => {
    setAutoPlay(!autoPlay);
  };

  const whatsyourcocktailtitle = useMemo(
    () => ({
      EN_UK: "What's your cocktail?",
      EN_US: "What's your cocktail?",
      EN_ROW: "What's your cocktail?",
      ES: "¿CUÁL ES TU CÓCTEL?",
      ES_ROW: "¿CUÁL ES TU CÓCTEL?",
      ES_US: "¿CUÁL ES TU CÓCTEL?",
      ES_ES: "¿CUÁL ES TU CÓCTEL?",
      ES_MX: "¿CUÁL ES TU CÓCTEL?",
    }),
    []
  );

  const ourAItitle = useMemo(
    () => ({
      EN_UK: "Our AI is tailoring a flavour adventure just for you.",
      EN_US: "Our AI is tailoring a flavour adventure just for you.",
      EN_ROW: "Our AI is tailoring a flavour adventure just for you.",
      ES: "Nuestra IA está personalizando una experiencia de sabor única para ti",
      ES_ROW: "Nuestra IA está personalizando una experiencia de sabor única para ti",
      ES_US: "Nuestra IA está personalizando una experiencia de sabor única para ti",
      ES_ES: "Nuestra IA está personalizando una experiencia de sabor única para ti",
      ES_MX: "Nuestra IA está personalizando una experiencia de sabor única para ti",
    }),
    []
  );


  const CLOUDFRONT_URL = process.env.REACT_APP_CDN;

  const title = whatsyourcocktailtitle[lang] || "What's your cocktail?";
  const titleAI = ourAItitle[lang] || "Our AI is tailoring a flavour adventure just for you.";

  return (
    <Box
      className={classes.firstStepRoot}
      sx={{
        flexDirection: {
          xs: "column !important",
          sm: "column !important",
          md: "row !important",
          lg: "row !important",
          xl: "row !important",
        },
        justifyContent: {
          xs: "unset !important",
          sm: "unset !important",
          md: "center !important",
          lg: "center !important",
          xl: "center !important",
        },
        paddingTop: {
          xs: "2rem !important",
          sm: "2rem !important",
          md: "0rem !important",
          lg: "0rem !important",
          xl: "0rem !important",
        },
        height: "100vh !important",
      }}
    >
      {/* Desktop */}
      <Grid container component={Box} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={6}
          className="step4Container"
          sx={{
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            paddingTop: iframe === 'stand' ? "15rem !important" : undefined,
          }}
        >
          <Box className={classes.firstStepDescriptionContainer}>
            <Box>
              <Typography gutterBottom className={classes.WYCTitle}>
                {title}
              </Typography>
            </Box>

            <Box>
              <Typography gutterBottom className={classes.firstStepTitle}>
                {titleAI}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& .rec-slider-container": {
              margin: "0 !important",
            },
          }}
          onMouseEnter={handleCarouselMouseEvent}
          onMouseLeave={handleCarouselMouseEvent}
        >
          <Carousel
            ref={verticalCarouselFirstRef}
            verticalMode
            itemsToShow={4}
            enableAutoPlay={autoPlay}
            autoPlaySpeed={400}
            renderArrow={() => <></>}
            renderPagination={() => <></>}
            itemPadding={[10, 10]}
          >
            <img
              src={assets.quiz4.jpg.yellow}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
            />
            <img
              src={assets.quiz4.jpg.orange}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Orange Cocktail"
            />
            <img
              src={assets.quiz4.jpg.blue}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Blue Cocktail"
            />
            <img
              src={assets.quiz4.jpg.green}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Green Cocktail"
            />
            <img
              src={assets.quiz4.jpg.yellow}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
            />
            <img
              src={assets.quiz4.jpg.orange}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Orange Cocktail"
            />
          </Carousel>
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "240px",
            "& .rec-carousel-wrapper": {
              transform: "rotate(180deg)",
            },
            "& .rec-slider-container": {
              margin: "0 !important",
            },
          }}
          onMouseEnter={handleCarouselMouseEvent}
          onMouseLeave={handleCarouselMouseEvent}
        >
          <Carousel
            ref={verticalCarouselSecondRef}
            verticalMode
            enableAutoPlay={autoPlay}
            itemsToShow={4}
            autoPlaySpeed={400}
            renderArrow={() => <></>}
            renderPagination={() => <></>}
            itemPadding={[10, 10]}
          >
            <img
              src={assets.quiz4.jpg.loader1}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz4.jpg.loader1; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz4.jpg.loader2}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Orange Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz4.jpg.loader2; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz4.jpg.loader3}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz4.jpg.loader3; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz4.jpg.loader4}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Orange Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz4.jpg.loader4; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz4.jpg.loader5}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz4.jpg.loader5; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz4.jpg.loader6}
              style={{
                width: "304px",
                height: "228px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz4.jpg.loader6; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
          </Carousel>
        </Grid>
      </Grid>
      {/* Mobile */}
      <Grid
        container
        component={Box}
        display={{ xs: "block", md: "none" }}
        sx={{ paddingRight: "1rem" }}
      >
        <Grid item xs={12}>
          <Box
            className={classes.fourthStepDescriptionContainer}
            sx={{ padding: "0rem !important", paddingTop: "0 !important" }}
          >
            <Box>
              <Typography className={classes.WYCTitleMobile}>
                {title}
              </Typography>
            </Box>

            <Box>
              <Typography gutterBottom className={classes.firstStepTitleMobile}>
                {titleAI}
              </Typography>
            </Box>
          </Box>
          <Grid container component={Box} display={{ xs: "block", md: "none" }}>
            <Grid
              item
              xs={12}
              sx={{
                height: "231px",
                overflow: "hidden",
                "& .rec-slider-container": {
                  marginRight: "0 !important",
                },
              }}
              onMouseEnter={handleCarouselMouseEvent}
              onMouseLeave={handleCarouselMouseEvent}
            >
              <Carousel
                ref={horizontalCarouselFirstRef}
                itemsToShow={1.5}
                enableAutoPlay={autoPlay}
                autoPlaySpeed={400}
                renderArrow={() => <></>}
                renderPagination={() => <></>}
                itemPadding={[0, 0, 0, 20]}
              >
                <img
                  src={assets.quiz4.jpg.orange}
                  style={{
                    width: "276px",
                    height: "207px",
                    borderRadius: "46.16px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Orange Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.orange; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
                <img
                  src={assets.quiz4.jpg.blue}
                  style={{
                    width: "276px",
                    height: "207px",
                    borderRadius: "46.16px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Blue Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.blue; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
                <img
                  src={assets.quiz4.jpg.green}
                  style={{
                    width: "276px",
                    height: "207px",
                    borderRadius: "46.16px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Green Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.green; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
                <img
                  src={assets.quiz4.jpg.yellow}
                  style={{
                    width: "276px",
                    height: "207px",
                    borderRadius: "46.16px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Yellow Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.yellow; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
                <img
                  src={assets.quiz4.jpg.orange}
                  style={{
                    width: "276px",
                    height: "207px",
                    borderRadius: "46.16px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Orange Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.orange; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
              </Carousel>
            </Grid>
          </Grid>
          <Grid container component={Box} display={{ xs: "block", md: "none" }}>
            <Grid
              item
              xs={12}
              sx={{
                height: "231px",
                overflow: "hidden",
                "& .rec-slider-container": {
                  marginRight: "0 !important",
                },
              }}
              onMouseEnter={handleCarouselMouseEvent}
              onMouseLeave={handleCarouselMouseEvent}
            >
              <Carousel
                ref={horizontalCarouselSecondRef}
                itemsToShow={1.5}
                enableAutoPlay={autoPlay}
                autoPlaySpeed={400}
                isRTL
                renderArrow={() => <></>}
                renderPagination={() => <></>}
                itemPadding={[0, 0, 0, 20]}
              >
                <img
                  src={assets.quiz4.jpg.loaderhorizontal1}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Orange Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.loaderhorizontal1; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
                <img
                  src={assets.quiz4.jpg.loaderhorizontal2}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Blue Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.loaderhorizontal2; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
                <img
                  src={assets.quiz4.jpg.loaderhorizontal3}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Green Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.loaderhorizontal3; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
                <img
                  src={assets.quiz4.jpg.loaderhorizontal4}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Yellow Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.loaderhorizontal4; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
                <img
                  src={assets.quiz4.jpg.loaderhorizontal5}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Orange Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.loaderhorizontal5; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
                <img
                  src={assets.quiz4.jpg.loaderhorizontal6}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Orange Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.loaderhorizontal6; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
                <img
                  src={assets.quiz4.jpg.loaderhorizontal6}
                  style={{
                    width: "249px",
                    height: "207px",
                    borderRadius: "50px",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "cover",
                  }}
                  alt="Orange Cocktail"
                  onError={(e) => {
                    e.target.src = assets.quiz4.jpg.loaderhorizontal6; // Replace with your fallback image URL
                    e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
                  }}
                />
              </Carousel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuizStep4;
