/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createConsumer = /* GraphQL */ `
  mutation CreateConsumer(
    $input: CreateConsumerInput!
    $condition: ModelConsumerConditionInput
  ) {
    createConsumer(input: $input, condition: $condition) {
      id
      createdAt
      interactions {
        site
        sourceId
        interactionType
        createdAt
        country
        region
        city
        latitude
        longitude
        __typename
      }
      fallback
      updatedAt
      __typename
    }
  }
`;
export const updateConsumer = /* GraphQL */ `
  mutation UpdateConsumer(
    $input: UpdateConsumerInput!
    $condition: ModelConsumerConditionInput
  ) {
    updateConsumer(input: $input, condition: $condition) {
      id
      createdAt
      interactions {
        site
        sourceId
        interactionType
        createdAt
        country
        region
        city
        latitude
        longitude
        __typename
      }
      fallback
      updatedAt
      __typename
    }
  }
`;
export const deleteConsumer = /* GraphQL */ `
  mutation DeleteConsumer(
    $input: DeleteConsumerInput!
    $condition: ModelConsumerConditionInput
  ) {
    deleteConsumer(input: $input, condition: $condition) {
      id
      createdAt
      interactions {
        site
        sourceId
        interactionType
        createdAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const createCocktail = /* GraphQL */ `
  mutation CreateCocktail(
    $input: CreateCocktailInput!
    $condition: ModelCocktailConditionInput
  ) {
    createCocktail(input: $input, condition: $condition) {
      cocktailName
      EN_UK {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        flavours
        calories
        alcoholVol
        alc
        foodPairing
        metaData
        difficulty
        preparationTime
        created_date
        updated_date
        user
        cuisineType {
          type
          description
        }
        equipment {
          equipmentName
          quantity
          description
        }
        images {
          imageType
          imageUrl
        }
        ingredients {
          ingredient
          quantity_ml
          quantity_oz
          substitute
        }
        method {
          step
          description
        }
        occasion {
          name,
          description
        }
        seasonTags {
          name,
          description
        }
        allergens {
          name,
          description
        }
        spirit{
          name,
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        __typename
      }
      EN_US {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        flavours
        calories
        alcoholVol
        alc
        foodPairing
        metaData
        difficulty
        preparationTime
        created_date
        updated_date
        user
        cuisineType {
          type
          description
        }
        equipment {
          equipmentName
          quantity
          description
        }
        images {
          imageType
          imageUrl
        }
        ingredients {
          ingredient
          quantity_ml
          quantity_oz
          substitute
        }
        method {
          step
          description
        }
        occasion {
          name,
          description
        }
        seasonTags {
          name,
          description
        }
        allergens {
          name,
          description
        }
        spirit{
          name,
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        __typename
      }
      ES {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        flavours
        calories
        alcoholVol
        alc
        foodPairing
        metaData
        difficulty
        preparationTime
        created_date
        updated_date
        user
        cuisineType {
          type
          description
        }
        equipment {
          equipmentName
          quantity
          description
        }
        images {
          imageType
          imageUrl
        }
        ingredients {
          ingredient
          quantity_ml
          quantity_oz
          substitute
        }
        method {
          step
          description
        }
        occasion {
          name,
          description
        }
        seasonTags {
          name,
          description
        }
        allergens {
          name,
          description
        }
        spirit{
          name,
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCocktail = /* GraphQL */ `
  mutation UpdateCocktail(
    $input: UpdateCocktailInput!
    $condition: ModelCocktailConditionInput
  ) {
    updateCocktail(input: $input, condition: $condition) {
      cocktailName
      EN_UK {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        flavours
        calories
        alcoholVol
        alc
        foodPairing
        metaData
        difficulty
        preparationTime
        created_date
        updated_date
        user
        cuisineType {
          type
          description
        }
        equipment {
          equipmentName
          quantity
          description
        }
        images {
          imageType
          imageUrl
        }
        ingredients {
          ingredient
          quantity_ml
          quantity_oz
          substitute
        }
        method {
          step
          description
        }
        allergens {
          name,
          description
        }
        occasion {
          name,
          description
        }
        seasonTags {
          name,
          description
        }
        spirit{
          name,
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
      EN_US {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        flavours
        calories
        alcoholVol
        alc
        foodPairing
        metaData
        difficulty
        preparationTime
        created_date
        updated_date
        user
        allergens {
          name,
          description
        }
        cuisineType {
          type
          description
        }
        equipment {
          equipmentName
          quantity
          description
        }
        images {
          imageType
          imageUrl
        }
        ingredients {
          ingredient
          quantity_ml
          quantity_oz
          substitute
        }
        method {
          step
          description
        }
        occasion {
          name,
          description
        }
        seasonTags {
          name,
          description
        }
        spirit{
          name,
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
      ES {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        flavours
        calories
        alcoholVol
        alc
        foodPairing
        metaData
        difficulty
        preparationTime
        created_date
        updated_date
        user
        allergens {
          name,
          description
        }
        cuisineType {
          type
          description
        }
        equipment {
          equipmentName
          quantity
          description
        }
        images {
          imageType
          imageUrl
        }
        ingredients {
          ingredient
          quantity_ml
          quantity_oz
          substitute
        }
        method {
          step
          description
        }
        occasion {
          name,
          description
        }
        seasonTags {
          name,
          description
        }
        spirit{
          name,
          brand {
            brandName
            brandDescription
            brandLogo
            brandLogoLifestyle
          }
        }
        ecommerce  {
          product_title
          product_description
          product_imageUrl
          product_Url
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCocktail = /* GraphQL */ `
  mutation DeleteCocktail(
    $input: DeleteCocktailInput!
    $condition: ModelCocktailConditionInput
  ) {
    deleteCocktail(input: $input, condition: $condition) {
      cocktailName
      EN_UK {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        flavours
        calories
        alcoholVol
        alc
        foodPairing
        metaData
        difficulty
        preparationTime
        created_date
        updated_date
        user
        __typename
      }
      EN_US {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        flavours
        calories
        alcoholVol
        alc
        foodPairing
        metaData
        difficulty
        preparationTime
        created_date
        updated_date
        user
        __typename
      }
      ES {
        cocktailTitle
        cocktailDescription
        cocktailDescriptionbig
        cocktail_url
        flavours
        calories
        alcoholVol
        alc
        foodPairing
        metaData
        difficulty
        preparationTime
        created_date
        updated_date
        user
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
