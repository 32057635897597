import { Box } from "@mui/system";
import React, { useEffect, useState, useMemo } from "react";
import { v4 as uuid } from 'uuid';
import {
  QuizStep1,
  QuizStep2,
  QuizStep3,
  QuizStep4,
  QuizStep5,
  QuizStepError,
} from "./steps";
import { QuizRecommendationCardProps } from "./components/QuizRecommendationCard";
import { CustomTabsDefinition } from "./components/TasteProfileTabs";
import { GetCocktail, GetCocktailsByLanguage } from "../../graphql_queries";
import { getS3FileUrl, downloadS3File } from "../../utils/S3Manager";
import { useParams, useLocation } from 'react-router-dom';
import dishMapping from "../../assets/quiz/dishes_mapping.json";
import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";
import InactiveScreen from "./steps/InactiveScreen";
// import { Grid } from "@mui/material";
// import { useStyles } from "./styles";
// import TheBarLogo from "../../assets/thebarlogo.svg";

// Test Respo

export interface QuizStepProps {
  setQuizStep: (val: number) => void;
  atmosphere?: string;
  setAtmosphere?: (val: string) => void;
  dishesSelected?: string[];
  setDishesSelected?: (val: string[]) => void;
  cocktails?: QuizRecommendationCardProps[];
  tabsDefinition?: CustomTabsDefinition;
  errorMsg?: string;
  lang?: string;
  iframe?: string;
  cookie?: string | null;
  site?: string;
  sessionId?: string;

}

export const atmosphereColors = {
  casual: "#B4ACCB",
  familiar: "#FCA43E",
  sophisticated: "#943434",
  party: "#94AB6E",
};

export const flavorDescriptions_EN_US = {
  BERRY:
    "You enjoy experiences with vibrant, juicy notes that dance on your palate.",

  BITTER:
    "You have a deep appreciation for the sophisticated, intense notes of bitterness, offering a mature and refined taste experience.",

  BOTANICAL:
    "Your senses are gently awakened by the intricate, botanical taste that unfolds like a garden in bloom.",

  CARAMEL:
    "Your palate gravitates towards the rich, velvety layers of caramel, embodying a symphony of sweetness that's both comforting and complex.",

  CHOCOLATE:
    "Your taste buds dance at the velvety, decadent embrace of chocolate, a symphony of flavors that range from the subtly sweet to the intriguingly bitter.",

  CITRUS:
    "Your palate is invigorated by the zesty, vibrant notes of citrus, offering a refreshing and interesting taste experience.",

  COFFEE:
    "Your palate embraces the rich, complex world of coffee flavor, a tapestry of aromas and tastes that range from the subtly sweet and nutty to the profoundly bold and bitter.",

  CREAMY:
    "Your taste buds revel in the luxurious, creamy flavor that envelops your palate with its rich, velvety texture.",

  FRUITY:
    "Your senses are awakened by the vibrant, fruity flavor that bursts forth with a kaleidoscope of sweet, tangy, and sometimes tart notes.",

  HERBAL:
    "Your senses embark on a journey through a sophisticated blend of aromatic herbs, each bringing its unique character to the palate.",

  MINT: "Your senses are instantly refreshed by the crisp, invigorating essence of mint flavor, a cool breeze that sweeps across the palate.",

  NUTTY:
    "Your palate finds comfort and warmth in the rich, earthy embrace of nuts.",

  LICORICE:
    "Your senses are drawn to the bold, distinctive flavors of licorice, where sweet meets bitter in an intriguing and complex experience.",
  FERMENTED:
    "Your palate appreciates the intriguing complexity of fermented flavors, where tangy meets savory in a rich and mature taste.",

  SMOKY:
    "Your taste buds are intrigued and captivated by the enigmatic allure of  smoky flavors, a journey into the rich notes evoke a sense of warmth.",

  SOUR: "Your senses are awakened and enlivened by a zesty spark, an invigorating splash of acidity that cuts through the mundane.",

  HOT: "Your taste adventure is ignited by the fiery intensity of hot flavors, a thrilling heat that dances across your palate, challenging and exciting your senses.",

  SWEET:
    "Your senses delight in the comforting embrace of sweet, a harmonious melody of sugary notes that sings of indulgence and joy.",

  TOASTED:
    "Your palate appreciates these cozy, comforting notes, where the golden-brown hues of roasted foods weave a tale of warmth and richness.",

  TROPICAL:
    "Your taste buds embark on an exotic journey, a lush, vibrant explosion of sweetness and acidity that transports you to sun-drenched shores.",

  VANILLA:
    "Your senses are soothed and enchanted by the creamy, aromatic complexity of vanilla, a subtle yet profound presence that elevates every creation it touches.",
  SAVORY:
    "Your taste buds are drawn to the robust and hearty qualities of savory flavors, which combine depth and richness for an amazing taste experience.",

  SPICE:
    "Your palate is captivated by the vibrant, aromatic world of spicy, a rich tapestry of tastes that spans from the warmly sweet to the exhilarating sharp.",

  WOODY:
    "Your taste journey is grounded in the rich, woody nuances, an ode to the sturdy, resinous notes of oak and aged wood.",
};
export const flavorDescriptions_EN_UK = {
  BERRY:
    "You enjoy experiences with vibrant, juicy notes that dance on your palate.",

  BITTER:
    "You have a deep appreciation for the sophisticated, intense notes of bitterness, offering a mature and refined taste experience.",

  BOTANICAL:
    "Your senses are gently awakened by the intricate, botanical taste that unfolds like a garden in bloom.",

  CARAMEL:
    "Your palate gravitates towards the rich, velvety layers of caramel, embodying a symphony of sweetness that's both comforting and complex.",

  CHOCOLATE:
    "Your taste buds dance at the velvety, decadent embrace of chocolate, a symphony of flavours that range from the subtly sweet to the intriguingly bitter.",

  CITRUS:
    "Your palate is invigorated by the zesty, vibrant notes of citrus, offering a refreshing and interesting taste experience.",

  COFFEE:
    "Your palate embraces the rich, complex world of coffee flavour, a tapestry of aromas and tastes that range from the subtly sweet and nutty to the profoundly bold and bitter.",

  CREAMY:
    "Your taste buds revel in the luxurious, creamy flavour that envelops your palate with its rich, velvety texture.",

  FRUITY:
    "Your senses are awakened by the vibrant, fruity flavour that bursts forth with a kaleidoscope of sweet, tangy, and sometimes tart notes.",

  HERBAL:
    "Your senses embark on a journey through a sophisticated blend of aromatic herbs, each bringing its unique character to the palate.",

  MINT: "Your senses are instantly refreshed by the crisp, invigorating essence of mint flavour, a cool breeze that sweeps across the palate.",

  NUTTY:
    "Your palate finds comfort and warmth in the rich, earthy embrace of nuts.",

  LICORICE:
    "Your senses are drawn to the bold, distinctive flavours of licorice, where sweet meets bitter in an intriguing and complex experience.",
  FERMENTED:
    "Your palate appreciates the intriguing complexity of fermented flavours, where tangy meets savory in a rich and mature taste.",

  SMOKY:
    "Your taste buds are intrigued and captivated by the enigmatic allure of  smoky flavours, a journey into the rich notes evoke a sense of warmth.",

  SOUR: "Your senses are awakened and enlivened by a zesty spark, an invigorating splash of acidity that cuts through the mundane.",

  HOT: "Your taste adventure is ignited by the fiery intensity of hot flavours, a thrilling heat that dances across your palate, challenging and exciting your senses.",

  SWEET:
    "Your senses delight in the comforting embrace of sweet, a harmonious melody of sugary notes that sings of indulgence and joy.",

  TOASTED:
    "Your palate appreciates these cozy, comforting notes, where the golden-brown hues of roasted foods weave a tale of warmth and richness.",

  TROPICAL:
    "Your taste buds embark on an exotic journey, a lush, vibrant explosion of sweetness and acidity that transports you to sun-drenched shores.",

  VANILLA:
    "Your senses are soothed and enchanted by the creamy, aromatic complexity of vanilla, a subtle yet profound presence that elevates every creation it touches.",
  SAVORY:
    "Your taste buds are drawn to the robust and hearty qualities of savory flavours, which combine depth and richness for an amazing taste experience.",

  SPICE:
    "Your palate is captivated by the vibrant, aromatic world of spicy, a rich tapestry of tastes that spans from the warmly sweet to the exhilarating sharp.",

  WOODY:
    "Your taste journey is grounded in the rich, woody nuances, an ode to the sturdy, resinous notes of oak and aged wood.",
};

export const flavorDescriptions_ES = {
  Bayas: "Disfrutas experiencias gastronómicas con toques vibrantes y jugosos.",
  Amargo: "Aprecias los sofisticados e intensos toques amargos, que ofrecen una experiencia de sabor madura y refinada.",
  Botánico: "Tus sentidos se despiertan con el sabor botánico que se desarrolla como un jardín que florece.",
  Caramelo: "Tus gustos se inclinan por las ricas y aterciopeladas capas de caramelo, una sinfonía de dulzura que es reconfortante y compleja al mismo tiempo.",
  Chocolate: "Sus papilas gustativas bailan con el abrazo aterciopelado y exquisito del chocolate, una sinfonía de sabores que varían desde lo sutilmente dulce hasta lo intrigantemente amargo.",
  Cítrico: "Tu paladar disfruta con las notas ácidas y vibrantes de los cítricos, que ofrecen una experiencia de sabor refrescantemente inigualable.",
  Café: "Tu paladar abraza el rico y complejo mundo del sabor del café, un tapiz de aromas y sabores que van desde lo sutilmente dulce y almendrado hasta lo profundamente audaz y amargo.",
  Cremoso: "Tus papilas gustativas disfrutan con el lujoso y cremoso sabor que envuelve tu paladar con su textura rica y aterciopelada.",
  Afrutado: "Tus sentidos se despiertan con el vibrante sabor afrutado que irrumpe con un caleidoscopio de notas dulces, ligeramente ácidas y a veces astringentes.",
  Herbal: "Tus sentidos se embarcan en un viaje a través de una sofisticada mezcla de hierbas aromáticas, cada una de las cuales aporta su carácter único al paladar.",
  Menta: "Tus sentidos se refrescan al instante con la esencia revitalizante del sabor a menta, una brisa fresca que barre el paladar.",
  Frutos_Secos: "Tu paladar siente satisfacción y calidez en el rico y terroso abrazo de los frutos secos.",
  Regaliz: "Tus sentidos se sienten atraídos por los sabores atrevidos y distintivos del regaliz, donde lo dulce se encuentra con lo amargo en una experiencia intrigante y compleja.",
  Fermentado: "Tu paladar valora la intrigante complejidad de los sabores fermentados, donde lo ligeramente ácido se encuentra con lo salado en un sabor rico y maduro.",
  Ahumado: "Tus papilas gustativas quedan intrigadas y cautivadas por el enigmático encanto de los sabores ahumados, un viaje a las ricas notas evoca una sensación de calidez.",
  Agrio: "Tus sentidos se despiertan y animan con una chispa refrescante y renovadora de acidez desafiante.",
  Picante: "Tu aventura gustativa se ensalza con la intensidad de los sabores picantes, un calor emocionante en tu paladar, desafiando y despertando tus sentidos.",
  Dulce: "Tus sentidos se deleitan con el reconfortante abrazo de lo dulce, una melodía armoniosa de notas azucaradas que canta de alegría.",
  Tostado: "Tu paladar aprecia esas notas acogedoras y reconfortantes, donde los tonos dorados de los alimentos asados tejen una historia de calidez y riqueza.",
  Tropical: "Tus papilas gustativas se embarcan en un viaje exótico, una exuberante y vibrante explosión de dulzura y acidez que te transporta a costas bañadas por el sol.",
  Vainilla: "Tus sentidos se calman y encantan con la complejidad cremosa y aromática de la vainilla, una presencia sutil pero profunda que eleva cada creación que toca.",
  Especiado: "Tu paladar queda cautivado por el vibrante y aromático mundo de lo especiado, un rico tapiz de sabores que abarca desde lo dulcemente cálido hasta lo emocionantemente agudo.",
  Amaderado: "Tu viaje gustativo se basa en los ricos matices amaderados, una oda a las notas resistentes y resinosas del roble y la madera envejecida.",
  Salado: "Tus papilas gustativas se sienten atraídas por las cualidades robustas y abundantes de los sabores salados, que combinan profundidad y riqueza para una experiencia de sabor increíble."
};


const flavour_mapping = {
  Berry: "Bayas",
  Bitter: "Amargo",
  Botanical: "Botánico",
  Caramel: "Caramelo",
  Chocolate: "Chocolate",
  Citrus: "Cítrico",
  Coffee: "Café",
  Creamy: "Cremoso",
  Fruity: "Afrutado",
  Herbal: "Herbal",
  Mint: "Menta",
  Nutty: "Frutos Secos",
  Smoky: "Ahumado",
  Sour: "Agrio",
  Hot: "Picante",
  Sweet: "Dulce",
  Toasted: "Tostado",
  Tropical: "Tropical",
  Vanilla: "Vainilla",
  Spice: "Especiado",
  Woody: "Amaderado",
  Licorice: "Regaliz",
  Fermented: "Fermentado",
  Savory: "Salado"
};

function getFlavorDescription(flavor, lng) {
  if (lng === 'EN_UK' || lng === 'EN_US' || lng === 'EN_ROW' || lng === 'EN_GB') {
    const flavorDescriptions = lng === 'EN_UK' ? flavorDescriptions_EN_UK : flavorDescriptions_EN_US;
    return flavorDescriptions[flavor.toUpperCase()];
  } else if (lng === 'ES' || lng === 'ES_ES' || lng === 'ES_MX' || lng === 'ES_US'
    || lng === 'ES_ROW') {
    const spanishKey = flavour_mapping[flavor];
    return flavorDescriptions_ES[spanishKey];
  }
  return '';
}

function getFlavorKey(flavor, lng) {
  if (lng === 'EN_UK' || lng === 'EN_US' || lng === 'EN_ROW' || lng === 'EN_GB') {
    return flavor
  } else if (lng === 'ES' || lng === 'ES_ES' || lng === 'ES_MX' || lng === 'ES_US'
    || lng === 'ES_ROW') {
    const spanishKey = flavour_mapping[flavor];
    return spanishKey
  }
  return '';
}

const QuizPage = () => {
  const { site, lang, brand, iframe = 'stand' } = useParams();
  const [quizStep, setQuizStep] = useState<number | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [atmosphere, setAtmosphere] = useState<string>("");
  const [dishesSelected, setDishesSelected] = useState<string[]>([]);
  const [cocktails, setCocktails] = useState<QuizRecommendationCardProps[]>([]);
  const [tabsDefinition, setTabsDefinition] = useState<CustomTabsDefinition>({
    tabs: [],
  });


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let _cookie = searchParams.get("cookie") || null;
  const fetchImage = async () => {
    const response = await downloadS3File('cantarito/Cantarito Serve.png')
  }


  fetchImage()


  const flavourmapping = useMemo(
    () => ({
      EN_UK: flavorDescriptions_EN_UK,
      EN_US: flavorDescriptions_EN_US,
      EN_ROW: flavorDescriptions_EN_US,
      ES: flavorDescriptions_ES,
      ES_ES: flavorDescriptions_ES,
      ES_US: flavorDescriptions_ES,
      ES_ROW: flavorDescriptions_ES,
      ES_MX: flavorDescriptions_ES
    }),
    []
  );


  const flavorDescriptions = flavourmapping[lang] || flavorDescriptions_EN_UK;

  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    // Create variables inside the useEffect
    const unixTimestamp = Math.floor(Date.now() / 1000); // Get the Unix timestamp (in seconds)
    const hexTimestamp = unixTimestamp.toString(16); // Convert it to hex
    
    // Create the sessionId using the variables
    const generatedSessionId = String(hexTimestamp) + String(uuid());

    // Set the sessionId to the state
    setSessionId(generatedSessionId);
  }, []); 

  const showFooter = iframe;

  useEffect(() => {
    const getRecommendations = async () => {
      const flavorprint_api_url = process.env.REACT_APP_FLAVORPRINT_API_URL;
      const flavorprint_api_key = process.env.REACT_APP_FLAVORPRINT_API_KEY_QUIZ;
      const CLOUDFRONT_URL = process.env.REACT_APP_CDN;
      let queryString = `?site=${site}`;

      queryString += `&sessionToken=${sessionId}`;

      if (lang === 'ES' || lang === 'ES_ES' || lang === 'ES_MX' || lang === 'ES_US'
        || lang === 'ES_ROW') {
        const dishesSelectedEnglish: string[] = dishesSelected.map((dish) => {
          if (dish in dishMapping) {
            return dishMapping[dish];
          } else {
            console.warn(`Warning: '${dish}' not found in the mapping object.`);
            return dish; // Return the original dish name if no mapping is found
          }
        });
        queryString += `&recipes=${dishesSelectedEnglish.map(item => encodeURIComponent(item)).join(',')}`;
      }
      else {
        queryString += `&recipes=${dishesSelected.map(item => encodeURIComponent(item)).join(',')}`;
      }


      queryString = queryString + "&occasion=" + atmosphere.toUpperCase();
      queryString = queryString + "&interactionType=View";
        
      if (iframe === 'worldclass') {
        _cookie = uuid()
      }
      queryString = queryString + `&consumerId=${_cookie}`;
      const URLFP = new URL(
        flavorprint_api_url + "recommendation" + queryString
      );
      const fpResponse = await fetch(URLFP, {
        method: "GET",
        headers: {
          "x-api-key": String(flavorprint_api_key),
        },
      });
      const fpResponseJSON = await fpResponse.json();

      if (fpResponseJSON && !fpResponseJSON.error) {
        setTabsDefinition({
          tabs: fpResponseJSON.consumerTopFlavors.slice(0, 3).map((flavor) => ({
            label: getFlavorKey(flavor, lang),
            content: getFlavorDescription(flavor, lang)
          }))
        });
        const items_api: any[] = [];

        const cocktail_names = [];

        for await (const recommendation of fpResponseJSON.cocktailRecommendations) {
          cocktail_names.push(recommendation.cocktailId);
        }

        const allgraphqlData = await GetCocktailsByLanguage(cocktail_names, lang)

        for (let i = 0; i < fpResponseJSON.cocktailRecommendations.length; i++) {

          const recommendation = fpResponseJSON.cocktailRecommendations[i]
          const graphqlData = allgraphqlData[i]


          if (graphqlData) {
            const lifeStyleImages = graphqlData.images
              ? graphqlData.images.filter(
                (image: any) => image.imageType === "view_recipe_first_image"
              )
              : [];
            let lifeStyleImageUrl = "";
            if (lifeStyleImages.length > 0) {
              const url = `${CLOUDFRONT_URL}/public/${lifeStyleImages[0].imageUrl.split("/public/").slice(-1)[0]}`

              lifeStyleImageUrl = url;
            }

            console.log("graphqlData =========== ", graphqlData)

            items_api.push({
              cocktailId: recommendation.cocktailId,
              site: site,
              brand_parameter: brand,
              brand: (graphqlData.spirit ? <>
                {
                  graphqlData.spirit[0] ? graphqlData.spirit[0].brand[0].brandName : ""
                }
              </> : ''),
              cocktailName: graphqlData.cocktailTitle || recommendation.title,
              description: graphqlData.cocktailDescription || recommendation.description,
              compatibility: +recommendation.compatibility.toFixed(2),
              image: lifeStyleImageUrl || recommendation.image,
              color:
                atmosphereColors[
                  recommendation.occasion[0].toLowerCase() as keyof typeof atmosphereColors
                ] === "#943434"
                  ? "#F7F7F7"
                  : "#2C2C2C",
              backgroundColor:
                atmosphereColors[
                recommendation.occasion[0].toLowerCase() as keyof typeof atmosphereColors
                ],
              url: (function () {
                // Check if brand is 'thebardomain' for specific URL handling
                if (String(brand).toLowerCase() === 'thebardomain' && (graphqlData.cocktail_url !== null)) {

                  return graphqlData.cocktail_url;  // Use specific URL when brand is 'thebardomain'
                }
                else if (graphqlData.cocktail_url === null) {

                  return `/recipe/thebar/${recommendation.cocktailId}/${(lang || "EN_US").toUpperCase()}/${iframe}`;
                }
                else {
                  return `/recipe/${String(brand).toLowerCase() === "thebar" ? "thebar" : "unbranded"}/${recommendation.cocktailId}/${(lang || "EN_US").toUpperCase()}/${iframe}`;
                }
              })()
            });
          }
        }
        setCocktails(items_api);


        setQuizStep(5);
      } else if (fpResponseJSON && fpResponseJSON.error) {
        console.log(fpResponseJSON.error);
        setErrorMsg(
          fpResponseJSON.message ||
          "Something went wrong. Please try again later."
        );
        setQuizStep(6);
      } else {
        setErrorMsg("Something went wrong. Please try again later.");
        setQuizStep(6);
      }
    };

    if (quizStep === 4) {
      getRecommendations();
    }
  }, [atmosphere, brand, dishesSelected, lang, quizStep, site, _cookie, showFooter]);



  return (
    <div style={{ height: "100vh", backgroundColor: "#FAF1EA", width: '100vw' }}>
      <InactiveScreen setQuizStep={setQuizStep} />

      <Box>
        {/* <Grid
        container
        className={classes.theBarTopBarContainer}
        sx={{
          marginBottom: {
            xs: "2rem",
            sm: "2rem",
            md: "0rem",
            lg: "0rem",
            xl: "0rem",
          },
        }}
      >
        <Grid
          component={Box}
          item
          xs={2}
          display={{ xs: "none", md: "block" }}
          sx={{ position: "relative", top: "14px", left: "20px" }}
        >
          <img src={TheBarLogo} alt="wyc-logo" />
        </Grid>
        <Grid
          component={Box}
          item
          xs={12}
          display={{ xs: "flex", md: "none" }}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={TheBarLogo} alt="wyc-logo" />
        </Grid>
      </Grid> */}
        {(quizStep === null || quizStep === 1) && (
          <QuizStep1
            setQuizStep={setQuizStep}
            lang={lang}
            iframe={showFooter}
          />
        )}
        {quizStep === 2 && (
          <QuizStep2
            setQuizStep={setQuizStep}
            atmosphere={atmosphere}
            lang={lang}
            setAtmosphere={setAtmosphere}
            setDishesSelected={setDishesSelected}
            iframe={showFooter}
          />
        )}
        {quizStep === 3 && (
          <QuizStep3
            setQuizStep={setQuizStep}
            lang={lang}
            atmosphere={atmosphere}
            dishesSelected={dishesSelected}
            setDishesSelected={setDishesSelected}
            iframe={showFooter}
          />
        )}
        {quizStep === 4 && <QuizStep4
          setQuizStep={setQuizStep}
          lang={lang}
          iframe={showFooter}
        />}
        {quizStep === 5 && (
          <QuizStep5
            setQuizStep={setQuizStep}
            atmosphere={atmosphere}
            lang={lang}
            iframe={iframe}
            dishesSelected={dishesSelected}
            cocktails={cocktails}
            tabsDefinition={tabsDefinition}
            cookie={_cookie}
            sessionId={sessionId}
            iframe={showFooter}
            site={site}
          />
        )}
        {quizStep === 6 && (
          <QuizStepError setQuizStep={setQuizStep} errorMsg={errorMsg} lang={lang} />
        )}


        {showFooter === 'stand' ? (
          <>
            <Box
              sx={{ minHeight: "121px", backgroundColor: "#111" }}
              display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex", xl: "flex" }}
            >
              <Grid container spacing={2} className="footer-bx">
                <Grid item xs={12} sm={8} className="footer-left">
                  <Box id="footer" sx={{ padding: { xs: '2rem 1rem', sm: '4rem 2rem', md: '4rem 0rem' } }}>
                    {/* This Box can contain other footer elements like links or text */}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} className="footer-right">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: 'center', sm: 'flex-end' },
                      alignItems: "center",
                      paddingRight: { xs: '1rem', sm: '4rem' },
                      paddingTop: { xs: '2rem', sm: 5 }
                    }}
                  >
                    <img
                      src="/drinkiq_slim.png"
                      alt="DrinkIQ"
                      width={"150px"}
                      height={"46px"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Helmet>
              <title>What's Your Cocktail?</title>
              {!window.location.hostname.includes("localhost") && (
                <script
                  type="text/javascript"
                  src="https://footer.diageohorizon.com/dfs/master.js"
                ></script>
              )}
            </Helmet>
          </>
        ) : ""
        }
      </Box>
    </div>
  );
};

export default QuizPage;
