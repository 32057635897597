import * as React from "react";
import MuiSnackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Fade } from "@mui/material";
import useSnackbar from "../hooks/useSnackbar";

export default function SnackbarAlert() {
  const { open, handleClose, severity, variant, title, content } =
    useSnackbar();

  const anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  } as SnackbarOrigin;

  return (
    <MuiSnackbar
      TransitionComponent={Fade}
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={3000}
      onClose={() => handleClose()}
    >
      <Alert
        severity={severity}
        variant={variant}
        onClose={() => handleClose()}
        sx={{
          ...(variant === "outlined" && {
            bgcolor: "background.paper",
          }),
        }}
      >
        <AlertTitle>{title}</AlertTitle>
        {content && <>{content}</>}
      </Alert>
    </MuiSnackbar>
  );
}
