
import { GenericFunctionResponse } from "../types/shared.types";
import AWS from 'aws-sdk';

// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_S3_REGION
});

// Create an S3 client
const s3 = new AWS.S3({useAccelerateEndpoint: true});

/**
 * Retrieves the S3 file URL for the given file key.
 *
 * @param {string} filekey - The key of the file in S3
 * @return {Promise<GenericFunctionResponse>} A Promise that resolves to a GenericFunctionResponse containing the file URL
 */
export const getS3FileUrl = async (
  filekey: string
): Promise<GenericFunctionResponse> => {
  try {
    
    const params = {
      Bucket: "whatsyourcocktailimages182207-prod",
      Key: `public/${filekey}`,
      Expires: 6000 // URL expiry time in seconds
    };

    const generatedUrl = s3.getSignedUrl('getObject', params);
    console.log(generatedUrl,33)

    return { success: true, data: generatedUrl};
  } catch (error) {
    console.log("Error listS3Files: ", error);
    return { success: false, message: "Failed to get file url" };
  }
}
/**
 * Retrieves the S3 file URL for the given file key.
 *
 * @param {string} filekey - The key of the file in S3
 * @return {Promise<GenericFunctionResponse>} A Promise that resolves to a GenericFunctionResponse containing the file URL
 */

export const downloadS3File = async (fileKey) => {
  try {
    const params = {
      Bucket: "whatsyourcocktailimages180242-stag",
      Key: `public/${fileKey}`,
    };

    const data = await s3.getObject(params).promise();

    // Convert the data to a blob URL
    const blob = new Blob([data.Body], { type: data.ContentType });
    const url = URL.createObjectURL(blob);

    return { success: true, data: url };
  } catch (error) {
    console.log("Error downloading S3 file: ", error);
    return { success: false, message: "Failed to download file" };
  }
};






