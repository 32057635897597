import React, {useMemo} from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useStyles } from "../../styles";
import { ReactComponent as BeginnerSvg } from "../../../../assets/svg/Beginner.svg";
import { ReactComponent as IntermediateSvg } from "../../../../assets/svg/Intermediate.svg";
import { ReactComponent as HardSvg } from "../../../../assets/svg/Hard.svg";
import { ReactComponent as WineCupSvg } from "../../../../assets/svg/WineCup.svg";

interface TheBarRecommendationCardProps {
  cocktailName: string;
  difficulty: string;
  alc: string;
  image: string;
  lng: string;
}

const TheBarRecommendationCard = ({
  cocktailName,
  difficulty,
  alc,
  image,
  lng,
}: TheBarRecommendationCardProps) => {
  const classes = useStyles();
  const difficultyIcons = {
    Beginner: BeginnerSvg,
    Intermediate: IntermediateSvg,
    Hard: HardSvg,
  };

  const viewrecipetitle = useMemo(
    () => ({
      EN_UK: "VIEW RECIPE",
      EN_US: "VIEW RECIPE",
      ES: "VER RECETA",
    }),
    []
  );


  const title_view_recipe = viewrecipetitle[lng] || "Essentials for Crafting the Magic";

  return (
    <Box
      sx={{
        margin: {
          xs: "0 0",
          sm: "0 0",
          md: "0 1rem",
          lg: "0 1rem",
          xl: "0 1rem",
        },
      }}
    >
      <Card
        className={`${classes.theBarCardContainer}`}
        sx={{
          width: {
            xs: "320px !important",
            sm: "320px !important",
            md: "415px !important",
            lg: "415px !important",
            xl: "415px !important",
          },
        }}
      >
        <CardMedia
          component="img"
          alt={cocktailName}
          height="415"
          image={image}
        />
        <CardContent sx={{height: "227px", position: "relative !important"}}>
          <Box sx={{ display: "flex", marginBottom: 0 }}>
            <Box className={classes.theBarIconText} sx={{ marginRight: 2 }}>
              <SvgIcon component={WineCupSvg} sx={{ marginRight: 1 }} />
              {alc}
            </Box>
            <Box className={classes.theBarIconText}>
              <SvgIcon
                component={
                  difficultyIcons[difficulty as keyof typeof difficultyIcons]
                }
                sx={{ marginRight: 1 }}
              />
              {difficulty}
            </Box>
          </Box>
          <Typography gutterBottom className={classes.theBarCocktailTitle}>
            {cocktailName}
          </Typography>
          <Typography gutterBottom className={classes.theBarViewRecipeLink}>
            {title_view_recipe}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TheBarRecommendationCard;
