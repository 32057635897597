import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  theBarRootContainer: {
    backgroundColor: "#FAF1EA",
  },
  theBarDescriptionPdf: {
    fontFamily: "Fnord !important",
    fontSize: "10pt !important",
    fontWeight: "400 !important",
    lineHeight: "15pt !important",
  },
  theBarMainTitlePdf: {
    fontFamily: "Fnordbold !important",
    fontSize: "45pt !important",
    fontWeight: "400 !important",
    lineHeight: "24pt !important", // Converted from 32px
  },
  sectionTitlePdf: {
    fontFamily: "HelveticaNeue-Bold !important",
    fontSize: "10pt !important", // Converted from 10px
    lineHeight: "7.95pt !important", // Converted from 10.6px
    fontWeight: "700 !important",
    marginBottom: "2rem !important", // 0.5rem remains the same
  },
  theBarIconTextPdf: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "10pt !important", // Converted from 11px
    fontWeight: "400 !important",
    lineHeight: "15pt !important", // Converted from 13.2px
  },
  theBarStepTextPdf: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "10pt !important", // Converted from 8px
    fontWeight: "400 !important",
    lineHeight: "15pt !important", // Converted from 8.48px
  },
  stepTextPdf: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "20pt !important", // Converted from 11px
    fontWeight: "400 !important",
    lineHeight: "15pt !important", // Converted from 13.2px
    display: "flex",
    alignItems: "center",
  },
  stepContainerPdf: {
    display: "flex",
    alignItems: "center",
    maxWidth: "311.25pt", // Converted from 415px
    marginBottom: "0.375rem", // 0.5rem remains the same
  },
  containerImage: {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectFit: "cover",
  },
  stepNumberPdf: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "20pt !important", // Converted from 11px
    fontWeight: "700 !important",
    lineHeight: "15pt !important", // Converted from 13.2px
    marginRight: "0.375rem !important", // Converted from 0.5rem
  },
  topTitlepdf: {
    fontFamily: "HelveticaNeue !important",
    fontSize: "7pt !important",
    fontWeight: "700 !important",
    lineHeight: "15.9pt !important", // Converted from 21.2px
    padding: "0pt !important", // Converted from 6px
    marginBottom: "0.75rem !important", // Converted from 1rem
    marginRight: "10.5pt !important", // Converted from 14px
    textTransform: "uppercase",
  },

  TheBarSecondSectionMainTitle: {
    fontSize: "20pt !important",
    fontFamily: "Fnordbold !important",
    fontWeight: "400 !important",
  },
  TheUnbrandedSecondSectionMainTitle: {
    fontSize: "20pt !important",
    fontFamily: "HelveticaNeue-Bold !important",
    fontWeight: "400 !important",
  },
  theBarCoverImage: {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "908pt", // Converted from 640px
    height: "908pt", // Converted from 640px
    objectFit: "cover",
    borderRadius: "6pt", // Converted from 8px
  },
}));
