import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface IframeParamContextProps {
  iframeParam: string;
  setIframeParam: (param: string) => void;
}

const IframeParamContext = createContext<IframeParamContextProps | undefined>(undefined);

export const useIframeParam = (): IframeParamContextProps => {
  const context = useContext(IframeParamContext);
  if (!context) {
    throw new Error('useIframeParam must be used within an IframeParamProvider');
  }
  return context;
};

interface IframeParamProviderProps {
  children: ReactNode;
}

export const IframeParamProvider = ({ children }: IframeParamProviderProps) => {
  const [iframeParam, setIframeParam] = useState("");
  const location = useLocation();

  useEffect(() => {
    const pathnameParts = location.pathname.split('/');
    let iframePart = pathnameParts[pathnameParts.length - 1]; // Get the last part of the URL

    if (iframePart !== 'stand' && iframePart!== 'iframe') {
      iframePart = 'stand'
    }
    setIframeParam(iframePart);
  }, [location]);

  return (
    <IframeParamContext.Provider value={{ iframeParam, setIframeParam }}>
      {children}
    </IframeParamContext.Provider>
  );
};
